import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import Loader from '@ey/loader';
import {
	labels,
	currentResource,
	dateFormatsListItems
} from '../../../util/uiconstants';
import {SVGICONS} from '@ey/icons';
import moment from 'moment';
import styled from 'styled-components';
import EllipsesControl from '@ey/ellipses-control';
import {usePrevious} from '../../../util/customHooks';

const ConfirmationStep = (props) => {
	const {isComponentValid, updateWizardData, engagementName, nextClicked} =
		props;
	//states declaration
	const [isValid, setIsValid] = useState(false);
	const [initLoad, setInitLoad] = useState(true);

	//redux states
	const restoreData = useSelector((store) => store.restoreEngagement);
	const userSettings = useSelector((store) => store.userSettings);

	let domainRestoreType =
		restoreData?.restoreEngagementData?.state?.restoreType;

	//global variables
	let isLoading = false;

	//componentDidMount and componentDidUpdate refs
	const nextClickedRef = usePrevious(nextClicked);
	const mounted = useRef();

	//componentDidMount and componentDidUpdate logic
	useEffect(() => {
		if (!mounted.current) {
			if (isGlobalRestoreApplicable()) {
				isComponentValid(!isBetweenNormalandGlobalUser());
			} else {
				isComponentValid(true);
			}
			mounted.current = true;
		} else {
			if (nextClickedRef !== nextClicked) {
				setInitLoad(false);
			}
		}
	});

	const isBetweenNormalandGlobalUser = () => {
		if (restoreData) {
			const {data} = restoreData;

			const count = data ? data.restoreengagementcount : 0;
			const maxRestoreCopiesAllowed = data ? data.maxrestorecopiesallowed : 0;
			const maxglobalexceptionrestoreperengmt = data
				? data.maxglobalexceptionrestoreperengmt
				: 0;

			if (
				count >= maxRestoreCopiesAllowed &&
				count < maxglobalexceptionrestoreperengmt
			) {
				return true;
			}
		}

		return false;
	};

	const isBelowGlobalExceptionUserLimit = () => {
		if (restoreData) {
			const {data} = restoreData;

			const count = data ? data.restoreengagementcount : 0;
			const maxglobalexceptionrestoreperengmt = data
				? data.maxglobalexceptionrestoreperengmt
				: 0;

			if (count < maxglobalexceptionrestoreperengmt) {
				return true;
			}
		}

		return false;
	};

	const isGlobalRestoreApplicable = () => {
		return domainRestoreType == 2; // type - 2 is global exception
	};

	const handleCheck = (e) => {
		updateWizardData('domainglobalexception', e);
		if (isBetweenNormalandGlobalUser()) {
			isComponentValid(e);

			// set model to valid
			setIsValid(e);
		}
	};

	const {data} = restoreData;

	let userDateFormat = 0;
	if (userSettings && userSettings.data) {
		userDateFormat = userSettings.data.userdatetimeformat;
	}

	let applyClass = isValid ? '' : initLoad ? '' : 'red';

	return (
		<>
			{isLoading ? (
				<Loader /> // Show loading indicator in case data pull in progress
			) : (
				<Restorewizardstep1>
					<section>
						<h5>{labels.restoreconfirmationheader} </h5>
					</section>

					<section className="Restoreengagement-name">
						{engagementName}
						{isGlobalRestoreApplicable() &&
						isBelowGlobalExceptionUserLimit() ? (
							<React.Fragment>
								<br />
								<label className="confirmbox">
									<input
										type="checkbox"
										className={applyClass}
										name="confirmcheckbox"
										onClick={(e) => handleCheck(e.target.checked)}
									/>
									<label>{labels.globalexception}</label>
									<span className="geekmark"> </span>
									{applyClass ? (
										<section className="import-error">
											<section>
												<SVGICONS styleName="small" id="icon" name="error" />
												<span>{labels.globalexceptioncheckerror}</span>
											</section>
										</section>
									) : null}
								</label>
							</React.Fragment>
						) : null}
					</section>
					<section className="Restoreengagement-wrapper">
						<RestoreCopiesMessage
							count={data ? data.restoreengagementcount : 0}
							maxRestoreCopiesAllowed={data ? data.maxrestorecopiesallowed : 0}
							maxglobalexceptionrestoreperengmt={
								data ? data.maxglobalexceptionrestoreperengmt : 0
							}
						/>

						{data &&
							data.restoredengagements &&
							data.restoredengagements.map((engData, i) => {
								return (
									<RestoreEngagement
										key={i}
										engData={engData}
										userDateFormat={userDateFormat}
									/>
								);
							})}
					</section>
				</Restorewizardstep1>
			)}
		</>
	);
};

const RestoreCopiesMessage = (props) => {
	if (!props.maxRestoreCopiesAllowed || props.maxRestoreCopiesAllowed === 0) {
		return null;
	}

	if (props.maxglobalexceptionrestoreperengmt > props.maxRestoreCopiesAllowed) {
		// user is part of special group, so have that check as well
		if (props.maxglobalexceptionrestoreperengmt <= props.count) {
			// even this count is exceeding, show user message and stop
			return (
				<section className="Restoreengagement-message">
					{labels.restoreengagementmaxcopies.replace(
						'{0}',
						props.maxglobalexceptionrestoreperengmt
					)}
				</section>
			);
		} else if (props.count > 1) {
			return (
				<section className="Restoreengagement-message">
					{labels.globalexceptionrestoreengagementengcopies.replace('{0}', props.count)}
				</section>
			);
		} else {
			return (
				<section className="Restoreengagement-message">
					{labels.restoreengagementengnocopies}
				</section>
			);
		}
	} else {
		// its normal user
		if (props.maxRestoreCopiesAllowed <= props.count) {
			return (
				<section className="Restoreengagement-message">
					{labels.restoreengagementmaxcopies.replace(
						'{0}',
						props.maxRestoreCopiesAllowed
					)}
				</section>
			);
		} else {
			if (props.count === 1) {
				return (
					<section className="Restoreengagement-message">
						{labels.restoreengagementengcopy.replace('{0}', props.count)}
					</section>
				);
			} else if (props.count > 1) {
				return (
					<section className="Restoreengagement-message">
						{labels.restoreengagementengcopies.replace('{0}', props.count)}
					</section>
				);
			} else {
				return (
					<section className="Restoreengagement-message">
						{labels.restoreengagementengnocopies}
					</section>
				);
			}
		}
	}
};

// eslint-disable-next-line react/no-multi-comp
const RestoreEngagement = (props) => {
	const {engData} = props;

	const [expanded, setExpanded] = useState(false);

	const togglePanel = () => {
		setExpanded(!expanded);
	};

	const {
		engagementdescription,
		totalactiveusers,
		restoreengagementusers,
		engagementcreatedate,
		engagementsourceid
	} = engData;

	const {userDateFormat} = props;

	return (
		<section>
			<section className="Restoreengagement-copyname">
				<b>{engagementdescription}</b> {labels.restoreengagementrestoredon}{' '}
				{moment
					.utc(engagementcreatedate)
					.format(dateFormatsListItems[userDateFormat].label)}
				{engagementsourceid === 8 ? ' (Global Exception)' : null}
			</section>
			<section
				className="Restoreengagement-users"
				onClick={(e) => togglePanel(e)}
			>
				<SVGICONS
					styleName="large"
					id="expandicon"
					name={expanded ? 'minus' : 'plus'}
				/>
				{totalactiveusers}
				{totalactiveusers > 1
					? ' ' + labels.restoreengagementusers
					: ' ' + labels.restoreengagementuser}
			</section>
			{expanded ? <UsersList users={restoreengagementusers} /> : null}
		</section>
	);
};

// eslint-disable-next-line react/no-multi-comp
const UsersList = (props) => {
	return (
		<section className="Restoreengagement-userslist">
			<ul>
				{props.users &&
					props.users.map((usr, i) => {
						let emailTo = 'mailto:' + usr.emailaddress;
						let usrRole = currentResource.rolelistitems.find(
							(e) => e.id === usr.roleid
						);
						return (
							<li key={i}>
								<EllipsesControl
									id="username"
									content={usr.firstname + ' ' + usr.lastname}
									tooltip={usr.firstname + ' ' + usr.lastname}
									isTooltipAvailable
								/>
								<span>
									{usrRole ? usrRole.label : labels.arcfileroleunavailable}
								</span>
								<span>
									<a href={emailTo}>{usr.emailaddress}</a>
								</span>
							</li>
						);
					})}
			</ul>
		</section>
	);
};

export default ConfirmationStep;

const Restorewizardstep1 = styled.section`
	& .Restoreengagement-name {
		border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey250`]};
		padding-bottom: 0;
		font-size: 1.4rem;
		input {
			width: 2rem;
			height: 2rem;
			margin-right: 0.5rem;
			vertical-align: text-bottom;
		}
		label {
			font-weight: normal;
		}
		& .import-error {
			font-size: 1.2rem;
			line-height: normal;
			height: 2rem;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;

			& svg {
				fill: ${(props) => props.theme.color[`red600`]};
				vertical-align: text-top;
				margin-right: 0.5em;
			}
		}
	}

	& .Restoreengagement-wrapper {
		padding-top: 2.5rem;
	}

	& .Restoreengagement-message {
		margin-bottom: 1.5rem;
		font-size: 1.4rem;
	}

	& .Restoreengagement-copyname {
		font-weight: bold;
		font-size: 1.4rem;
	}

	& .Restoreengagement-users {
		font-weight: bold;
		font-size: 1.4rem;
		margin: 0.5em 1em;

		& svg {
			vertical-align: middle;
			margin-right: 0.8rem;
		}
	}

	& .Restoreengagement-userslist {
		margin-left: 4em;
		font-size: 1.4rem;
		width: 55%;
		a {
			color: ${(props) => props.theme.color[`blue600yellow400`]};
		}
		& ul {
			margin-bottom: 1em;
		}

		& ul li {
			list-style: none;
			border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey250`]};
			margin: 0.5em 0;
			padding-bottom: 0.5em;

			& > * {
				max-width: 22%;
				width: 22%;
				display: inline-block;
				margin: 0 2%;
			}

			& .ellipses {
				display: inline-flex;
			}

			&:last-child {
				border: 0;
			}
		}
	}
`;
