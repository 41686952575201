/* eslint-disable prettier */
/**

import { Addon } from 'react-bootstrap/lib/InputGroup';

 * Created by calhosh on 4/14/2017.
 * Arabic resource file
 */
export const labels = {
    defaultDropdown: "القائمة المنسدلة",
    status: "الحالة",
    language: "اللغة",
    channel: "القناة",
    header: "الرأس",
    headerName: "اسم الرأس",
    sectionCustomLabelLabel: "تسمية مخصصة",
    sectionNamePlaceHolder: "أدخل عنوان القسم",
    showRelatedObjectLabel: "عرض الكائنات المرتبطة",
    errorBanner: "{0} خطأ (أخطاء)",
    engagements: "مهام التدقيق",
    archives: "الأرشيفات",
    id: "المعرف",
    archived: "تمت الأرشفة",
    myarchives: "أرشيفاتي",
    allarchives: "كل الأرشيفات",
    norecords: "لم يتم العثور على أي سجلات",
    nomatches: "لم يتم العثور على أي تطابقات",
    notemplates: "لا تتوفر أي قوالب للمنطقة/ الاقليم المحدد",
    pleasetryagain: "يُرجى إعادة المحاولة",
    firstpage: "الصفحة الأولى",
    prevpage: "الصفحة السابقة",
    nextpage: "الصفحة التالية",
    lastpage: "الصفحة الأخيرة",
    allengagements: "جميع مهام التدقيق",
    searchPlaceholder: "بحث",
    clearSearch: "مسح البحث",
    searchTextPlaceHolder: "بحث",
    show: "إظهار",
    manageaccess: "إدارة الوصول",
    restore: "استرجاع",
    rollforward: "ترحيل",
    viewaccesslist: "عرض قائمة الوصول",
    teammembers: "أعضاء الفريق",
    name: "الاسم",
    initials: "الأحرف الأولى",
    role: "الدور",
    usersettings: "إعدادات المستخدم",
    usersettingsnewengagements: "الإعدادات تنطبق على مهام التدقيق المنشأة حديثاً",
    usersettingsallengagements: "الإعدادات تنطبق على جميع مساحات العمل ومهام التدقيق",
    enterfirstname: "اسم العرض (الأول)",
    enterlastname: "اسم العرض (الأخير)",
    firstname: "الاسم الأول",
    lastname: "الاسم الأخير",
    enter: "أدخل",
    select: "تحديد...",
    email: "عنوان البريد الإلكتروني",
    contact: "رقم الهاتف",
    accesslist: "الوصول إلى القائمة",
    engagementsettings: "إعدادات مهمة التدقيق",
    displayname: "اسم العرض",
    signoffinitials: "الأحرف الأولى للتوقيع",
    primaryoffice: "المكتب الرئيسي",
    roles: "الدور",
    phonenumber: "رقم الهاتف",
    globalapplicationsettings: "إعدادات التطبيق العامة",
    displaylanguage: "لغة العرض",
    preferreddateformat: "صيغة التاريخ المفضلة",
    preferrednumberformat: "صيغة الرقم المفضلة",
    save: "حفظ",
    cancel: "إلغاء",
    isRequired: "{0} مطلوب",
	arcretrymessage: "حدث خطأ خلال عملية الأرشفة. اتصل بمكتب الدعم لمتابعة عملية الأرشفة. "
		+ "تاريخ أرشفة السجل لن يتأثر وتم تسجيله على أنه", 
    ArchiveRetryLink: "إعادة محاولة الأرشفة",
    workspacelist: "قائمة مساحات العمل",
    engagementslist: "قائمة مهام التدقيق",
    accessrequested: "تم طلب الوصول",
    noaccess: "لا يمكن الوصول",
    accessrejected: "وصول مرفوض",
    requestaccess: "طلب الوصول",
    allarchiveinitialmsg: "أدخل اسم العميل أو اسم مساحة العمل أو اسم مهمة التدقيق للبدء",
    noengagementsforusermessage: "ليس لديك أي مهام تدقيق نشطة. انقر فوق إنشاء مهمة تدقيق للبدء.",
    noengagementstoimportto: "ليس لديك أي مهام تدقيق للاستيراد إليها.",
    favorites: "المفضلات",
    reviewengagement: "مراجعة مهام التدقيق",
    noreviewengagementsmessage: "ليس لديك أي مهام تدقيق للمراجعة.",
    nofavoriteengagementsmsg1: "ليس لديك أي مهام تدقيق مفضلة.",
    nofavoriteengagementsmsg2: "لإضافة مهمة تدقيق لهذا العرض، انتقل إلى جميع مهام التدقيق ",
    nofavoriteengagementsmsg3: "وانقر فوق أيقونة المفضلات في بطاقة مهمة التدقيق.",
    markasfavorite: "تحديد كمفضلة",
    unmarkfromfavorites: "إلغاء التحديد كمفضلة",
    selectworkspacenoclientworkspaceexists: "ليس لديك أي مساحة عمل حالية. انقر فوق عودة واختر مساحة عمل جديدة من الخطوة 1.",
	importpkgselectworkspacenoclientworkspaceexists: 'ليس لديك أي مساحة عمل حالية. انقر فوق عودة واختار "إنشاء مساحة عمل جديدة" من الخطوة السابقة.',
    /*Reporting Hub*/
    reportingHub: "Reporting",
    reportingHubTitle: "تقرير EY Canvas",
    selectEngagement: "حدد مهمة تدقيق لعرض التقارير",
    connectVPN: "يمكن الوصول إلى التقارير فقط في حالة الاتصال بشبكة إرنست ويونغ أو من خلال تطبيق الاتصال عن بعد EY Remote Connect",
    reportsNotLoaded: "تعذر تحميل التقارير. قم بتحديث الصفحة وحاول مرة أخرى. في حالة استمرار المشكلة، اتصل بمكتب الدعم.",
    searchByEngagementNameOrClientId: "حدد مهمة تدقيق",
    dataExportLabel: "تصدير البيانات",
    summaryScreensLabel: "شاشات مختصرة",
    projectManagementLabel: "إدارة المشروع",
    engagement: "اسم مهمة التدقيق",
    reportingNotAvailable: "إعداد التقارير غير متوفر في الموقع المحدد. اتصل بمسؤول EY Canvas المحلي الخاص بك للمزيد من التفاصيل.",
    client: "العميل",
    workspace: "مساحة العمل",
    serviceline: "الإدارة",
    subserviceline: "الإدارة الفرعية",
    engagementstartdate: "تاريخ نهاية السنة",
    engagementenddate: "تاريخ نهاية الفترة المشمولة",
    auditorsreportdate: "تاريخ التقرير",
    createengagementquestionlabel: "إنشاء مهمة تدقيق",
    choosenewexistingquestionheader: "إنشاء مهمة تدقيق في مساحة عمل حالية أو جديدة",
    createengagementheader: "إنشاء مهمة تدقيق",
    restoreengagementheader: "استرجاع مهمة تدقيق",
    createworkspacestepheader: "إنشاء مساحة عمل جديدة",
    selectexistingworkspacestepheader: "حدد مساحة عمل حالية",
    choosenewexistingstepheader: "مساحة عمل جديدة أو حالية",
    editengagementwizheader: "تحرير مهمة التدقيق",
    removeengagementwizheader: "إزالة مهمة تدقيق",
    editworkspacewizheader: "تحرير مساحة العمل",
    editworkspacestepfooter: "انقر فوق ’حفظ وإغلاق‘ لحفظ التغييرات على المساحة العمل أو انقر فوق ’حفظ واستمرار‘ لتحرير مهمة التدقيق.",
    editwizheader: "تحرير",
    newworkspace: "مساحة عمل جديدة",
    existingworkspace: "مساحة عمل حالية",
    back: "عودة",
    continue: "استمرار",
    saveclose: "حفظ وإغلاق",
    savecontinue: "حفظ واستمرار",
    finish: "إنهاء",
    step01: "الخطوة 1",
    step02: "الخطوة 2",
    step03: "الخطوة 3",
    step04: "الخطوة 4",
    step05: "الخطوة 5",
    engagementname: "اسم مهمة التدقيق",
    confirmdecline: "تأكيد الرفض. لا يمكن التراجع عن هذا الإجراء.",
    confirmdeclinevalidation: "قم بتأكيد أنك ترغب في الرفض.",
    responderror: "لقد قمت بالاستجابة إلى هذه الدعوة مسبقاً، يرجى تحديث الصفحة وحاول مرة أخرى.",
    engagementcode: "رمز مهمة التدقيق",
    engagementcodename: "اسم رمز مهمة التدقيق",
    selectEngagementHeader: "حدد مساحة عمل حالية",
    selectworkspaceclient: "حدد العميل",
    selectclient: "حدد العميل لعرض مساحات العمل المرتبطة",
    createnewworkspace: "إنشاء مساحة عمل جديدة",
    editworkspace: "تحرير مساحة العمل",
    clientcode: "رمز العميل",
    clientname: "اسم العميل",
    invalidclientcode: "رمز العميل غير صالح",
    workspacename: "اسم مساحة العمل",
    location: "الموقع",
    workspacecodenameplaceholder: "أدخل رمز العميل للبحث عن اسم العميل",
    engagementcodenameplaceholder: "أدخل رمز مهمة التدقيق للبحث عن اسم الرمز",
    isprimary: "رئيسي",
    addengagementcode: "إضافة رمز مهمة التدقيق",
    engagementnamerequired: "اسم مهمة التدقيق مطلوب",
    primaryofficerequired: "المكتب الرئيسي مطلوب",
    languagerequired: "يلزم إدخال اللغة",
    servicelinerequired: "الإدارة مطلوبة",
    subservicelinerequired: "الإدارة الفرعية مطلوبة",
    engagementcodevalidationmessage: "رمز مهمة التدقيق لا يطابق رمز العميل",
    engagementcoderequired: "رمز مهمة التدقيق مطلوب",
    yearenddate: "تاريخ نهاية السنة",
    periodenddate: "تاريخ نهاية الفترة",
    reportdate: "تاريخ التقرير",
    complete: "مكتمل",
    end: "انهاء",
    started: "تم البدء",
    notstarted: "لم يتم البدء",
    inprogress: "قيد التنفيذ",
    inreview: "قيد المراجعة",
    taskstatus: "حالة المهمة",
    confirmlabel: "تأكيد",
    submitlabel: "تقديم",
    editengagementquestionlabel: "تحرير مهمة التدقيق",
    removeengagementquestionlabel: "إزالة مهمة تدقيق",
    removeengaementconfirmationmessage1: "هل أنت متأكد من أنك تريد إزالة مهمة التدقيق التالية من قائمة مهام التدقيق الخاصة بك؟ إذا قمت بإزالة مهمة التدقيق، فلن يكون بإمكانك الوصول إليها. ",
    removeengagementerrormessage: "لا يمكن إزالة مهمة التدقيق من مساحة العمل حيث أنك آخر مستخدم نشط. إضافة عضو فريق آخر أو حذف مهمة التدقيق. ",
    deleteengagement: "حذف مهمة التدقيق",
    deleteengagementconfirmationmessage: "هل أنت متأكد من أنك تريد تقديم طلب لحذف مهمة التدقيق التالية؟ بالنسبة لمهام التدقيق المنتهية أو تلك التي لا نصدر بشأنها تقرير، يجب على فرق التدقيق استشارة إدارة الخدمات المهنية لتحديد ما إذا كانت الأرشفة مطلوبة.",
    deleteengagementconfirmationmessageoneuser: "هل أنت متأكد من أنك تريد حذف مهمة التدقيق التالية؟ بالنسبة لمهام التدقيق المنتهية أو تلك التي لا نصدر بشأنها تقرير، يجب على فرق التدقيق استشارة إدارة الخدمات المهنية لتحديد ما إذا كانت الأرشفة مطلوبة",
    deleteengagementmarkeddelete: "تم تعليم مهمة التدقيق بالفعل للحذف",
    deleteengagementoption: "حذف",
    deleteengagementreasonlabel: "أدخل سبب الحذف",
    deleteengagementreasonrequired: "يُرجى إدخال سبب الحذف",
    deleteengagementcommentrequiredlabel: "يُرجى إدخال سبب الحذف",
    markeddeleteconfirm: "تأكيد الحذف",
    deleterequestbannertext1: "طلب حذف من",
    deleterequestbannertext2: "مضت",
    markeddeleteconfirmmessage: "هل أنت متأكد من أنك تريد حذف مهمة التدقيق التالية؟ إذا قمت بتأكيد الحذف، فلا يمكن التراجع عن هذا الإجراء.",
    markeddeletereasonlabel: "سبب الحذف",
    engagementlistplaceholder: "حدد مهمة تدقيق",
    engagementrequired: "مهمة التدقيق مطلوبة",
    restoreconfirmationstepheader: "تأكيد",
    restoreconfirmationheader: "هل أنت متأكد من أنك تريد استرجاع مهمة التدقيق؟",
    selectarchiveheader: "حدد الملف المؤرشف",
    restoreengagementstepheader: "استرجاع مهمة تدقيق",
    chooserollfwdhdr: "ترحيل إلى مساحة عمل جديدة أو حالية",
    rollfwdstephdrext: "ترحيل إلى مساحة عمل حالية",
    rollfwdstephdrnew: "ترحيل إلى مساحة عمل جديدة",
    rollfwdhdrqstn: "ترحيل مهمة التدقيق إلى مساحة عمل جديدة أو حالية؟",
    rollforwardengagementstepheader: "ترحيل مهمة التدقيق",
    genericerror1: "حدث خطأ، يُرجى إعادة المحاولة أو الاتصال بفريق الدعم",
    restoreengagementmaxcopies: "تجاوزت مهمة التدقيق الحد الأقصى لعدد النسخ المسترجعة ({0}). طلب الوصول لنسخة موجودة.",
    restoreengagementengcopies: "يوجد {0} نسخ مسترجعة متوفرة لمهمة التدقيق هذه:",
    restoreengagementengcopy: "يوجد {0} نسخ مسترجعة متوفرة لمهمة التدقيق هذه:",
    restoreengagementengnocopies: "لا يوجد نسخ مسترجعة أخرى متوفرة لمهمة التدقيق هذه.",
    globalexceptionrestoreengagementengcopies: "There are {0} restored copies of this engagement available. Check the box above to restore with global exception.",
    globalexception: "Global exception",
    globalexceptioncheckerror: "Check Global exception option to continue.",
    restoreengagementrestoredon: "مسترجع في",
    restoreengagementusers: "المستخدمون",
    restoreengagementuser: "المستخدم",
    at: "في",
    arcfileuserunavailable: "المستخدم غير متوفر",

    restorehdrqstn: "استرجاع مهمة التدقيق في مساحة عمل جديدة أو حالية؟",
    chooserestorehdr: "استرجاع إلى مساحة عمل جديدة أو حالية",
    restorestephdrext: "استرجاع إلى مساحة عمل حالية",
    restorestephdrnew: "استرجاع إلى مساحة عمل جديدة",

    close: "إغلاق",
    confirm: "تأكيد",
    settings: "الإعدادات",
    expand: "توسيع",
    collapse: "طي",
    contextMenuEdit: "تحرير",
    contextMenuEditEngagement: "تحرير مهمة التدقيق",
    contextMenuRemoveEngagement: "إزالة مهمة تدقيق",
    contextMenuDeleteEngagement: "حذف مهمة التدقيق",
    contextMenuEditWorkspace: "تحرير مساحة العمل",
    contextMenuDelete: "حذف",
    contextMenuOverrideArchiveDate: "تجاوز الموعد النهائي للأرشفة",
    na: "غير منطبق",
    archivesubmittedby: "تم التقديم بواسطة",
    archivedateandtime: "التاريخ والوقت",
    notifications: "الإشعارات",
    nonotificationmsg: "ليس لديك أي إشعارات",
    notifcenter: "مركز الإشعارات",
    notifitemspendingact: "البنود قيد الإجراء",
    accept: "قبول",
    reject: "رفض",
    acceptall: "قبول الكل",
    rejectall: "رفض الكل",
    notifEngInvite: "دعوات مهمة التدقيق",
    notifengaccesreq: "طلب الوصول إلى مهمة التدقيق",
    keyevents: "الأحداث الرئيسية",
    milestones: "مرحلة الإنجاز الرئيسية",
    importselecttemplateheader: "يرجى تحديد القالب الذي ترغب في استخدامه",
    importtemplateheader: "يرجى تحديد المنطقة/الإقليم الذي ترغب في الاستيراد منه",
    import: "استيراد",
    importregionstepheader: "حدد المنطقة / الإقليم",
    importtemplatestepheader: "حدد قالب",
    importtemplatemsg: "حدد المنطقة/ الإقليم للمتابعة",
    importselecttemplatemsg: "حدد قالب للمتابعة",
    importengagementheader: "استيراد القالب",
    importhdrqstn: "استيراد إلى مساحة عمل جديدة أو حالية",
    importstephdrext: "استيراد إلى مساحة عمل موجودة",
    importstephdrnew: "استيراد إلى مساحة عمل جديدة",
    chooseimporthdr: "استيراد إلى مساحة عمل جديدة أو حالية",
    selectexistingworkspacemsg: "حدد مساحة عمل للمتابعة",
    selectaclienttocontinuemsg: "حدد عميل للمتابعة",
    selectengagementmsg: "حدد مهمة تدقيق لقبول حزمة البيانات",
    selectgaengagementmsg: "يجب عليك تحديد مهمة تدقيق لقبول طلب الربط",
    arearegion: "منطقة / إقليم",
    importdatapackagequestionlabel: "ماذا ترغب أن تفعل؟",
    sentby: "مرسل من",
    on: "بتاريخ",
    by: "بواسطة",
    view: "عرض",
    sourceengagement: "مصدر مهمة التدقيق",
    package: "حزمة",
    decline: "رفض",
    delegate: "تفويض",
    chooseaction: "اختيار إجراء",
    selectworkspace: "تحديد مساحة عمل",
    declinepackage: "رفض الطلب",
    delegatepackage: "تفويض الحزمة",
    selectengagement: "تحديد مهمة تدقيق",
    datapackagerequest: "طلب حزمة البيانات",
    respond: "استجابة",
    deletionrequestfor: "تم استلام طلب الحذف لـ",
    invalidengagementcode: "رمز مهمة تدقيق غير صالح",
    noNotifications: "لا تتوفر أي إشعارات",
    bellIconTitle: "مركز الإشعارات",
    restoredengagement: "مهمة التدقيق المسترجعة",
    completeprofileheader: "ملف التعريف المكتمل",
    completeindependenceheader: "الاستقلال المكتمل",
    retry: "إعادة المحاولة",
    errorDate: "خطأ",
    invitationDate: "تاريخ الدعوة",
    engCreationFailedWarning: "فشل إنشاء مهمة التدقيق. قم بتحديث الصفحة وحاول مرة أخرى. في حالة استمرار المشكلة، اتصل بمكتب الدعم.",
    deletedEngagementWarning: "إذا كانت مهمة التدقيق قيد الاستخدام وتم حذفها بالخطأ، اتصل بمكتب الدعم فوراً.",
    searchEngagementWarning: "ابحث عن مهام التدقيق هذه لاتخاذ مزيد من الإجراءات.",
    archiveOverrideWarning: "فترة تجاوز الأرشفة المحددة غير مسموح بها من قبل مركز سجلات الأرشيف. تم إرجاع الموعد النهائي للأرشفة إلى القيمة الافتراضية للموقع الخاص بك.",
    archiveOverrideInProgress: "تجاوز الأرشفة بانتظار موافقة ARC",
    archiveOverrideAccepted: "فترة تجاوز الأرشفة مكتملة",
    archiveOverrideRejected: "فترة تجاوز الأرشفة المحددة غير مسموح بها من قبل مركز سجلات الأرشيف. تم إرجاع الموعد النهائي للأرشفة إلى القيمة الافتراضية للموقع الخاص بك.",
    scopeName: "اسم النطاق",
    shortName: "الاسم المختصر",
    invited: "تمت الدعوة",
    invitedOn: "تمت الدعوة بتاريخ",
    instructions: "التعليمات",
    updates: "التحديثات",
    viewinstructions: "عرض التعليمات",
    viewinstructionsupdates: "عرض التحديثات",
    more: "المزيد",
    addmembers: "إضافة أعضاء فريق",
    statusChangeDate: "تم تغيير الحالة",
    deletedBy: "تم الحذف بواسطة ",
    deletedDate: "تم الحذف",
    deactivatedBy: "تم إلغاء التنشيط بواسطة",
    deactivatedDate: "تم إلغاء التنشيط",
    requestUserName: "تم الطلب بواسطة",
    requestDate: "تم الطلب",
    archiveDate: "تمت الأرشفة",
    receivedFrom: "مستلم من",
    received: "مستَلم",
    clear: "مسح",
    clearAll: "Clear all",
    type: "النوع",
    engagementhasbeendeleted: "تم حذف مهمة التدقيق المحددة من قبل مستخدم آخر. قم بتحديث الصفحة وحاول مرة أخرى.",
    manageTeam: "إدارة الفريق",
    childinprogress: "مهمة التدقيق قيد التنفيذ",
    // SeeMore component
    showMore: "إظهار المزيد",
    showLess: "إظهار أقل",
    showMoreEllipsis: "إظهار المزيد...",
    showLessEllipsis: "إظهار أقل...",
    accepted: "مقبول",
    rejected: "مرفوض",
    deniedBy: "تم الرفض بواسطة ",
    engagementstatusrestored: "مسترجع",
    engagementstatusmarkedfordeletion: "تم التعليم للحذف",
    delegaterequired: "التفويض مطلوب",
    username: "اسم المستخدم",
    noresults: "لم يتم العثور على أي نتائج، يُرجى إعادة المحاولة",
    importdatapackageconcurrencymessage: "تم تغيير حالة طلب النسخ. قم بتحديث الصفحة وحاول مرة أخرى.",
    selectdelegateuser: "حدد تفويض",
    eyusersearchplaceholder: "أدخل الاسم أو عنوان البريد الإلكتروني للبحث",
    optional: "(اختياري)",
    engagementAlerts: "تنبيهات مهمة التدقيق",
    engagementMetrics: "مقاييس مهمة التدقيق",
    overdue: "متأخر",
    /*day labels*/
    mon: "الاثنين",
    tues: "الثلاثاء",
    wed: "الأربعاء",
    thurs: "الخميس",
    fri: "الجمعة",
    sat: "السبت",
    sun: "الأحد",
    canvas: "EY Canvas",
    overrideArchiveDateModalHeader: "تجاوز الموعد النهائي للأرشفة",
    overrideArchiveDateModalMessage: "هل أنت متأكد من أنك تريد تجاوز الموعد النهائي للأرشفة؟",
    overrideArchiveDateModalComboPlaceholder: "تجاوز الموعد النهائي للأرشفة",
    createdOn: "تاريخ الإنشاء",
    expiresOn: "ينتهي في",
    archiveDeadlineDate: "الموعد النهائي للأرشفة",
    defaultOverriden: "تم تجاوز الموعد الافتراضي",
    selectexistingworkspace: "حدد مساحة عمل حالية",
    importtonewworkspace: "استيراد إلى مساحة عمل جديدة",
    groupaudit: "طلب رابط تدقيق المجموعة",
    gaacceptdeclinedelegatequestionlabel: "ماذا تود أن تفعل مع طلب الرابط هذا؟",
    gaacceptselectengagementquestionlabel: "حدد مهمة تدقيق لربطها",
    gadelegatequestionlabel: "تفويض طلب رابط تدقيق المجموعة",
    gadeclinequestionlabel: "رفض طلب رابط تدقيق المجموعة",
    garegioncomponent: "المنطقة / المكون",
    gascope: "النطاق",
    gamxTransfer: "نقل من GAMx",
    closeNotificationCenter: "إغلاق مركز الإشعارات",
    contextMenucrp: "إنشاء نسخة مراجعة",
    workingoffline: "العمل بدون اتصال",
    trainingoffline: "Working offline (training)",
    training: "Training",
    crpdescription: "Create a copy of engagement for external reviewers to review from the EY Canvas External Access Portal. Once created, you can manage the expiry and members of this engagement copy from the Review engagements list.",
    expiry: "Expiry",
    enteremail: "Enter email address",
    create: "Create",
    metricsPlaceholderText: "لا تتوفر بيانات للإطار الزمني المحدد",
    deleteReviewCopy: "Delete review copy",
    deleteReviewCopyMsg: "Are you sure that you want to delete this review copy? Once deleted, the engagement will no longer be available and cannot be recovered.",
    crpNotAvailable: "Review copy cannot be created for this engagement because the Canvas External Access Portal is not available in this datacenter.",
    externalmember: "External Members",
    internalmember: "Internal Members",
    add: "Add",
    crpEditMembers: "Edit members",
    invalidEmail: "Enter a valid email address",
    emailNotFound: "Email address not found",
    dupExternalUser: "External member already added",
    noExternalUser: "No external members have been added.",
    expiresIn: "Expires in",
    days: "days",
    reviewengagementname: "Review engagement name",
    enternameoremail: "Enter name or email address",
    options: "Options",
    userNotFound: "User not found",
    remove: "Remove",
    crpEditHeader: "Edit review copy",
    globalexceptionrestore: 'Global exception restore',
    softwareUpdate: 'Software updates',
	updateNow: "Update now",
	updateMsg: "Software updates are available for EY Canvas. Do you want to download and install them? IF ANY DOCUMENTS ARE OPEN OR YOU HAVE UNSAVED WORK, SELECT UPDATE LATER. If all documents are closed and work in EY Canvas is saved, select Update Now."
};
export const errors = {
    // GenericErrors Codes
    '0005': "المعاملة معلقة",
    '0006': "فشلت المعاملة",
    '0008': "لا يمكن أن يحتوي الوصف على أي من الأحرف التالية: //",
    '0010': "فشل استدعاء الأرشيف",

    0: "فشل الطلب.",
    928: "وصول غير مصرح به - ليس لديك صلاحية الوصول إلى الاختيار المحدد اتصل بمكتب الدعم للحصول على تعليمات حول كيفية الوصول، إذا كان ذلك مصرحاً.",

    1009: "لم يتم العثور على خادم البيانات",
    1010: "لم يتم العثور على مركز البيانات",
    1011: "لم يتم إعداد خادم البيانات لهذه الدولة",
    1013: "لم يتم العثور على معرف الدولة أو معرف مهمة التدقيق",
    1014: "لم يتم العثور على مركز بيانات الدولة",
    1016: "فشل ربط محدد قاعدة البيانات",
    1017: "oDataQuery لا يمكن أن يكون لا شيء أو فارغاً",
    1018: "oDataQuery لا يتضمن أية حقول",
    1019: "لم يتم العثور على مركز البيانات العالمي",
    1020: "المستخدم غير موجود في النظام",
    1021: "إعداد التقارير غير متوفر في الموقع المحدد. اتصل بمسؤول EY Canvas المحلي الخاص بك للمزيد من التفاصيل.",

    // Scope & Strategy 1021 - 1200
    1026: "لم يتم العثور على السطر",
    1030: "لم يتم العثور على اسم العميل",

	// Restore Engagement
	1101: "Unable to restore engagement to the country selected. Refresh the page and try again.",

    // Execution   1201-1400
    1219: "لم يتم العثور على الطريقة",
    1234: "معرف الدور لا يمكن أن يكون لا شيء أو فارغاً",
    1260: "تم حذف البند الذي تعمل عليه حالياً من قبل مستخدم آخر.",
    1300: "كائن طلب غير صالح",
    1301: "دور مهمة التدقيق غير  صالح",
    1302: "لغة المستخدم غير صالحة",
    1303: "لم يتم العثور على تفاصيل مستخدم مهمة التدقيق",
    1304: "موقع غير صالح",
    1305: "لم يتم العثور على اسم مهمة التدقيق",
    1306: "اسم مهمة التدقيق يتجاوز الحد الأقصى للطول (255 حرف)",
    1307: "لم يتم العثور على تاريخ نهاية الفترة المشمولة",
    1308: "التاريخ غير صالح",
    1309: "لم يتم العثور على تاريخ تقرير التدقيق،",
    1310: "لم يتم العثور على الموعد النهائي لأرشفة مهمة التدقيق",
    1311: "لا يمكن أن يكون الموعد النهائي لأرشفة مهمة التدقيق قبل تاريخ اليوم،",
    1312: "مهمة التدقيق لديها رموز مهمة تدقيق مكررة",
    1313: "لم يتم العثور على الموقع",
    1314: "لم يتم العثور على اسم مساحة العمل",
    1315: "اسم مساحة العمل يتجاوز الحد الأقصى للطول (255 حرف)",
    1316: "رمز العميل يتجاوز الحد الأقصى للطول (50 حرف)",
    1317: "اسم العميل يتجاوز الحد الأقصى للطول (255 حرف)",
    1318: "قيمة غير صالحة للعلامة للإشارة إلى ما إذا كانت مساحة العمل للتدريب",
    1319: "لم يتم العثور على علامة الإشارة إلى أن مساحة العمل للتدريب",
    1320: "لم يتم العثور على علامة الإشارة إلى أنه تم التحقق من العميل",
    1321: "قيمة غير صالحة للعلامة للإشارة إلى أنه تم التحقق من العميل",
    1322: "معرف العميل غير صالح",
    1323: "لم يتم العثور على رموز مهمة تدقيق",
    1324: "لم يتم العثور على معرف مهمة التدقيق",
    1325: "معرف المكتب الرئيسي غير صالح",
    1052: "معرف الإدارة غير صالح",
    1053: "معرف الإدارة الفرعية غير صالح",
    1326: "لم يتم العثور على معرف الدولة",
    1327: "معرف مساحة عميل غير صالح",
    1328: "حالة عضو فريق مهمة التدقيق غير صالحة",
    1329: "حالة مهمة التدقيق غير صالحة",
    1330: "تم حذف مهمة التدقيق مسبقاً من قبل عضو فريق آخر",
    1339: "تم تجاوز الحد الأقصى من عدد مهام التدقيق المسموح به",
    1340: "لم يتم العثور على تفاصيل الموقع",
    1341: "لم يتم استلام بيانات مهمة التدقيق للتحديث بشكل صحيح",
    1342: "لم يتم العثور على تفاصيل مهمة التدقيق لمهمة التدقيق الحالية",
    1343: "لم يتم العثور على بيانات ملخص مهمة التدقيق لمهمة التدقيق الحالية",
    1344: "مهمة التدقيق غير موجودة للمعرف الذي تم اختياره",
    1359: "إن معرف مركز البيانات لأحد مهام التدقيق النشطة غير فعال",
    1363: "فشل إنشاء مهمة التدقيق عالمياً",
    1364: "فشل إنشاء مهمة التدقيق في LDC",
    1373: "حدد مساحة عمل حالية أو قم بإنشاء مساحة عمل جديدة",
    1374: "اسم مهمة التدقيق موجود بالفعل. يرجى إدخال اسم مهمة تدقيق جديد لاستيراد القالب.",

    // Invite Team Members (1401 - 1500)
    1403: "رسالة الترحيب لمهمة التدقيق تتجاوز الحد الأقصى للطول (200 حرف)",
    1406: "لم يتم العثور على مجموعات مهمة التدقيق",
    1424: "لم يتم العثور على مهمة التدقيق أو تفاصيل المستخدم",
    1425: "لم يتم العثور على تفاصيل مهمة التدقيق",

    // User details Error Codes (1426 - 1434)
    1426: "الاسم الأول مطلوب",
    1427: "الاسم الأخير مطلوب",
    1428: "الأحرف الأولى مطلوبة",
    1429: "المكتب الرئيسي مطلوب",
    1430: "رقم الهاتف المفضل مطلوب",
    1431: "يلزم إدخال اللغة",
    1432: "صيغة التاريخ المفضلة مطلوبة",
    1433: "صيغة الرقم المفضل مطلوبة",
    1434: "تفاصيل المستخدم لا يمكن أن تكون لا شيء أو فارغة",
    1436: "تم اكتشاف تعارض. في حالة استمرار المشكلة، اتصل بمكتب الدعم.",
    1438: "مجموعة المستخدمين لا يمكن أن تكون فارغةً.",

    1502: "تعذر استكمال العملية في الوقت الحالي. قم بتحديث الصفحة وحاول مرة أخرى. في حالة استمرار المشكلة، اتصل بمكتب الدعم.",
    1504: "تعذر استكمال العملية في الوقت الحالي. قم بتحديث الصفحة وحاول مرة أخرى. في حالة استمرار المشكلة، اتصل بمكتب الدعم.",
    1505: "إدخال غير صالح",

    // Common control errors (1601 - 1700)
    1601: "نسبة مئوية غير صالحة",
    18000: "خطأ في معالجة مركز سجلات الأرشيف: لا يمكن تنفيذ العملية المطلوبة نظراً لأن مركز سجلات الأرشيف قيد الصيانة. يُرجى إعادة المحاولة لاحقاً.",
    18306: "خطأ في معالجة مركز سجلات الأرشيف: لا يمكن العثور على نموذج مركز سجلات الأرشيف المتعلق بمعرف Canvas ArchivalGUID",
    18307: "خطأ في معالجة مركز سجلات الأرشيف: تم حذف مهمة التدقيق ضمن ARC. لا يمكنك المتابعة بالخيار المحدد. خطأ معالجة ARC:",
    18309: "خطأ في معالجة مركز سجلات الأرشيف: لا يمكن تنفيذ العملية المطلوبة نظراً لأن مهمة التدقيق التي تمت أرشفتها ضمن قيود تنظيمية. اتصل بمسؤول مركز سجلات الأرشيف الخاص بك إذا كنت في حاجة لمزيد من المساعدة.",
    18320: "خطأ في معالجة مركز سجلات الأرشيف: إن نموذج مركز سجلات الأرشيف بالحالة معلق (متوقف مؤقتاً) ولا يمكن تعديله.",
    18327: "خطأ في معالجة مركز سجلات الأرشيف: إن نموذج مركز سجلات الأرشيف بالحالة معلق للحذف ولا يمكن تعديله.",
    18328: "خطأ في معالجة مركز سجلات الأرشيف: لا يمكن تنفيذ العملية المطلوبة نظراً لأن مهمة التدقيق محددة للحذف في ARC. اتصل بمسؤول مركز سجلات الأرشيف الخاص بك إذا كنت في حاجة لمزيد من المساعدة.",
    18329: "خطأ في معالجة مركز سجلات الأرشيف: مهمة التدقيق هذه غير متاحة للشركاء. اتصل بمسؤول مركز سجلات الأرشيف الخاص بك إذا كنت في حاجة لمزيد من المساعدة.",
    18330: "خطأ في معالجة مركز سجلات الأرشيف: لا يمكن تنفيذ العملية المطلوبة نظراً لأن مهمة التدقيق مؤمّنة بناءً على قيود تنظيمية. اتصل بمسؤول مركز سجلات الأرشيف الخاص بك إذا كنت في حاجة لمزيد من المساعدة.",
    2027: "طلب غير صحيح",
    2046: "غير مصرح به",
    2047: "محظور",
    2048: "لم يتم العثور على السجل",
    2049: "المنشأة المطلوبة كبيرة للغاية",
    2052: "غير مقبول",
    2053: "حدث خطأ أثناء استرجاع البيانات. قم بتحديث الصفحة وحاول مرة أخرى. في حالة استمرار المشكلة، اتصل بمكتب الدعم.",
    2056: "حدث خطأ عام",
    2057: "الخدمة غير متوفرة",

    // Roll Forward Error Codes (2301-2400)
    2301: "خلال الترحيل، لم يتم العثور على الملف الذي تمت أرشفته",
    2302: "خلال الترحيل، فشل استدعاء مرجع الأرشيف",
    2303: "خلال الترحيل، يحتوي الملف المؤرشف الذي تم إرجاعه على معرف مميز فارغ",
    2304: "خلال الترحيل، يحتوي الملف المؤرشف الذي تم إرجاعه على رابط URL فارغ لمخزن الملفات",
    2305: "خلال الترحيل، يحتوي الملف المؤرشف الذي تم إرجاعه على قيمة تجزئة فارغة",
    2306: "المستخدم غير مصرح له بتنفيذ هذا الإجراء",
    2307: "تم تجاوز العدد المسموح به للنسخ المسترجعة",

    // Link Error Codes
    2568: "الرابط لم يعد متوفراً نظراً لأنك لست نقطة الاتصال الحالية. يرجى تحديث الصفحة.",
    3005: "قام الفريق الرئيسي بالفعل بحذف المكون من مهمة تدقيق المجموعة",
    2571: "تم تحديث طلب الارتباط. يرجى تحديث الصفحة.",

    // Error for v1 engagement not supported features
    4001: "تم إنشاء مهمة التدقيق المحددة في إصدار قديم من EY Canvas ولا يمكن استعادتها حتى تتم ترقية مركز البيانات المحلي للمواقع المحددة إلى أحدث إصدار من EY Canvas. اتصل بمكتب الدعم المحلي لمزيد من المعلومات.",
    4002: "تم إنشاء مهمة التدقيق المختارة في إصدار قديم من EY Canvas وطلب الوصول غير متوفر حتى تتم ترقية مركز البيانات المحلي إلى أحدث إصدار. اتصل بمكتب الدعم المحلي لمزيد من المعلومات.",
    4214: "لا يمكن استيراد القالب إلى مساحة العمل في الموقع المحدد. حدد موقع مختلف وحاول مرة أخرى",

    // Common framework error
    4205: "لم يتم العثور على تفاصيل طلب النسخ",
    401000: "Network change is detected and please reload the page to continue",

    //CopyHub concurrency scenarios
    4217: "تم تغيير حالة طلب حزمة البيانات. قم بتحديث الصفحة وحاول مرة أخرى.",

    // Error for Mobility API
    4501: "لا تتوفر أي مهام في مهمة التدقيق",
    4502: "لا يوجد مهام مطلوبة للتنفيذ متوفرة في مهمة التدقيق",

    // Counrty API version error
    5001: "لدى الموقع المحدد نسخة قديمة من EY Canvas ولا يمكن ترحيل مهمة التدقيق إلى مساحة عمل جديدة حتى تتم ترقية مركز البيانات المحلي للموقع المحدد إلى أحدث إصدار من EY Canvas. اتصل بمكتب الدعم المحلي لمزيد من المعلومات،",

    //CRP checks
    5101: "لا يمكن إنشاء نسخة مراجعة لمهمة التدقيق الحالية نظراً لأن بوابة Canvas للوصول الخارجي غير متوفرة في مركز البيانات هذا.",
    5102: "هناك طلب نسخ آخر قيد التنفيذ لمهمة التدقيق. يُرجى إعادة المحاولة لاحقاً.",

    18100: "لم يتم العثور على الإدارة.",
    18101: "لم يتم العثور على الإدارة الفرعية."
};
export const actionTypes = {
    1: "رسائل النظام",
    2: "دعوات مهمة التدقيق",
    3: "تم رفض الوصول",
    4: "طلبات حذف مهمة التدقيق",
    5: "خطأ في إنشاء مهمة التدقيق",
    6: "طلب الوصول إلى مهمة التدقيق",
    7: "مهام التدقيق المحذوفة",
    8: "مهام التدقيق المؤرشفة",
    9: "مهام التدقيق المُلغى تنشيطها",
    10: "تجاوز الموعد النهائي للأرشفة",
    11: "طلبات رابط المجموعة",
    12: "تم استلام تعليمات المجموعة",
    13: "تم استلام النسخ",
    14: "مهمة التدقيق قيد التنفيذ"
};
export const timephaseType = {
    '-1': "تاريخ نهاية الفترة المشمولة",
    '-2': "تاريخ التقرير",
    '-3': "الموعد النهائي للأرشفة",
    '1': "النطاق",
    '2': "الاستراتيجية",
    '3': "معاينات",
    '4': "اختبار اجراء الرقابة المرحلي",
    '5': "اختبار جوهري مرحلي",
    '6': "المعاينات (عمليات نهاية السنة)",
    '7': "اختبار اجراء الرقابة لنهاية السنة",
    '8': "اختبار جوهري لنهاية السنة",
    '9': "الاستنتاج",
    '10': "غير منطبق",
    '11': "التنفيذ",
    '12': "نطاق واستراتيجية إدارة المشاريع",
    '13': "نطاق واستراتيجية أخرى",
    '14': "التقييم الموحد للمخاطر",
    '15': "تقييم المخاطر وحد الإفصاح",
    '16': "إنهاء النطاق والاستراتيجية",
    '17': "إدارة المشاريع المرحلية اللاحقة"
};
export const dataExport = [
	"نموذج الأرشفة",
	"نماذج Canvas",
	"طلبات العميل",
	"نماذج إجراء الرقابة",
	"المستندات",
	"المهام الخارجية",
	"ملف التعريف",
	"المهام",
	"أعضاء الفريق",
	"Trial Balance"
];
export const dataExportReportNames = {
	'نموذج الأرشفة': 'Archive Form',
	'نماذج Canvas': 'Canvas Form Report',
	'طلبات العميل': 'Client Request Report',
	'نماذج إجراء الرقابة': 'Control Report',
	'المستندات': 'All Documents Report',
	'المهام الخارجية': 'External Task Report',
	'ملف التعريف': 'Profile',
	'المهام': 'All Tasks Report',
	'أعضاء الفريق': 'Team Members',
	'Trial Balance': 'Numbers Report'
};
export const projectManagement = [
"تفاصيل المشاركة",
"مرحلة الإنجاز الرئيسية",
"ملاحظات المراجعة",
"اختبارات التحقق من الصحة"
];
export const projectManagementReportNames = {
	'تفاصيل المشاركة': 'Client Collaboration Dashboard',
	'مرحلة الإنجاز الرئيسية': 'Milestone Report',
	'ملاحظات المراجعة': 'Review Notes Report',
	'اختبارات التحقق من الصحة': 'Validation Checks Report'
};
export const summaryScreenDefaults = [
	"تقرير خطة التدقيق",
	"خطة التدقيق مع الدليل المرتبط",
	"Controls Review Tool (CRT)",
	"ملخص التقييم الموحد للمخاطر",
	"النتائج",
	"ملخص تطبيق تقنية المعلومات وهيكل الخدمات",
	"ملخص فئة المعاملات الجوهرية"
	// "مصفوفة التقييم الجوهري"
];
export const summaryScreenDefaultsReportNames = {
	'تقرير خطة التدقيق': 'Audit Plan Report',
	'خطة التدقيق مع الدليل المرتبط': 'Audit Plan with Related Evidence',
	'Controls Review Tool (CRT)': 'Controls Review Tool (CRT)',
	'ملخص التقييم الموحد للمخاطر': 'CRA Summary',
	'النتائج': 'Findings',
	'ملخص تطبيق تقنية المعلومات وهيكل الخدمات': 'ITSO CRT',
	'ملخص فئة المعاملات الجوهرية': 'SCOT Summary',
	'مصفوفة التقييم الجوهري': 'Substantive Evaluation Matrix'
};
export const summaryScreenInterim = [
"تقرير خطة العمل",
"خطة العمل مع الدليل المرتبط"
];
export const summaryScreenInterimReportNames = {
	'تقرير خطة العمل': 'Audit Plan Report',
	'خطة العمل مع الدليل المرتبط': 'Audit Plan with Related Evidence'
};
export const summaryScreenGroupAudit = [
	"تقرير خطة التدقيق",
	"خطة التدقيق مع الدليل المرتبط",
	"Controls Review Tool (CRT)",
	"ملخص التقييم الموحد للمخاطر",
	"النتائج",
	"ملخص التعليمات الجماعية",
	"Group Report",
	"ملخص هيكل المجموعة",
	"ملخص تطبيق تقنية المعلومات وهيكل الخدمات",
	"ملخص فئة المعاملات الجوهرية"
	// "مصفوفة التقييم الجوهري"
];
export const summaryScreenGroupAuditReportNames = {
	'تقرير خطة التدقيق': 'Audit Plan Report',
	'خطة التدقيق مع الدليل المرتبط': 'Audit Plan with Related Evidence',
	'Controls Review Tool (CRT)': 'Controls Review Tool (CRT)',
	'ملخص التقييم الموحد للمخاطر': 'CRA Summary',
	'النتائج': 'Findings',
	'ملخص التعليمات الجماعية': 'Group Instruction Summary',
	'Group Report': 'Group Report',
	'ملخص هيكل المجموعة': 'Group Structure Summary',
	'ملخص تطبيق تقنية المعلومات وهيكل الخدمات': 'ITSO CRT',
	'ملف التعريف': 'Profile',
	'ملخص فئة المعاملات الجوهرية': 'SCOT Summary',
	'مصفوفة التقييم الجوهري': 'Substantive Evaluation Matrix'
};
export const summaryScreenGroupAuditInterim = [
	"ملخص التعليمات الجماعية",
	"ملخص هيكل المجموعة",
	"تقرير خطة العمل",
	"خطة العمل مع الدليل المرتبط"
];
export const summaryScreenGroupAuditInterimReportNames = {
	'ملخص التعليمات الجماعية': 'Group Instruction Summary',
	'ملخص هيكل المجموعة': 'Group Structure Summary',
	'تقرير خطة العمل': 'Audit Plan Report',
	'خطة العمل مع الدليل المرتبط': 'Audit Plan with Related Evidence'
};
export const rolelistitems = [{
        id: 8,
        label: "الموظفين"
    },
    {
        id: 7,
        label: "مدقق رئيسي"
    },
    {
        id: 6,
        label: "مدير"
    },
    {
        id: 5,
        label: "مدير رئيسي"
    },
    {
        id: 3,
        label: "مدير تنفيذي"
    },
    {
        id: 4,
        label: "مسؤول"
    },
    {
        id: 11,
        label: "شريك آخر"
    },
    {
        id: 2,
        label: "شريك التدقيق"
    },
    {
        id: 1,
        label: "الشريك المسؤول"
    },
    {
        id: 10,
        label: "مراجع جودة التدقيق"
    },
    {
        id: 17,
        label: "مراجع جودة التدقيق"
    },
    {
        id: 16,
        label: "المستشار العام"
    }
];
export const roleType = {
    '11': "شريك آخر",
    '24': "آخر (على سبيل المثال، مراجعة العناية الواجبة)",
    '15': "خدمات الدعم التنفيذي",
    '18': "فريق مكونات ML",
    '17': "مراجع جودة التدقيق",
    '1': "الشريك المسؤول",
    '2': "شريك التدقيق",
    '9': "متدرب",
    '13': "خدمات الاستشارية (الموارد البشرية- الخدمة الاستشارية للمعاملات- الخدمة الاستشارية IT - أخرى",
    '14': "الضرائب",
    '27': "القطاع",
    '10': "مراجع جودة التدقيق",
    '25': "المكتب",
    '8': "الموظفين",
    '20': "موظف العميل",
    '19': "مشرف العميل",
    '5': "مدير رئيسي",
    '16': "المستشار العام",
    '26': "المنطقة",
    '23': "منظم",
    '22': "فريق التدقيق الداخلي",
    '3': "مدير تنفيذي",
    '21': "مشرف التدقيق الداخلي",
    '6': "مدير",
    '4': "مسؤول",
    '7': "مدقق رئيسي",
    '12': "مركز الكفاءات العالمي",
    '28': "محلي",
    '29': "عالمي"
};
export const copyObjectGroupType = {
    1: "مهمة التدقيق",
    2: "الحسابات",
    3: "فئات المعاملات الجوهرية",
    4: "تطبيقات تقنية المعلومات وهياكل الخدمات",
    5: "الطلبات",
    6: "الدليل",
    7: "الأرقام",
    8: "الطلبات والمهام الخارجية",
    9: "الملفات المؤقتة",
    10: "دليل الفترة السابقة",
    11: "الملفات المحذوفة مؤخراً",
    12: "ملفات السجل",
    13: "نموذج مهمة التدقيق",
    14: "استرجاع نسخة من GCO",
    15: "نسخة المراجعة الخارجية",
    16: "المراجعة الخارجية لمهمة التدقيق النشطة",
    17: "نماذج Canvas"
};
export const EngagementStatusText = [
"غير محدد",
"نشط",
"معلق",
"مؤرشف",
"محذوف",
"الأرشفة قيد التنفيذ",
"تمت إعادة التنشيط",
"مسترجع",
"تم التعليم للحذف",
"خطأ في الأرشفة",
"خطأ في الترحيل",
"خطأ في إعادة التنشيط",
"خطأ في الاسترجاع",
"الترحيل قيد التنفيذ",
"إعادة التنشيط قيد التنفيذ",
"الاسترجاع قيد التنفيذ",
"ترحيل GAMx قيد التنفيذ",
"خطأ في ترحيل GAMx",
"إنشاء المعاملة معلق",
"فشل إنشاء المعاملة",
"تحديث المعاملة معلق",
"فشل تحديث المعاملة",
"النسخ قيد التنفيذ",
"خطأ في النسخ",
"خطأ في نسخ القالب",
"المراجعة الخارجية لمهمة التدقيق قيد التنفيذ",
"خطأ في المراجعة الخارجية لمهمة التدقيق",
"المراجعة الخارجية لمهمة التدقيق"
];
export const overdueIssues = {
    partnerInvitationPending: "دعوة الشريك معلقة",
    eqrInvitationPending: "دعوة مراجع جودة التدقيق معلقة",
    invalidEngagementCode: "رمز مهمة تدقيق غير صالح",
    overdueArchiveDeadline: "تم تجاوز الموعد النهائي للأرشفة",
    upcomingArchiveDeadline: "الموعد النهائي للأرشفة المقبل",
    contentDeliveryInProgress: "تسليم المحتوى قيد التنفيذ",
    overdueMilestones: "Team milestones overdue",
    overduePartnerTasks: "تم تجاوز مهام الشريك",
    overdueEqrTasks: "تم تجاوز مهام مراجع جودة التدقيق"
};
export const engagementMetricNames = [{
        propName: 'preparer',
        value: "للإعداد"
    },
    {
        propName: 'forMyReview',
        value: "للمراجعة"
    },
    {
        propName: 'upcomingReview',
        value: "المراجعة القادمة"
    },
    {
        propName: 'reviewNotesAssigned',
        value: "ملاحظات لي"
    },
    {
        propName: 'reviewNotesAuthored',
        value: "ملاحظات من جانبي"
    },
    {
        propName: 'clientRequests',
        value: "الطلبات"
    },
    {
        propName: 'timephases',
        value: "الأُطر الزمنية"
    },
    {
        propName: 'groupTasks',
        value: "مهام المجموعة"
    },
    {
        propName: 'groupDeliverables',
        value: "المحصلات النهائية للمجموعة"
    },
    {
        propName: 'automationDocuments',
        value: "أتمتة"
    }
];
export const metricFilterComingDue = [{
        value: 1,
        label: "مستحق غداً"
    },
    {
        value: 3,
        label: "مستحق خلال 3 أيام المقبلة"
    },
    {
        value: 5,
        label: "مستحق خلال 5 أيام المقبلة",
        isDefault:true 
    },
    {
        value: 10,
        label: "مستحق خلال 10 أيام المقبلة"
    },
    {
        value: 30,
        label: "مستحق خلال 30 يوماً المقبلة",
        isMax: true
    }
];
export const metricFilterWhatsNew = [{
        value: 1,
        label: "ما الجديد أمس"
    },
    {
        value: 3,
        label: "ما الجديد في الثلاثة أيام الأخيرة"
    },
    {
        value: 5,
        label: "ما الجديد في الخمسة أيام الأخيرة",
        isDefault: true
    },
    {
        value: 10,
        label: "ما الجديد في العشرة أيام الأخيرة",
        isMax: true
    }
];
export const engagementStatuses = {
    1: "نشط",
    2: "معلق",
    3: "مؤرشف",
    4: "محذوف",
    5: "الأرشفة قيد التنفيذ",
    6: "تمت إعادة التنشيط",
    7: "مسترجع",
    8: "تم التعليم للحذف",
    9: "خطأ في الأرشيف",
    10: "خطأ في الترحيل",
    11: "خطأ في إعادة التنشيط",
    12: "خطأ في الاسترجاع",
    13: "الترحيل قيد التنفيذ",
    14: "إعادة التنشيط قيد التنفيذ",
    15: "الاسترجاع قيد التنفيذ",
    16: "ترحيل GAMX قيد التنفيذ",
    17: "خطأ في ترحيل GAMX",
    18: "إنشاء المعاملة معلق",
    19: "فشل إنشاء المعاملة",
    20: "تحديث المعاملة معلق",
    21: "فشل تحديث المعاملة",
    22: "النسخ قيد التنفيذ",
    23: "خطأ في النسخ",
    24: "خطأ في نسخ القالب",
    25: "المراجعة الخارجية لمهمة التدقيق قيد التنفيذ",
    26: "خطأ في المراجعة الخارجية لمهمة التدقيق",
    27: "المراجعة الخارجية لمهمة التدقيق",
    28: "تم التطويع إلى معزول عام",
    29: "تم التطويع إلى معزول عام مؤرشف",
    30: "تسليم المحتوى قيد التنفيذ",
    31: "خطأ في تسليم المحتوى",
    32: "فشل تسليم المحتوى",
    33: "تقسيم مركز البيانات"
};
export const overrideArchiveDeadlineDateOptions = [{
        value: 0,
        label: "افتراضي"
	},	
    {
        value: 10,
        label: "10 أيام"
	},
	{
		value: 14,
		label: "14 أيام"
	},
    {
        value: 20,
        label: "20 يوماً"
    },
    {
        value: 21,
        label: "21 يوماً"
    },
    {
        value: 25,
        label: "25 يوماً"
    },
    {
        value: 30,
        label: "30 يوماً"
    },
    {
        value: 45,
        label: "45 يوماً"
    }
];
export const ExternalReviewCopyExpiryDays = [{
        value: 30,
        label: "30 days"
    },
    {
        value: 60,
        label: "60 days"
    },
    {
        value: 90,
        label: "90 days"
    }
];

export const serviceLines = [{
        servicelineid: 1,
        servicelinename: "Assurance"
    },
    {
        servicelineid: 3,
        servicelinename: "Strategy and Transactions"
    },
    {
        servicelineid: 2,
        servicelinename: "Consulting"
    },
    {
        servicelineid: 4,
        servicelinename: "Tax"
    },
    {
        servicelineid: 5,
        servicelinename: "GCO"
    }
];

export const subServiceLines = [{
        subservicelineid: 1,
        servicelineid: 1,
        subservicelinename: "Audit"
    },
    {
        subservicelineid: 2,
        servicelineid: 1,
        subservicelinename: "FIDS"
    },
    {
        subservicelineid: 3,
        servicelineid: 2,
        subservicelinename: "ITRA"
    },
    {
        subservicelineid: 9,
        servicelineid: 1,
        subservicelinename: "FAAS"
    },
    {
        subservicelineid: 10,
        servicelineid: 1,
        subservicelinename: "ACR"
    },
    {
        subservicelineid: 11,
        servicelineid: 1,
        subservicelinename: "CCaSS"
    },
    {
        subservicelineid: 4,
        servicelineid: 3,
        subservicelinename: "Capital Transformation"
    },
    {
        subservicelineid: 5,
        servicelineid: 3,
        subservicelinename: "Transaction Support"
    },
    {
        subservicelineid: 6,
        servicelineid: 2,
        subservicelinename: "Performance Improvement"
    },
    {
        subservicelineid: 7,
        servicelineid: 2,
        subservicelinename: "Risk"
    },
    {
        subservicelineid: 7,
        servicelineid: 23,
        subservicelinename: "Business Consulting"
    },
    {
        subservicelineid: 24,
        servicelineid: 2,
        subservicelinename: "Technology Consulting"
    },
    {
        subservicelineid: 8,
        servicelineid: 2,
        subservicelinename: "Other"
    },
    {
        subservicelineid: 12,
        servicelineid: 4,
        subservicelinename: "BTS"
    },
    {
        subservicelineid: 13,
        servicelineid: 4,
        subservicelinename: "Human Capital"
    },
    {
        subservicelineid: 14,
        servicelineid: 4,
        subservicelinename: "Law"
    },
    {
        subservicelineid: 15,
        servicelineid: 4,
        subservicelinename: "Indirect Tax"
    },
    {
        subservicelineid: 16,
        servicelineid: 4,
        subservicelinename: "GCR"
    },
    {
        subservicelineid: 17,
        servicelineid: 4,
        subservicelinename: "Transaction Tax"
    },
    {
        subservicelineid: 18,
        servicelineid: 4,
        subservicelinename: "ITS"
    },
    {
        subservicelineid: 19,
        servicelineid: 5,
        subservicelinename: "GCO"
    },
    {
        subservicelineid: 20,
        servicelineid: 2,
        subservicelinename: "Business Consulting"
    },
    {
        subservicelineid: 21,
        servicelineid: 2,
        subservicelinename: "Technology Consulting"
    },
    {
        subservicelineid: 22,
        servicelineid: 3,
        subservicelinename: "Transactions and Corporate Finance"
    },
    {
        subservicelineid: 23,
        servicelineid: 3,
        subservicelinename: "EY Parthenon"
    }
];
