/* eslint-disable prettier */
import {
	Addon
} from 'react-bootstrap/lib/InputGroup';

/**
 * Created by calhosh on 4/14/2017.
* RU resource file
 */
export const labels = {
	defaultDropdown: "Выпадающее меню",
	status: "Статус",
	language: "Язык",
	channel: "Канал",
	header: "Заголовок",
	headerName: "Наименование заголовка",
	sectionCustomLabelLabel: "Пользовательская метка",
	sectionNamePlaceHolder: "Ввести название раздела",
	showRelatedObjectLabel: "Показать связанные объекты",
	errorBanner: "{0} Ошибка(и)",
	engagements: "Аудиторские задания",
	selectedengagements: "Задание",
	addEngagement: "Добавить задание",
	archives: "Архивы",
	userInitialsTitle: "Параметры пользователя",
	clickForOptions: "Click for more options",
	comingDue: "Приближается срок",
	whatsNew: "Новое",
	auditMetrics: "Критерии аудита",
	id: "Идентификатор",
	archived: "Архивировано",
	myarchives: "Мои Архивы",
	allarchives: "Все Архивы",
	logout: "Выйти",
	norecords: "Данных не обнаружено",
	nomatches: "Соответствий не обнаружено",
	notemplates: "Для выбранного региона/подрегиона шаблоны отсутствуют.",
	pleasetryagain: "Повторите попытку",
	firstpage: "Первая Страница",
	prevpage: "Предыдущая Страница",
	nextpage: "Следующая Страница",
	lastpage: "Последняя Страница",
	allengagements: "Все аудиторские задания",
	searchPlaceholder: "Поиск",
	searchEngagementsPlaceholder: 'Search engagements',
	clearSearch: "Очистить поле поиска",
	searchTextPlaceHolder: "Поиск",
	show: "Показать",
	manageaccess: "Управление доступом",
	restore: "Восстановить",
	rollforward: "Перенести",
	viewaccesslist: "Просмотреть список пользователей",
	teammembers: "Члены команды",
	name: "Имя",
	initials: "Инициалы",
	role: "Функция",
	usersettings: "Пользовательские настройки",
	usersettingsnewengagements: "Настройки применимы к новым аудиторским заданиям",
	usersettingsallengagements: "Настройки применимы ко всем рабочим пространствам и аудиторским заданиям",
	enterfirstname: "Отобразить имя",
	enterlastname: "Отобразить фамилию",
	firstname: "Имя",
	lastname: "Фамилия",
	enter: "Ввод",
	select: "Выбор…",
	email: "Адрес электронной почты",
	contact: "Телефон",
	accesslist: "Список доступа",
	engagementsettings: "Настройки аудиторского задания",
	displayname: "Отобразить имя",
	signoffinitials: "Инициалы для подписи",
	primaryoffice: "Главный офис",
	roles: "Функция",
	phonenumber: "Телефон",
	globalapplicationsettings: "Основные настройки приложения",
	displaylanguage: "Язык отображения",
	preferreddateformat: "Предпочтительный формат даты",
	preferrednumberformat: "Предпочтительный числовой формат",
	save: "Сохранить",
	cancel: "Отменить",
	isRequired: "Необходимо указать {0}",
	arcretrymessage: "В процессе архивирования произошла ошибка. Дата записи для архива в настоящее время равна {0}. ЭЯ Канвас автоматически повторяет попытку вашей архивации, пожалуйста, подождите пятнадцать часов с момента отправки этого архива для завершения процесса автоматического восстановления, прежде чем обращаться в справочную службу. Если Служба поддержки может разрешить проблему без повторной активации проекта, дата архивирования будет по-прежнему равна {0}. Если Служба поддержки должна повторно активировать проект, чтобы команда приняла корректирующие меры, дата архивирования будет сброшена на то время, когда команда снова отправит архив. Если это приведет к позднему архивированию, команда должна включить в проект документацию, содержащую ссылку на номер билета службы поддержки, проблему и шаги, предпринятые для ее решения в соответствии с местной политикой архивирования.",
	ArchiveRetryLink: "Повторить попытку архивирования",
	workspacelist: "Перечень Рабочих Пространств",
	engagementslist: "Перечень Аудиторских Заданий",
	accessrequested: "Доступ запрошен",
	noaccess: "Доступ отсутствует",
	accessrejected: "Доступ не разрешен",
	requestaccess: "Запросить доступ",
	allarchiveinitialmsg: "Для начала работы ввести наименование Клиента, наименование Рабочего Пространства или Аудиторского Задания",
	noengagementsforusermessage: "У вас нет активных аудиторских заданий. Для начала работы кликните Создать аудиторское задание.",
	noengagementstoimportto: "У вас отсутствуют какие-либо задания для импорта.",
	favorites: "Избранное",
	portfolioview: 'All Engagements v2',
	reviewengagement: "Задания по обзорной проверке",
	noreviewengagementsmessage: "У вас нет заданий по обзорной проверке.",
	noreviewengagementsmessagecontact: "У вас отсутствуют какие-либо задания по обзорной проверке. Если вам требуется доступ, обратитесь к члену аудиторской команды.",
	nofavoriteengagementsmsg1: "У вас нет избранных аудиторских заданий.",
	nofavoriteengagementsmsg2: "Чтобы добавить задание в данное окно, перейдите в раздел Все аудиторские задания",
	nofavoriteengagementsmsg3: "и нажмите на значок избранного в карте аудиторских заданий.",
	markasfavorite: 'Mark as favorite',
	unmarkfromfavorites: 'Remove from favorites',
	selectworkspacenoclientworkspaceexists: "У вас отсутствуют действующие рабочие пространства. Нажмите на кнопку Вернуться и выберите Новое рабочее пространство из Этапа 1.",
	importpkgselectworkspacenoclientworkspaceexists: "У вас отсутствуют рабочие пространства. Нажмите кнопку 'Назад' и выберите 'Создать новое рабочее пространство' на предыдущем этапе.",
	expirydatelabel: 'This engagement will be deleted in',
	expirydatetodaytomorrowlabel: 'This engagement will be deleted',
	/*Reporting Hub*/
	reportingHub: "Подготовка отчетности",
	reportingHubTitle: "Подготовка отчетности в ЭЯ Канвас",
	selectEngagement: "Выбрать задание для получения доступа к отчетам",
	connectVPN: "Доступ к отчетам возможен только через прямое или удаленное подключение к сети ЭЯ",
	reportsNotLoaded: "Невозможно загрузить отчеты. Обновите страницу и повторите попытку. Если проблему не удается устранить, обратитесь в службу технической поддержки.",
	searchByEngagementNameOrClientId: "Выбрать задание ",
	dataExportLabel: "Экспорт данных",
	summaryScreensLabel: "Сводные окна",
	projectManagementLabel: "Управление проектами",
	engagement: "Наименование аудиторского задания",
	reportingNotAvailable: "Функция подготовки отчетности не доступна в выбранном территориальном подразделении. Дополнительную информацию можно получить у местного специалиста ЭЯ Канвас.",
	client: "Клиент",
	workspace: "Рабочее пространство",
	serviceline: "Сервисная линия",
	subserviceline: "Вспомогательная сервисная линия",
	engagementstartdate: "Дата окончания года",
	engagementenddate: "Дата окончания проверяемого периода",
	deleteafter: 'Delete after',
	auditorsreportdate: "Дата выпуска аудиторского заключения",
	createengagementquestionlabel: "Создать аудиторское задание",
	choosenewexistingquestionheader: "Создать задание в новом или действующем рабочем пространстве?",
	createengagementheader: "Создать задание",
	restoreengagementheader: "Восстановить задание ",
	createworkspacestepheader: "Создать новое рабочее пространство",
	selectexistingworkspacestepheader: "Выбрать существующее рабочее пространство",
	workspacenotelibilefornewengagement: 'Workspace not eligible for new engagements',
	choosenewexistingstepheader: "Новое или действующее рабочее пространство",
	editengagementwizheader: "Редактировать аудиторское задание",
	removeengagementwizheader: "Удалить задание",
	editworkspacewizheader: "Редактировать рабочее пространство",
	editworkspacestepfooter: "Нажать кнопку 'Сохранить и закрыть' для сохранения изменений в рабочем пространстве или конпку 'Сохранить и продолжить' для редактирования задания.",
	editwizheader: "Редактировать",
	newworkspace: "Новое рабочее пространство",
	existingworkspace: "Действующее рабочее пространство",
	back: "Назад",
	continue: "Продолжить",
	saveclose: "Сохранить и закрыть",
	savecontinue: "Сохранить и продолжить",
	finish: "Завершить",
	step01: "Этап 01",
	step02: "Этап 02",
	step03: "Этап 03",
	step04: "Этап 04",
	step05: "Этап 05",
	engagementname: "Наименование задания",
	confirmdecline: "Подтвердить отмену. Данная операция не может быть отменена.",
	confirmdeclinevalidation: "Подтвердить свое намерение отменить. ",
	responderror: "Вы уже ответили на это приглашение. Обновите страницу и повторите попытку.",
	engagementcode: "Код задания",
	engagementcodename: "Наименование кода задания",
	selectEngagementHeader: "Выбрать действующее рабочее пространство",

	selectworkspaceclient: "Выбрать клиента",
	selectclient: "Выбрать клиента, чтобы просмотреть соответствующие рабочие пространства",
	createnewworkspace: "Создать новое рабочее пространство",
	editworkspace: "Редактировать рабочее пространство",
	clientcode: "Код клиента",
	clientname: "Наименование клиента",
	invalidclientcode: "Неверный код клиента",
	workspacename: "Наименование рабочего пространства",
	location: "Территориальное подразделение",
	workspacecodenameplaceholder: "Ввести код клиента для поиска наименования клиента",
	engagementcodenameplaceholder: "Ввести код задания для поиска наименования кода",
	isprimary: "Основной",
	addengagementcode: "Добавить код аудиторского задания",
	engagementnamerequired: "Укажите наименование аудиторского задания",
	primaryofficerequired: "Укажите главный офис",
	languagerequired: "Укажите язык",
	servicelinerequired: "Укажите сервисную линию",
	subservicelinerequired: "Укажите вспомогательную сервисную линию",
	engagementcodevalidationmessage: "код аудиторского задания не совпадает с кодом клиента",
	engagementcoderequired: "Укажите код аудиторского задания",
	yearenddate: "Дата окончания года",
	periodenddate: "Дата окончания периода",
	reportdate: "Дата подготовки отчета",
	complete: "Завершено",
	end: "Конец",
	started: "Начато",
	notstarted: "Не начато",
	inprogress: "В процессе",
	inreview: "В стадии проверки",
	taskstatus: "Статус задачи",
	confirmlabel: "Подтвердить",
	submitlabel: "Отправить",
	editengagementquestionlabel: "Редактировать аудиторское задание",
	removeengagementquestionlabel: "Удалить задание",
	removeengaementconfirmationmessage1: "Удалить следующее задание из перечня моих заданий? Если вы удалите задание, у вас больше не будет к нему доступа.",
	removeengagementerrormessage: "Задание не может быть удалено из рабочего пространства, поскольку вы являетесь последним активным пользователем. Добавить еще одного члена команды или удалить задание.",
	deleteengagement: "Удалить аудиторское задание",
	deletreviewcopyoption: "Удалить",
	deleteengagementconfirmationmessage: "Отправить запрос на удаление задания?",
	deleteengagementconfirmationmessage1: "В случае задания, которое было прекращено или в рамках которого аудиторское заключение не выпускается, члены команды должны проконсультироваться со специалистами группы профессиональной практики на предмет необходимости создания архива по заданию.",
	deleteengagementconfirmationmessageoneuser: "Удалить следующее задание?",
	deleteengagementconfirmationmessageoneuser1: 'The archiving requirements in ',
	deleteengagementconfirmationmessageoneuser2: 'DOC+ARC 3.1',
	deleteengagementconfirmationmessageoneuser3: ' should be considered prior to deletion. For example, terminated engagements or those where we do not issue a report may require consultation with Professional Practice to determine whether an archive is required.',
	deleteengagementmarkeddelete: "Аудиторское задание уже отмечено на удаление",
	deleteengagementoption: "Удалить",
	deleteengagementreasonlabel: "Укажите причину удаления",
	deleteengagementreasonquestion: "Причина удаления данного задания?",
	deleteengagementreasonrequired: "Требуется указать обоснование. Выберите соответствующий вариант или укажите причину выше.",
	deleteengagementcommentreasonrequired: "Требуется указать обоснование. Выберите причину из перечня сверху.",
	deleteengagementcommentrequiredlabel: "Пожалуйста, укажите причину удаления",
	deletereasontrainingengagementlabel: "Обучающее задание",
	deletereasonrestoredorcopiedlabel: "Задание было восстановлено или скопировано исключительно в справочных целях",
	deletereasoncreatedbymistakelabel: "Создано по ошибке",
	deletereasonotherlabel: "Прочее",
	markeddeleteconfirm: "Подтвердить удаление",
	deleterequestbannertext1: "Запрос на удаление от",
	deleterequestbannertext2: "назад",
	markeddeleteconfirmmessage: "Удалить следующее задание?",
	markeddeleteconfirmmessage1: "После подтверждения удаления данная операция не может быть отменена.",
	markeddeletereasonlabel: "Причина удаления",
	engagementlistplaceholder: "Выбрать аудиторское задание",
	engagementrequired: "Укажите аудиторское задание",
	restoreconfirmationstepheader: "Подтверждение",
	restoreconfirmationheader: "Вы уверены, что хотите восстановить задание?",
	selectarchiveheader: "Выбрать заархивированный файл",
	restoreengagementstepheader: "Восстановить задание",
	chooserollfwdhdr: "Перенести аудиторское задание в новое или действующее рабочее пространство",
	rollfwdstephdrext: "Перенести аудиторское задание в действующее рабочее пространство",
	rollfwdstephdrnew: "Перенести аудиторское задание в новое рабочее пространство",
	rollfwdhdrqstn: "Перенести аудиторское задание в новое или действующее рабочее пространство?",
	rollforwardengagementstepheader: "Создать задание из существующего",
	genericerror1: "Ошибка, повторите попытку или обратитесь в службу поддержки",
	restoreengagementmaxcopies: "Количество восстановленных копий данного задания достигло максимального значения ({0}). Запрос доступа к имеющейся копии.",
	restoreengagementengcopies: "Имеется {0} восстановленных копий данного задания:",
	restoreengagementengcopy: "Имеется {0} восстановленная копия данного задания:",
	restoreengagementengnocopies: "Прочие восстановленные копии данного задания отсутствуют.",
	globalexceptionrestoreengagementengcopies: "Восстановленные копии {0} данного задания доступны.  Установите флажок выше для восстановления с исключением на глобальном уровне.",
	globalexception: "Исключение на глобальном уровне",
	globalexceptioncheckerror: "Установите флажок Исключения на глобальном уровне, чтобы продолжить.",
	restoreengagementrestoredon: "восстановлено",
	restoreengagementusers: "пользователи",
	restoreengagementuser: "пользователь",
	at: "в",
	arcfileuserunavailable: "Пользователь недоступен",

	restorehdrqstn: "Восстановить задание в новом или существующем рабочем пространстве?",
	chooserestorehdr: "Восстановить в новом или существующем рабочем пространстве",
	restorestephdrext: "Восстановить в существующем рабочем пространстве",
	restorestephdrnew: "Восстановить в новом рабочем пространстве",

	close: "Закрыть",
	confirm: "Подтвердить",
	settings: "Настройки",
	expand: "Развернуть",
	collapse: "Свернуть",
	contextMenuEdit: "Редактировать",
	contextMenuEditEngagement: "Редактировать задание",
	contextMenuRemoveEngagement: "Убрать аудиторское задание",

	contextMenuDeleteEngagement: "Удалить аудиторское задание",
	contextMenuEditWorkspace: "Редактировать рабочее пространство",
	contextMenuDelete: "Удалить",
	contextMenuOverrideArchiveDate: "Изменить крайний срок архивации",
	na: "Н/П",
	archivesubmittedby: "Отправлено",
	archivedateandtime: "Дата и время",
	notifications: "Уведомления",
	nonotificationmsg: "У вас отсутствуют уведомления.",
	notifcenter: "Центр уведомлений",
	notifitemspendingact: "объекты, в отношении которых требуется действие",
	accept: "Принять",
	reject: "Отклонить",
	acceptall: "Принять все",
	rejectall: "Отклонить все",
	notifEngInvite: "Приглашение для участия в задании",
	notifengaccesreq: "Запрос на получение доступа к заданию",
	keyevents: "Ключевые события",
	milestones: "Ключевые этапы",
	importselecttemplateheader: "Выбрать шаблон для использования",
	importtemplateheader: "Выбрать регион/подрегион, из которого вы хотите импортировать",
	import: "Импорт",
	importregionstepheader: "Выбрать Регион/Подрегион",
	importtemplatestepheader: "Выбрать шаблон",
	importtemplatemsg: "Выбрать регион/подрегион, чтобы продолжить",
	importselecttemplatemsg: "Выбрать шаблон, чтобы продолжить",
	importengagementheader: "Импортировать шаблон",
	importhdrqstn: "Импортировать в новое или существующее рабочее пространство",
	importstephdrext: "Импортировать в существующее рабочее пространство",
	importstephdrnew: "Импортировать в новое рабочее пространство",
	chooseimporthdr: "Импортировать в новое или существующее рабочее пространство",
	selectexistingworkspacemsg: "Выбрать рабочее пространство, чтобы продолжить",
	selectaclienttocontinuemsg: "Выбрать клиента для продолжения",
	selectengagementmsg: "Выбрать аудиторское задание, чтобы принять пакет данных",
	selectgaengagementmsg: "Для принятия запроса на привязку задач необходимо выбрать задание",
	arearegion: "Регион/Подрегион",
	importdatapackagequestionlabel: "Выберите дальнейшие действия",
	sentby: "Отправитель",
	on: "дата",
	by: "посредством",
	view: "Предоставление данных",
	sourceengagement: "Исходное задание",
	package: "Пакет",
	decline: "Отклонить",
	delegate: "Делегировать",
	chooseaction: "Выбрать действие",
	selectworkspace: "Выбрать рабочее пространство",
	declinepackage: "Отклонить запрос",
	delegatepackage: "Делегировать пакет",
	selectengagement: "Выбрать задание",
	datapackagerequest: "Запрос в отношении пакета данных",
	respond: "нажмите для ответа",
	deletionrequestfor: "Получен запрос на удаление в отношении",
	invalidengagementcode: "Неверный код задания",
	noNotifications: "Уведомления отсутствуют",
	bellIconTitle: "Центр уведомлений",
	restoredengagement: "Восстановленное задание",
	profiledeliveryinprogress: "Выполняется создание профиля",
	completeprofileheader: "Заполнить профиль",
	completeindependenceheader: "Заполнить форму «Подтверждение независимости» ",
	retry: "Повторить попытку",
	errorDate: "Ошибка",
	invitationDate: "Дата приглашения",
	engCreationFailedWarning: "Не удалось создать Задание. Обновите страницу и повторите попытку. Если проблему не удалось устранить, обратитесь в службу технической поддержки.",
	deletedEngagementWarning: "Если аудиторское задание было активным и удалено по ошибке, просьба незамедлительно обратиться в службу технической поддержки.",
	searchEngagementWarning: "Поиск данных заданий для продолжения работы. ",
	archiveOverrideWarning: "Архивирование выбранного периода запрещено АЦ. Установлены исходные настройки в отношении предельной даты архивирования для вашего месторасположения. ",
	archiveOverrideInProgress: "Ожидается утверждение изменения крайнего срока архивации АЦ",
	archiveOverrideAccepted: "Крайний срок архивации изменен",
	archiveOverrideRejected: "Изменение выбранного периода архивации запрещено АЦ. Крайний срок архивации был изменен на срок, предусмотренный для вашего региона.",
	scopeName: "Наименование объема",
	shortName: "Краткое наименование",
	invited: "Приглашено",
	invitedOn: "Приглашено на",
	instructions: "инструкции",
	updates: "обновления",
	viewinstructions: "Просмотр инструкций",
	viewinstructionsupdates: "Просмотр обновлений",
	more: "Дополнительно",
	addmembers: "Добавить членов",
	statusChangeDate: "Статус изменен",
	deletedBy: "Удалено (указать кем) ",
	deletedDate: "Удалено",
	deactivatedBy: "Деактивировано (указать кем)",
	deactivatedDate: "Деактивировано",
	requestUserName: "Отправитель",
	requestDate: "Запрошено",
	archiveDate: "Архивировано",
	receivedFrom: "Получено от",
	received: "Получено",
	clear: "Очистить",
	clearAll: "Очистить все",
	type: "Тип",
	engagementhasbeendeleted: "Выбранное аудиторское задание было удалено другим пользователем. Обновите страницу и повторите попытку.",
	manageTeam: "Управление командой",
	childinprogress: "Задание в процессе настройки",
	// SeeMore component
	showMore: "Отобразить дополнительные опции",
	showLess: "Скрыть дополнительные опции",
	showMoreEllipsis: "Отобразить дополнительные опции...",
	showLessEllipsis: "Скрыть дополнительные опции...",
	accepted: "Принято",
	acceptedOn: 'Accepted on:',
	rejected: "Отклонено",
	deniedBy: "Отклонено (указать кем) ",
	engagementstatusrestored: "Восстановлено",
	engagementstatusmarkedfordeletion: "Отмечено на удаление",
	delegaterequired: "Требуется делегирование",
	username: "Имя пользователя",
	noresults: "По вашему запросу ничего не найдено, повторите попытку",
	importdatapackageconcurrencymessage: "Статус запроса на копирование изменен. Обновите страницу и повторите попытку.",
	selectdelegateuser: "Выбрать делегата",
	eyusersearchplaceholder: "Ввести имя или адрес электронной почты для поиска",
	optional: "(Не обязательно)",
	engagementAlerts: "Бюллетени по аудиторскому заданию",
	engagementMetrics: "Показатели аудиторского задания",
	overdue: "Срок превышен",
	/*day labels*/
	mon: "Пн",
	tues: "Вт",
	wed: "Ср",
	thurs: "Чт",
	fri: "Пт",
	sat: "Сб",
	sun: "Вс",
	canvas: "EY Canvas",
	overrideArchiveDateModalHeader: "Изменить крайний срок архивации",
	overrideArchiveDateModalMessage: "Вы уверены, что хотите изменить крайний срок архивации?",
	overrideArchiveDateModalComboPlaceholder: "Изменить крайний срок архивации",
	createdOn: "Дата создания",
	expiresOn: "Дата окончания",
	archiveDeadlineDate: "Крайний срок архивации",
	defaultOverriden: "Установленный по умолчанию крайний срок изменен",
	selectexistingworkspace: "Выбрать существующее рабочее пространство",
	importtonewworkspace: "Импортировать в новое рабочее пространство",
	groupaudit: "Запрос на привязку к аудиторской группе",
	gaacceptdeclinedelegatequestionlabel: "Какие действия выполнить в отношении запроса на привязку ?",
	gaacceptselectengagementquestionlabel: "Выбрать задание для привязки",
	gadelegatequestionlabel: "Делегировать запрос на привязку к аудиторской группе",
	gadeclinequestionlabel: "Отклонить запрос на привязку",
	garegioncomponent: "Регион/Компонент",
	gascope: "Объем",
	gamxTransfer: "Перенести данные из ГАМикс",
	closeNotificationCenter: "Закрыть центр уведомлений",
	contextMenucrp: "Создать копию для проверки",
	workingoffline: "Режим оффлайн",
	trainingoffline: "Работа в режиме офлайн (обучение)",
	training: "Обучение",
	crpdescription: "Создать копию для лиц, осуществляющих внешнюю проверку.",
	crpeditdescritpion: "Редактировать аудиторское задание или срок его истечения. Задания с истекшим сроком будут удалены автоматически.",
	expiry: "Срок выполнения",
	enteremail: "Введите адрес электронной почты",
	create: "Создать",
	metricsPlaceholderText: "Отстуствуют данные для выбранного периода",
	deleteReviewCopy: "Удалить копию для проверки",
	deleteReviewCopyMsg: "Удалить данную копию для проверки? После удаления задание не будет доступно, и его нельзя будет восстановить.",
	crpNotAvailable: "Создание копии для проверки для данного задания невозможно , поскольку внешний портал доступа к Канвас в данное время не подключен. Обратитесь в службу технической поддержки для получения более подробной информации.",
	externalmember: "Внешние участники",
	internalmember: "Внутренние участники",
	add: "Добавить",
	crpEditMembers: "Редактировать",
	invalidEmail: "Введите действующий адрес электронной почты",
	emailNotFound: "Адрес электронной почты не обнаружен",
	dupExternalUser: "Внешний участник добавлен",
	noExternalUser: "Внешние участники не добавлены.",
	expiresIn: "Срок выполнения задания истекает через",
	days: "дней",
	today: 'today',
	tomorrow: 'tomorrow',
	reviewengagementname: "Наименование задания по обзорной проверке",
	enternameoremail: "Введите имя или адрес электронной почты",
	options: "Варианты",
	userNotFound: "Пользователь не найден",
	remove: "Удалить",
	crpEditHeader: "Редактировать копию для проверки",
	globalexceptionrestore: "Восстановление глобального исключения",
	expired: "Истекший",
	softwareUpdate: "Программные обновления",
	updateNow: "Обновить сейчас",
	updateMsg: "Есть обновления для программного обеспечения ЭЯ Канвас. Выберите Обновить сейчас для загрузки и установки обновлений. Страница будет обновлена.",
	preferredlandingpageview: "Предпочтительный вид начальной страницы",
	preferredlandingpagefilter: "Предпочтительный фильтр начальной страницы",
	engagementlist: "Список проетков",
	workspacelistusersettings: "Список рабочих областей",
	engagementsclientstoload: "Количество проектов/клиентов, загружаемых при каждом доступе к приложению",
	favoriteengagements: "Любимые проекты",
	preferredEngagementDashboard: "Настройки информационной панели задания",
	preferredEngagementDashboardTeamMembers: "Члены команды",
	preferredEngagementDashboardExecutives: "Руководящие сотрудники",
	linkedHelixProjects: "Сделана ссылка на ЭЯ Хеликс",
	loadingLabel: "Идет загрузка...",
	loadingErrorText: "Произошла ошибка при загрузке в Таймлайн. Обновите страницу и повторите попытку.",
	selectYear: "Выбрать год (обязательно)",
	clientNameOrId: "Наименование клиента или идентификатор",
	engagementNameOrId: "Наименование задания или идентификатор",
	engagementCodeOrCodeName: "Код задания или наименования кода",
	searchArchives: "Поиск",
	clearSearchArchives: "Сбросить результаты поиска",
	searchArchivesMessage: "Для начала работы выберите год архивирования задания. Далее задайте поиск по одному или более вариантам, предложенным ниже:",
	titleUpdateProfile: "Информация",
	msgUpdateProfileLn1: "Вы обновили язык в задании. Чтобы получить контент на этом языке, перейдите в раздел «Обновления контента задания» и выберите Обновление глобального контента.",
	sessionTimerMsg1: "Ваш текущий сеанс работы скоро истечет в связи с отсутствием активности.",
	sessionTimerMsg2: "Для вашей безопасности сеанс работы в ЭЯ Канвас заканчивается автоматически после 30 минут отсутствия активности.",
	sessionTimerMsg3: "Ваш текущий сеанс работы истечет через {0} минут.",
	sessionTimerMsg4: "Если вы хотите оставаться в системе, нажмите",
	sessionTimerHeader: "Автоматическое завершение сеанса для вашей защиты",
	theme: "Тема",
	light: "Светлый",
	dark: "Темный",
	chooseDisplayTheme: "Выберите тему дисплея по умолчанию.",
	searchByCountry: "Выберите страну",
	primaryEngagementLabel: "Задание основной команды",
	componentEngagementLabel: "Задание команды компонента",
	regionalEngagementLabel: "Задание региональной команды",
	linkedLabel: "Ссылки",
	eyCanvasEconomicsTooltip: "Экономика ЭЯ Канвас",
	eyOversightTooltip: 'EY Canvas Oversight',
	eyPrivacyNoticeTooltip: 'Privacy notice',
	eyTermsOfServiceTooltip: 'Terms of service',
	eyGeneralHelp: 'General Help',

	preferrednegativenumberformat: "Предпочтительный формат отрицательного числа",

	protectedEngagementMessage: "Печать, копирование или воспроизведение документов иным образом недопустимы",
	documentRrestriction: "Ограничение в отношении документов: ",
	on: 'On ',
	off: "Выкл ",
	protectedEngagementMessageOn: "- В рамках данного задания документы не могут быть распечатаны, скопированы или воспроизведены иным образом",
	protectedEngagementMessageOff: "- В рамках данного задания документы могут быть распечатаны, скопированы или воспроизведены иным образом",

	/* Dashboard labels*/
	homeLabel: "Главная страница",
	manageAccessHeader: "Управление доступом",
	showInactiveUserslabel: "Показать неактивных пользователей",
	addUserButtonLabel: "Добавить пользователя",
	searchUserText: "Поиск",
	nameHeader: "Имя и фамилия",
	emailHeader: "Адрес электронной почты",
	actionHeader: "Действие",
	manageRolesLabel: "Управление ролями",
	deactivateUserlabel: "Деактивировать пользователя",
	manageRolesTableModule: "Модуль",
	manageRolesTableRoles: "Роли",
	manageRolesTableServiceLine: "Сервисная линия",
	manageRolesTableCountries: "Страны",
	manageAccessAssignRole: "Распределить роль",
	manageAccessNoRolesAdded: "Роли не добавлены",
	manageAccessDeleteRoleModalHeader: 'Delete role',
	manageAccessDeleteRoleButton: 'Delete',
	noRoleResults: "Результаты отсутствуют",
	confirmAssignRoleButton: "Распределить",
	permissionGroupName: "Наименование группы",
	permissionGroupDescription: "Описание группы",
	activeMembers: "Активные члены команды",
	permissionGroupMembersTitle: "Должность",
	permissionGroupMembersPhone: "Телефон",
	permissionGroupMembersGui: "ГПИ",
	permissionGroupMembersUserId: "Идентификатор пользователя",
	permissionGroupMembersAdStatus: "Статус в Активной директории",
	permissionGroupAddMember: "Добавить участника",
	permissionGroupUserDeleteLabel: 'Delete',
	permissionGroupUserDeleteHeader: 'Delete member',
	permissionGroupDetails: "Информация о группах, имеющих разрешения",
	cache: 'Cache',
	engagementID: 'Engagement ID',
	cacheKey: 'Cache key',
	validationMessage: 'Required field',
	getCache: 'Get cache',
	clearResult: 'Clear results',
	removeCache: 'Remove cache',
	requiredField: 'Required Field',
	ticketNumber: 'Ticket number (Required)',
	confirmationMessage: 'Please enter a ticket number for this action',
	confirmButtonLabel: 'Confirm',
	cancelButtonLabel: 'Cancel',
	modalHeaderLabel: 'Remove cache',
	adminModuleinvalidEngagementId: 'Invalid engagement ID',
	clearResults: 'Clear results',
	getDocuments: 'Get documents',
	documentsHeader: 'Documents',
	validTicketNumber: 'Please enter a valid ticketNumber',

	egagementsPageDescription: "Поиск заданий по одному или нескольким критериям для обновления данных о задании, приглашения новых членов команды, обновления статуса и разрешений существующего члена команды.",
	engagementSearchCriteria: "Country or Engagement ID are required to perform the search",
	engagementsHeader: "Задание",
	engagementIdSearchPlaceholder: "Engagement ID",
	engagementNameSearchPlaceholder: "Engagement name",
	workSpaceSearchPlaceholder: "Наименование или идентификатор рабочего пространства",
	clientSearchPlaceholder: "Наименование клиента или его код",
	engagementCodeSearchPlaceholder: "Код задания или наименования кода",
	userSearchPlaceholder: "ГУИ пользователя, адрес электронной почты или идентификатор пользователя",
	countrySearchPlaceholder: "Country",
	engagementTableHeader: "Задание",
	nameTableHeader: "Имя и фамилия",
	workspaceIdTableHeader: "Рабочее пространство",
	clientTableHeader: "Клиент",
	engagementCodeTableHeader: "Код задания",
	yearEndTableHeader: "Окончание года",
	engagementdeleteDate: "Дата удаления задания",
	archivalguid: "Архивный ГУИД",
	archivalLogId: "Идентификатор архивного журнала",
	archivalFileguid: "ГУИД архивного файла",
	profile: "Профиль заполнен",
	independence: "Independence",
	archiveRetry: "Archive retry",
	engagementdetails: "Информация о задании",
	engagementsdetails: "Информация о задании",
	statusTableHeader: "Cтатус",
	attributesTableHeader: "Атрибуты",
	attributesProfileComplete: "Профиль заполнен",
	attributesIndpendenceApplicable: "Применимость требований к независимости",
	engagementPagePlaceholder: "Выполнить поиск по указанным выше заданиям. Здесь отобразятся результаты",
	noEngagementFound: "Задание не найдено",
	emptySearchFieldError: "Как минимум одно поисковое поле должно иметь значение.",
	largeCountrySelectionError: "Выберите 10 или менее стран.",
	saveEngagement: "Сохранить",
	undoEditEngagement: "Сброс",
	engagementUpdateSuccess: "Изменения были успешно сохранены",
	archiveRetrySuccessMessage: "Archive has been successfully initiated for this engagement. Please check status later to validate if the Archiving was successful.",
	welcome: "Welcome to ",
	welcomeadmin: "Admin Tool",
	engagementCard: "Задания",
	messagingCard: "Обмен сообщениями",
	templatesCard: "Шаблоны",
	permissionGroupCard: "Группы, имеющие разрешения",
	manageAccessCard: "Управление доступом",
	databaseLocatorCard: "Sync database locator",
	importForReviewTooltip: "Задание будет оптимизировано для проверки, некоторые свойства и функции будут недоступны.",
	importForReviewChecboxText: "Импортировать исключительно для целей проверки",
	clientCode: "Код клиента",
	primaryEngagementCode: "Код задания основной команды",
	invalidEngagementTitle: "Неверный код клиента или задания",
	invalidEngagementMessage: "Код клиента и/или задания необходимо подтвердить до входа в задание. Нажмите «Продолжить» для обновления и подтверждения кодов.",
	insufficientPermission: "Insufficient permissions for this module",
	noPermission: "Нет разрешения",
	noAdminRights: "У вас нет прав доступа к данному инструменту.",
	//Engagement Users Lables
	engmtUserTableUserNameHeader: "Имя пользователя",
	engmtUserTableEmailAddressHeader: "Адрес эл. почты",
	engmtUserTableRoleHeader: "Role",
	engmtUserTableGUIeHeader: "ГУИ",
	engmtUserTableStatuseHeader: "Status",
	engmtUserTableIndependenceStatuseHeader: "Independence",
	engmtUserTableConfidentialityHeader: "Конфиденциальность",
	engmtUserTableADStatusHeader: "Вердикт AD",
	engmtUserTablePermissionsHeader: "Разрешения",
	engmtUserTableActionHeader: "Действие",
	engagementUsersTitle: "Пользователи задания",
	engagementInviteMember: "Invite member",
	engagementInviteUser: "Invite user",
	engagementRoles: "Role",
	engagementRolesRequired: "Engagement role required",
	engagementInviteMemberSuccessMessage: "Member has been invited to the engagement successfully",
	engagementInviteMemberDescription: "Search for users by first name, last name, or email address above. Results will display here.",
	engagementUsersTitleEdit: "Редактировать",
	engagementUpdatePermissionToolTip: "У вас недостаточно прав для обновления задания.",
	engagementRetryArchivePermissionToolTip: "You have insufficient permissions to retry archive",
	usersEditPermissionToolTip: "У вас нет разрешений для выполнения данного действия. Свяжитесь с администратором и запросите разрешение.",
	usersPermissionToolTip: "У вас недостаточно прав для обновления задания пользователя задания.",
	invalidArchivalGuidMessage: "Invalid archival GUID",
	invalidArchivalFileGuidMessage: "Invalid archival file GUID",
	invalidArchivalLogId: "Invalid archival log id",
	datePickerLabel: "Select a date",
	updateEngagement: "Update Engagement",

	invalidEngagementMessage: "Код клиента и/или задания необходимо подтвердить до входа в задание. Нажмите «Продолжить» для обновления и подтверждения кодов.",
	invalidEngagementId: "Недействительный идентификатор задания",
	serverErrorMessage: "Ошибка сервера, просьба вернуться позже",
	engagementDetailsNoPermission: "У вас недостаточно прав для просмотра информации о пользователе задания.",
	engagementNotFound: "Подробные данные по заданию не найдены",
	pageNotFound: "Страница не найдена",
	assignRoleModalMessage: "Выберите модуль и конкретную функцию для активации пользователю",
	selectModule: "Выбрать модуль",
	selectRole: "Выбрать роль",
	serviceLine: "Сервисная линия",
	countries: "Страны",
	confirmAssignRoleButton: "Распределить",
	addUser: "Добавить пользователя",
	addAdminUserHeader: "Введите имя, фамилию или адрес электронной почты пользователя и нажмите Ввод для поиска",
	addMemberDescription: "Search for users by first name, last name, or email address above. Results will display here.",
	seachInputRequired: "Поиск необходимых входящих данных",
	searchUsers: "Поиск пользователей",
	noResultsFound: "Результаты не обнаружены",
	usersToBeAdded: "Добавить следующих пользователей",
	addUserSuccessMessage: "Пользователь с правами администратора успешно добавлен.",
	addMemberSuccessMessage: 'Member has been added to the group successfully',
	deactivateWarning: "Деактивировать указанного пользователя?",
	deactivateButton: "Деактивировать",
	deactivateModalTitle: "Деактивировать пользователя",
	generateReport: 'Generate report',
	generateValidationReport: 'Generate Validation Report',

	templateManagement: "Шаблоны",
	templateName: "Наименование шаблона",
	templateAreaRegion: "Межрегиональная область/регион",
	templateSize: "Размер",
	templateCreatedBy: "Исполнитель",
	templateCreatedDate: "Дата создания",
	templateCountOfEngagement: "Задания",
	templateStatus: "Cтатус",
	templatePublished: "Дата публикации",
	templateUnpublished: "Не опубликовано",
	templateSearchText: "Ввести поисковый запрос и нажать «Ввод»",
	templateInsufficientPermissions: "Недостаточно прав для данного модуля",
	templateMultipleArea: "Несколько",
	templateEdit: "Редактировать шаблон",
	templateEditSave: "Сохранить",
	templateEditCancel: "Отменить",
	templateEditSuccessMessage: "Шаблон успешно обновлен.",
	templateDeleteConfirmationMessage: 'Are you sure that you want to delete the Template?',
	templateDelete: 'Delete template',
	templateEditDelete: 'Delete',

	messagingMessageTitle: 'Message title',
	messagingDescriptionTitle: 'Message description',
	messagingStartsOn: 'Starts on (UTC)',
	messagingEndsOn: 'Ends on (UTC)',
	messagingAffectedDataCenters: 'Affected data centers',
	messagingStatus: 'Status',
	messagingCreatedBy: 'Created by',
	messagingCreatedDate: 'Created date',
	messagingDataCenterSystemWide: 'System wide',
	messagingDataCenterMultiple: 'Multiple',
	messagingDataCenterSingle: 'Single',
	messageName: 'Message title',
	messageStartsOn: 'Starts on',
	messageEndsOn: 'Ends on',
	messageDateTime: 'Select date and time',
	messageAllTimeInUTC: 'All times are in UTC',
	messageTargetAudience: 'Target audience',
	messageDataCenters: 'Data centers',
	messageSelectADate: 'Select a Date',
	messageAllDataCenters: 'All data centers',
	messageKendoDescription: 'Use of formatting will reduce the maximum description length of 4,000 characters',
	messageSelectDataCenter: 'Select a data center',
	messageFieldRequired: 'Required field',
	messageStartTimeError: 'Start date and time cannot be less than current time',
	messageEndTimeError1: 'End date and time should not be before current date and time',
	messageEndTimeError2: 'End date and time should be greater than start date and time',
	messageDataCenterError: 'Select at least one data center',
	messageSubmitValidationError: 'Missing required fields. Please scroll and ensure all required fields are populated.',
	messageChangesValidationError: 'No changes have been made, change at least one field in order to Save.',
	currentutc: 'Current UTC date and time :',
	messageCurrentUTCTime: ' {0} (in 24 hours format)',
	createMessage: 'Create message',
	createMessagePermissions: 'Insufficient permissions for this module',
	createMessageSuccess: 'Message created successfully',
	editMessage: 'Edit message',
	editMessageSuccess: 'Message edited successfully',
	editHoverText: "Edit",

	messagingModalDeleteTitle: 'Delete message',
	messagingModalDeleteMessage: 'Are you sure you want to delete the message?',
	messagingModalDeleteButton: 'Delete',
	messagingModalDeleteConfirm: 'Confirm',
	messagingModalDeleteCancel: 'Cancel',
	messagingModalDeletedSuccessfully: 'Message deleted successfully',

	// databaselocator
	databaseLocatorSuccessMessage: 'Database locator sync has been queued',

	// documents module
	docListHeaderDocId: 'Document ID',
	docListHeaderDocVersion: 'Version',
	docListHeaderDocName: 'Document name',
	docListHeaderFileSize: 'File size',
	docListHeaderContentUri: 'Content URI',
	docListHeaderFileExists: 'File exists',
	docListNoResults: 'No documents found',
	docListTotalDocuments: 'Total documents:',
	docListDocIdSearchLabel: 'Document ID',
	docListExportBtn: 'Export',

	// Internal user roles abbr
	picRole: "PIC",
	adminRole: "Администратор",
	readOnlyRoleForThirdPartyUser: "Только для чтения",
	legacy: "Не ограниченные в использовании",
	permissionLabel: "Разрешение",
	permission: "Разрешения",
	active: "Активный",
	inactive: "Неактивный",
	EngagementUserStatus: "Status",
	IndependenceStatus: "Independence",
	Confidentiality: "Конфиденциальность",
	EditUserPermissionsMainTitle: "Вы можете редактировать свои разрешения",
	EditUserPermissionsInstructions: "Всегда будут редактироваться разрешения других людей, которые к вам не относятся.",
	PermissionSaveButtonText: "Сохранить",
	PermissionCancelButtonText: "Отменить",
	UnknownCountry: "Неизвестно",
	engagementNoPermissionMesage: "У вас недостаточно прав для использования модуля задания.",
	cacheRemoveSuccessMessage: "Cache has been successfully removed",
	cacheKeyNotFound: "No result found for the cache key",

	// Portfolio labels
	informationText: "Welcome back,",
	filterEngagements: {
		all: 'All engagements',
		favorite: 'Favorite engagements',
		review: 'Review engagements'
	},
	filterEngagementsLabel: 'Filter engagements',
	engv2noActiveEngagements: 'You do not have any active engagements. Click on the "Add engagement" button to get started.',
	engv2noFavoriteEngagements: 'You do not have any favorite engagements. To add engagements to this view, navigate to All engagements and click the favorites icon on the bottom right corner of the engagement cards.',
	engv2noReviewEngagements: 'You do not have any review engagements. Contact an engagement team member if you require access.',

	// Engagement Progress Labels
	engagementProgressStrategyPhaseLabel: 'Scope & strategy phase',
	engagementProgressExecutionPhaseLabel: 'Execution phase',
	engagementProgressConclusionPhaseLabel: 'Conclusion phase',
	engagementProgressCompletionPercentage: 'complete',
	engagementProgressCompleted: 'Complete',
	engagementProgressNotAvailable: 'Engagement progress will be available when your local datacenter is upgraded',

	// Engagement archive countdown indicator
	EngagementArchiveCountdownIndicator: {
		reportreleasedate: 'Report release date is <b>{0}</b>',
		archivedeadlinedate: 'Archive deadline date is <b>{0}</b>',
		today: 'today',
		tommorow: 'tomorrow',
		overdue: 'overdue',
		reportreleasedays: '<b>{0} days</b> until Report release date',
		archivedeadlinedays: '<b>{0} days</b> until Archive deadline date'
	},
	postArchive: 'Post archive',

	adminTool: 'Admin Tool',
	characters: 'characters',
	placeholder: 'Placeholder',
	ofLabel: 'of',
	kendoTextLimitMessage: 'Maximum characters limit exceeded',
	kendoRequiredMessage: 'Required',
	engv2Errors: {
		noReviewEngagementsPermission: 'You do not have permission to review engagements. Contact an engagement team member if you require access.'
	},
	timeTracking: 'Time Tracker',
	timeTrackingToolMessage: 'This optional tool was specifically created to help you better manage your time as you work on your engagements.',
	timeTrackingToolMessage1: 'Once you start logging time, you can find a summary of collected data for up to 14 days. Data older than 14 days will automatically be removed.',
	timeTrackingToolUpdatedMessage: 'This optional tool helps record how you spend your time. Time logged is retained for a maximum of two weeks and will not be archived.',
	timeTrackerErrorFallBackMessage: 'Time summary cannot be loaded. Refresh the page and try again. If the issue persists, contact the Help Desk.',
	serviceGateway: 'Service Gateway'
};

export const errors = {
	// GenericErrors Codes
	'0005': "Операция в режиме ожидания",
	'0006': "Операция не выполнена",
	'0008': "Название не может содержать следующие символы: \\",
	'0010': "Не удалось получить ссылку на архив",

	0: "Не удалось выполнить запрос",
	928: "Несанкционированный доступ - У вас нет доступа к выбранному варианту. Обратитесь в службу технической поддержки для получения доступа при авторизации.",

	1009: "Сервер данных не найден",
	1010: "Центр обработки данных не найден",
	1011: "Конфигурация центра обработки данных для данной страны не проводилась ",
	1013: "ID страны или ID аудиторского задания не найдены",
	1014: "Центр обработки данных указанной страны не найден",
	1016: "Не удалось привязать адрес базы данных",
	1017: "Параметр oData не может быть равен нулю или содержать пустые значения",
	1018: "Параметр oData не содержит полей",
	1019: "Универсальный центр обработки данных не найден",
	1020: "Пользователь не зарегистрирован в системе",
	1021: "Функция подготовки отчетности не доступна в выбранном территориальном подразделении. Дополнительную информацию можно получить у местного специалиста ЭЯ Канвас.",

	// Scope & Strategy 1021 - 1200
	1026: "Строка не найдена",
	1030: "Наименование клиента не найдено",

	// Restore Engagement
	1101: "Невозможно восстановить задание для выбранной страны. Обновите страницу и повторите попытку.",

	// Execution   1201-1400
	1219: "Метод не найден",
	1234: "ID должности не могут быть равны нулю или содержать пустые значения",
	1260: "Объект, над которым вы работаете, был удален другим пользователем.",
	1300: "Неверный объект запроса",
	1301: "Неверная должность в аудиторском задании",
	1302: "Неправильно указан язык пользователя",
	1303: "Данные пользователя аудиторского задания не найдены",
	1304: "Неверное значение территориального подразделения",
	1305: "Наименование аудиторского задания не найдено",
	1306: "Наименование аудиторского задания превышает допустимую длину (255 символов)",
	1307: "Дата окончания проверяемого периода не найдена",
	1308: "Неверная дата",
	1309: "Дата выпуска аудиторского заключения не найдена",
	1310: "Установленный срок архивирования аудиторского задания не найден",
	1311: "Установленный срок архивирования задания не может предшествовать сегодняшней дате",
	1312: "Дублирование кодов аудиторского задания",
	1313: "Территориальное подразделение не найдено",
	1314: "Наименование рабочего пространства не найдено",
	1315: "Наименование рабочего пространства превышает допустимую длину (255 символов)",
	1316: "Код клиента превышает допустимую длину (50 символов)",
	1317: "Наименование клиента превышает допустимую длину (255 символов)",
	1318: "Неверное значение индикатора, обозначающего, что рабочее пространство предназначено для обучения",
	1319: "Индикатор, обозначающий, что рабочее Пространство предназначено для обучения, не найден",
	1320: "Индикатор, обозначающий, что клиент подтвержден, не найден",
	1321: "Неверное значение индикатора, обозначающего, что клиент подтвержден",
	1322: "Неверные ID Клиента",
	1323: "Коды аудиторского задания не найдены",
	1324: "ID аудиторского задания не найдены",
	1325: "Неверные ID главного офиса",
	1052: "Неверный идентификатор сервисной линии",
	1053: "Неверный идентификатор направления оказания услуг",
	1326: "ID страны не найдены",
	1327: "Неверные ID рабочего пространства",
	1328: "Неверный статус члена команды по аудиторскому заданию",
	1329: "Неверный статус аудиторского задания",
	1330: "Аудиторское задание было удалено другим членом команды",
	1331: "Дата окончания года не найдена",
	1339: "Превышено допустимое количество аудиторских заданий",
	1340: "Подробные данные по территориальному подразделению не найдены",
	1341: "Ошибка в получении данных по обновлению аудиторского задания",
	1342: "Подробные данные по текущему аудиторскому заданию не найдены",
	1343: "Сводная информация по текущему аудиторскому заданию не найдена",
	1344: "Аудиторское задание не соответствует выбранным ID",
	1359: "ID центра данных для одного из активных аудиторских заданий являются недействительными",
	1363: "Не удалось создать аудиторское задание в Универсал",
	1364: "Не удалось создать аудиторское задание в локальном центре хранения и обработки данных",
	1373: "Выбрать действующее Рабочее Пространство или создать новое Рабочее Пространство",
	1374: "Наименование Аудиторского Задания уже существует. Введите наименование нового Аудиторского Задания, чтобы импортировать шаблон.",
	1379: "Содержимое обновляется. Повторите попытку позже. Если проблема сохраняется в течение длительного времени, обратитесь в службу технической поддержки.",
	1380: "Не удалось выполнить повторную попытку архивирования. Обновите страницу и повторите попытку. Если проблему не удается устранить, обратитесь в службу технической поддержки.",
	1381: "Создание / Перенос / Восстановление здания не выполнено из-за несоответствия идентификатора страны в рабочем пространстве и задании.",
	1382: "Создание / Перенос / Восстановление здания не выполнено из-за несоответствия основного офиса в рабочем пространстве и задании.",
	1384: 'Restore or Roll Forward is already in progress for this engagement. Please try again later.',
	1386: 'Workspace not eligible for new engagements. Refresh the page and try again. If the issue persists contact the Help Desk.',
	1385: 'Engagement cannot be created in the selected location. Refresh the page and try again. If the issue persists, contact the Help Desk.',
	// Invite Team Members (1401 - 1500)
	1403: "Сообщение приветствия по аудиторскому заданию превышает допустимую длину (200 символов)",
	1406: "База Аудиторских Заданий не найдена",
	1424: "Аудиторское задание или данные пользователя не найдены",
	1425: "Подробные данные по аудиторскому заданию не найдены",

	// User details Error Codes (1426 - 1434)
	1426: "Укажите имя",
	1427: "Укажите фамилию",
	1428: "Укажите инициалы",
	1429: "Укажите главный офис",
	1430: "Укажите предпочтительный номер телефона",
	1431: "Укажите язык",
	1432: "Укажите предпочтительный формат даты",
	1433: "Укажите предпочтительный числовой формат",
	1434: "Данные пользователя не могут быть равны нулю или содержать пустые значения",
	1436: "Обнаружено расхождение. Если проблему не удается устранить, обратитесь в службу технической поддержки.",
	1438: "Пользовательские базы данных должны быть заполнены.",
	1448: "Выбранная страна не прошла обновление до последней версии ЭЯ Канвас. Обратитесь к ЭЯ Атлас, чтобы узнать сроки реализации проекта, и повторите попытку после пройденного обновления. Если проблему не удается устранить, обратитесь в службу технической поддержки.",

	1502: "В настоящее время невозможно выполнить операцию. Обновите страницу и повторите попытку. Если проблему не удается устранить, обратитесь в службу технической поддержки.",
	1504: "В настоящее время невозможно выполнить операцию. Обновите страницу и повторите попытку. Если проблему не удается устранить, обратитесь в службу технической поддержки.",
	1505: "Неверные исходные данные",

	// Common control errors (1601 - 1700)
	1601: "Неправильно указан процент",
	18000: "Ошибка обработки АРХИВА: Требуемая операция не может быть выполнена, поскольку АРХИВ находится на техническом обслуживании. Повторите попытку позже.",
	18306: "Ошибка обработки АРХИВА: Архивная форма, связанная с Канвас архивным номером GUID, не найдена",
	18307: "Ошибка обработки АРХИВА: Задание было удалено из архива. Выбранная операция не может быть выполнена. Для получения дополнительной поддержки просьба связаться с администратором Архива.",
	18309: "Ошибка обработки АРХИВА: Требуемая операция не может быть выполнена, поскольку в отношении заархивированного задания действуют нормативно-правовые ограничения.Для получения дополнительной поддержки просьба связаться с администратором Архива.",
    18320: "Ошибка обработки АРХИВА: Архивная форма в состоянии блокировки (ожидания) и не может быть изменена.",
	18327: "Ошибка обработки АРХИВА: Архивная форма в состоянии блокировки для удаления и не может быть изменена.",
	18328: "Ошибка обработки АРХИВА: Требуемая операция не может быть выполнена, поскольку задание отмечено как предназначенное для удаления из Архива. Для получения дополнительной поддержки просьба связаться с администратором Архива.",
	18329: "Ошибка обработки АРХИВА: Данное задание не поддерживает функцию доступа Партнеров. Для получения дополнительной поддержки просьба связаться с администратором Архива.",
	18330: "Ошибка обработки АРХИВА: Требуемая операция не может быть выполнена, поскольку доступ к заданию заблокирован в связи с нормативно-правовыми ограничениями.Для получения дополнительной поддержки просьба связаться с администратором Архива.",
    2027: "Неверный запрос",
	2046: "Ошибка авторизации",
	2047: "Запрещено",
	2048: "Запись не найдена",
	2049: "Запрашиваемое значение слишком велико",
	2052: "Неприменимо",
	2053: "Во время получения комментариев произошла ошибка. Обновите страницу и повторите попытку. Если проблему не удается устранить, обратитесь в службу технической поддержки.",
	2056: "Общая ошибка",
	2057: "Сервис недоступен",

	// Roll Forward Error Codes (2301-2400)
	2301: "При создании аудиторского задания из существующего, архивный файл отсутствует",
	2302: "При создании аудиторского задания из существующего, не удалось получить ссылку на архив",
	2303: "При создании аудиторского задания из существующего, уникальный идентификатор архивного файла пуст",
	2304: "При создании аудиторского задания из существующего, файловая система URL для архивного файла пуста",
	2305: "При создании аудиторского задания из существующего, хеш-значение для архивного файла отсутствует",
	2306: "Пользователь не вправе выполнять данную операцию",
	2307: "Разрешенное число восстановленных копий превышено",

	// Link Error Codes
	2568: "Ссылка более не доступна, поскольку вы не являетесь действующим Контактным Лицом. Обновите страницу.",
	3005: "Основная команда удалила Компонент из Аудиторского Задания Группы",
	2571: "Запрос на привязку был изменен. Обновите страницу.",

	// Error for v1 engagement not supported features
	4001: "Выбранное Аудиторское Задание было создано в старой версии системы ЭЯ Канвас. Создание из существующего задания возможно только после установки последней версии ЭЯ Канвас в локальном центре хранения и обработки данных. Дополнительную информацию можно получить у специалистов локальной службы технической поддержки.",
	4002: "Выбранное Аудиторское Задание было создано в старой версии ЭЯ Канвас. Получение доступа возможно только после установки последней версии в локальном центре хранения и обработки данных. Дополнительную информацию можно получить у специалистов локальной службы технической поддержки.",
	4214: "Форма не может быть импортирована в рабочее пространство для выбранного территориального подразделения.Выберите другое территориальное подразделение и повторите попытку",

	// Common framework error
	4205: "Не Найдены Данные Запроса На Копирование",
	401000: "В системе обнаружены изменения. Обновите страницу для продолжения работы",

	// CopyHub concurrency scenarios
	4217: "Статус пакета данных изменен. Обновите страницу и повторите попытку.",
	4219: "Копии из различных версий ЭЯ Канвас не поддерживаются данным выпуском в связи с улучшениями, внесенными в процесс копирования. Информация о графике внедрения представлена в ЭЯ Атлас. Копию можно сделать после обновления вашего центра данных.",
	4220: "Версия шаблона не поддерживается для данной страны.",
	4221: "Версия шаблона не поддерживается для данной страны.",
	4222: 'Import cannot be completed until the destination location has been upgraded to the latest version of EY Canvas.  Select another location to continue.  Contact the Help Desk if the issue persists.',

	// Error for Mobility API
	4501: "В Аудиторском Задании отсутствуют задачи",
	4502: "В Аудиторском Задании отсутствуют активные Задачи",

	// Counrty API version error
	5001: "В выбранном территориальном подразделении используется устаревшая версия ЭЯ Канвас. Задание может быть перенесено на новое рабочее пространство только после установки последней версии ЭЯ Канвас в локальном центре хранения и обработки данных выбранного территориального подразделения. Дополнительную информацию можно получить у специалистов локальной службы технической поддержки.",

	//CRP checks
	5101: "Создание копии для проверки для данного задания невозможно , поскольку внешний портал доступа к Канвас в данное время не подключен. Обратитесь в службу технической поддержки для получения более подробной информации.",
	5102: "Для данного задания ведется копирование экземпляра для проверки в Канвас. Повторите попытку позже.",
	5103: "Редактирование копии для проверки для данного задания невозможно , поскольку внешний портал доступа к Канвас в данное время не подключен. Обратитесь в службу технической поддержки для получения более подробной информации.",
	5104: "Ограничение в отношении документов не распространяется на данное задание. Отмените выбор и повторите попытку.",

	18100: "Сервисная линия не найдена.",
	18101: "Направление оказания услуг не найдено.",

	//RBAC realted error messages.
	935: "У вас недостаточно прав для выполнения данной операции. Свяжитесь с администратором задания, и запросите права на выполнение данной операции.",
	8001: "В настоящий момент невозможно выполнить операцию. Обновите страницу и повторите попытку. Если ошибка повториться, свяжитесь со службой технической поддержки.",
	336699: 'Time summary cannot be loaded. Refresh the page and try again. If the issue persists, contact the Help Desk.',

	//Concurrency errors
	7821: 'You have already responded to this invite, refresh the page and try again.'
};

export const adminErrors = {
	1332: 'Error while triggering archive retry',
	1501: "Пользователь является неактивным. Обновите страницу и повторите попытку",
	156: 'Insufficient permissions to this module',
	70740: 'Changes cannot be saved. Engagement must have at least one active member who has permissions to administer engagement and has resolved independence. If the issue persists, contact the Help Desk.',
	70790: 'Domain is not allowed. Try again and if the issue persists, contact the Help Desk.',
	70800: 'Invalid user to invite third party User.',
	70810: 'Invalid/Inactive RBAC roles selected for third party user.',
	70820: 'Select valid RBAC role for third party user.',
	70830: 'Select read only role for third party user.'
};

export const actionTypes = {
	1: "Сообщения системы",
	2: "Приглашения для участия в задании",
	3: "Доступ запрещен",
	4: "Запросы на удаление аудиторского задания",
	5: "Ошибка при создании аудиторского задания",
	6: "Запрос доступа к аудиторскому заданию",
	7: "Удаленные аудиторские задания",
	8: "Заархивированные аудиторские задания",
	9: "Деактивированные аудиторские задания",
	10: "Изменение крайнего срока архивации",
	11: "Запросы на привязку к Группе",
	12: "Полученные инструкции Группы",
	13: "Полученные копии",
	14: "Выполнение задания"
};
export const timephaseType = {
	'-1': "Дата окончания проверяемого периода",
	'-2': "Дата выпуска аудиторского заключения",
	'-3': "Установленный срок архивации",
	'1': "Объем аудита",
	'2': "Стратегия",
	'3': "Сквозные проверки",
	'4': "Промежуточное тестирование средств контроля",
	'5': "Промежуточное тестирование по существу",
	'6': "Сквозные проверки (на конец года)",
	'7': "Тестирование средств контроля на конец года",
	'8': "Тестирование по существу на конец года",
	'9': "Вывод",
	'10': "Н/п",
	'11': "Исполнение",

	'12': "Проверка качества объема работ и стратегии",

	'13': "Прочие объем и стратегия",
	'14': "ООР",
	'15': "Оценка риска и существенность",
	'16': "Окончательное формирование объема и стратегии",
	'17': "Управление проектом после промежуточной даты"
};
export const dataExport = [
	"Форма Архива",
	"Формы Канвас",
	"Сотрудничество",
	"Формы Контроля",
	"Документы",
	"Задачи",
	"Члены Команды",
	"Оборотно-сальдовая ведомость"
];
export const dataExportReportNames = {
	'Форма Архива': 'Archive Form',
	'Формы Канвас': 'Canvas Form Report',
	'Сотрудничество': 'Collaboration',
	'Формы Контроля': 'Control Report',
	'Документы': 'All Documents Report',
	'Задачи': 'All Tasks Report',
	'Члены Команды': 'Team Members',
	'Оборотно-сальдовая ведомость': 'Numbers Report'
};
export const projectManagement = [
	"Информация о совместной работе",
	"Цифровая скоринговая модель",
	"Основные этапы",
	"Замечания",
	"Проверки в рамках процесса валидации"
];
export const projectManagementReportNames = {
	'Информация о совместной работе': 'Client Collaboration Dashboard',
	'Основные этапы': 'Milestone Report',
	'Замечания': 'Review Notes Report',
	'Проверки в рамках процесса валидации': 'Validation Checks Report'
};
export const summaryScreenDefaults = [
	"Отчет о планировании аудита",
	"План аудита со связанными доказательствами",
	"Инструмент проверки средств контроля (ИПСК)",
	"Сводные данные по ООР",
	"Результаты",
	"Описание ИТ-приложения и СО",
	"Сводные данные по ЗКО",
	"Матрица оценки по существу"
];
export const summaryScreenDefaultsReportNames = {
	'Отчет о планировании аудита': 'Audit Plan Report',
	'План аудита со связанными доказательствами': 'Audit Plan with Related Evidence',
	'Инструмент проверки средств контроля (ИПСК)': 'Controls Review Tool (CRT)',
	'Сводные данные по ООР': 'CRA Summary',
	'Результаты': 'Findings',
	'Описание ИТ-приложения и СО': 'ITSO CRT',
	'Сводные данные по ЗКО': 'SCOT Summary',
	'Матрица оценки по существу': 'SEM'
};
export const summaryScreenInterim = [
	"Отчет по рабочему плану",
	"Рабочий план с соответствующими доказательствами"
];
export const summaryScreenInterimReportNames = {
	'Отчет по рабочему плану': 'Audit Plan Report',
	'Рабочий план с соответствующими доказательствами': 'Audit Plan with Related Evidence'
};
export const summaryScreenGroupAudit = [
	"Отчет о планировании аудита",
	"План аудита со связанными доказательствами",
	"Инструмент проверки средств контроля (ИПСК)",
	"Сводные данные по ООР",
	"Результаты",
	"Сводные данные по инструкциям группы",
	"Отчет по группе",
	"Сводные данные о структуре группы",
	"Описание ИТ-приложения и СО",
	"Сводные данные по ЗКО",
	"Матрица оценки по существу"
];
export const summaryScreenGroupAuditReportNames = {
	'Отчет о планировании аудита': 'Audit Plan Report',
	'План аудита со связанными доказательствами': 'Audit Plan with Related Evidence',
	'Инструмент проверки средств контроля (ИПСК)': 'Controls Review Tool (CRT)',
	'Сводные данные по ООР': 'CRA Summary',
	'Результаты': 'Findings',
	'Сводные данные по инструкциям группы': 'Group Instruction Summary',
	'Отчет по группе': 'Group Report',
	'Сводные данные о структуре группы': 'Group Structure Summary',
	'Описание ИТ-приложения и СО': 'ITSO CRT',
	'Сводные данные по ЗКО': 'SCOT Summary',
	'Матрица оценки по существу': 'SEM'
};
export const summaryScreenGroupAuditInterim = [
	"Сводные данные по инструкциям группы",
	"Сводные данные о структуре группы",
	"Отчет по рабочему плану",
	"Рабочий план с соответствующими доказательствами"
];
export const summaryScreenGroupAuditInterimReportNames = {
	'Сводные данные по инструкциям группы': 'Group Instruction Summary',
	'Сводные данные о структуре группы': 'Group Structure Summary',
	'Отчет по рабочему плану': 'Audit Plan Report',
	'Рабочий план с соответствующими доказательствами': 'Audit Plan with Related Evidence'
};
export const rolelistitems = [{
	id: 8,
	label: "Специалист"
},
{
	id: 7,
	label: "Старший эксперт"
},
{
	id: 6,
	label: "Менеджер"
},
{
	id: 5,
	label: "Старший менеджер"
},
{
	id: 3,
	label: "Исполнительный директор"
},
{
	id: 4,
	label: "Принципал"
},
{
	id: 11,
	label: "Другой партнер"
},
{
	id: 2,
	label: "Партнер по аудиторскому заданию "
},
{
	id: 1,
	label: "Партнер, ответственный за аудиторское задание"
},
{
	id: 10,
	label: "Контролер качества аудиторского задания"
},
{
	id: 17,
	label: "Контролер качества аудита"
},
{
	id: 16,
	label: "Руководитель юридической службы"
},
{
	id: 12,
		label: 'GDS - Staff'
	},
	{
		id: 30,
		label: 'GDS - Senior'
	},
	{
		id: 31,
		label: 'GDS - Manager'
}
];
export const roleType = {
	'11': "Другой партнер",
	'24': "Прочее (напр., независимая комплексная проверка)",
	'15': "Административные услуги",
	'18': "Команда компонента на клиентах с децентрализованным местонахождением",
	'17': "Контролер качества аудита",
	'1': "Партнер, ответственный за аудиторское задание",
	'2': "Партнер по аудтиторскому заданию ",
	'9': "Стажер",
	'13': "Консультационные услуги (ФАИТ, консультационные услуги по сделкам, управление персоналом и прочее)",
	'14': "Услуги в области налогообложения",
	'27': "Направление",
	'10': "Контролер качества аудиторского задания",
	'25': "Офис",
	'8': "Специалист",
	'20': "Специалист клиента",
	'19': "Руководитель отдела клиента",
	'5': "Старший менеджер",
	'16': "Руководитель юридической службы",
	'26': "Область",
	'23': "Регулирующий орган",
	'22': "Специалист службы внутреннего аудита",
	'3': "Исполнительный директор",
	'21': "Руководитель службы внутреннего аудита",
	'6': "Менеджер",
	'4': "Принципал",
	'7': "Старший эксперт",
	'12': "Глобальный центр кадровых ресурсов",
	'28': "Национальный",
	'29': 'Global',
	'12': 'GDS - Staff',
	'30': 'GDS - Senior',
	'31': 'GDS - Manager'
};
export const copyObjectGroupType = {
	1: "Аудиторское задание",
	2: "Счета",
	3: "ЗКО",
	4: "ИТ-приложения и сервисные организации",
	5: "Запросы",
	6: "Доказательства",
	7: "Показатели",
	8: "Запросы и внешние задачи",
	9: "Временные файлы",
	10: "Доказательства за предыдущий период",
	11: "Недавно удаленные файлы",
	12: "Архивные файлы",
	13: "Шаблон аудиторского задания",
	14: "Восстановить копию юридического отдела",
	15: "Копия на портале внешней проверки",
	16: "Внешняя проверка активного задания",
	17: "Формы Канвас",
	20: "Доказательства",
	21: "Задание",
	22: "Счета",
	23: "ЗКО",
	24: "Создание задания из существующего",
	27: "Внешние доказательства",
	28: "EY Canvas FIT Enablement"
};
export const EngagementStatusText = [
	"Не определено",
	"Активная",
	"Отложенная",
	"Заархивированная",
	"Удалено",
	"Архивирование выполняется",
	"Повторная активация",
	"Восстановлено",
	"Отмечено на удаление",
	"Ошибка архивирования",
	"Ошибка при переносе",
	"Ошибка при повторной активации",
	"Ошибка при восстановлении",
	"Перенос выполняется",
	"Повторная активация выполняется",
	"Восстановление выполняется",
	"Перенос данных в систему Гамикс выполняется",
	"Ошибка при переносе данных в систему Гамикс",
	"Создание операции в режиме ожидания",
	"Операция не создана",
	"Обновление операции в режиме ожидания",
	"Обновление операции не выполнено",
	"Копирование выполняется",
	"Ошибка копирования",
	"Ошибка копирования шаблона",
	"Внешняя проверка задания выполняется",
	"Ошибка при выполнении внешней проверки",
	"Задание по внешней обзорной проверке "
];
export const overdueIssues = {
	partnerInvitationPending: "Приглашение партнера в режиме ожидания",
	eqrInvitationPending: "Приглашение лица, осуществляющего проверку качества, в режиме ожидания",
	invalidEngagementCode: "Неверный код аудиторского задания",
	overdueArchiveDeadline: "Предельный срок архивирования просрочен",
	upcomingArchiveDeadline: "Ближайший предельный срок архивирования",
	contentDeliveryInProgress: "Предоставление контента выполняется",
	overdueMilestones: "Командные ключевые точки просрочены",
	overduePartnerTasks: "Задачи партнера с просроченным сроком выполнения",
	overdueEqrTasks: "Задачи лица, осуществляющего проверку, с просроченным сроком выполнения"
};
export const engagementMetricNames = [{
	propName: 'preparer',
	value: "Подготовить"
},
{
	propName: 'forMyReview',
	value: "Проверить"
},
{
	propName: 'upcomingReview',
	value: "Предстоящая проверка"
},
{
	propName: 'reviewNotesAssigned',
	value: "Примечания для меня"
},
{
	propName: 'reviewNotesAuthored',
	value: "Мои примечания"
},
{
	propName: 'clientRequests',
	value: "Запросы"
},
{
	propName: 'timephases',
	value: "Временные этапы"
},
{
	propName: 'groupTasks',
	value: "Задачи по Группе"
},
{
	propName: 'groupDeliverables',
	value: "Результаты по Группе"
},
{
	propName: 'automationDocuments',
	value: "Автоматизация"
}
];
export const metricFilterComingDue = [{
	value: 1,
	label: "Срок выполнения наступает завтра"
},
{
	value: 3,
	label: "Срок выполнения наступает через 3 дня"
},
{
	value: 5,
	label: "Срок выполнения наступает через 5 дней",
	isDefault: true
},
{
	value: 10,
	label: "Срок выполнения наступает через 10 дней"
},
{
	value: 30,
	label: "Срок выполнения наступает через 30 дней",
	isMax: true
}
];
export const metricFilterWhatsNew = [{
	value: 1,
	label: "Новое за вчера"
},
{
	value: 3,
	label: "Новое за последние 3 дня"
},
{
	value: 5,
	label: "Новое за последние 5 дней",
	isDefault: true
},
{
	value: 10,
	label: "Новое за последние 10 дней",
	isMax: true
}
];

export const type1Alerts = [
	"hasPartnerInvited",
	"hasEqrInvited",
	"hasValidEngagementCode",
	"overdueArchiveDeadline"
];

export const engagementStatuses = {
	1: "Активная",
	2: "Отложенная",
	3: "Заархивированная",
	4: "Удалено",
	5: "Архивирование выполняется",
	6: "Повторная активация",
	7: "Восстановлено",
	8: "Отмечено на удаление",
	9: "Ошибка архивирования",
	10: "Ошибка при переносе ",
	11: "Ошибки при повторной активации",
	12: "Ошибка при восстановлении",
	13: "Перенос выполняется",
	14: "Повторная активация выполняется",
	15: "Восстановление выполняется",
	16: "Перенос данных в систему Гамикс выполняется",
	17: "Ошибка при переносе данных в систему Гамикс",
	18: "Создание операции в режиме ожидания",
	19: "Операция не создана",
	20: "Обновление операции в режиме ожидания",
	21: "Обновление операции не выполнено",
	22: "Копирование выполняется",
	23: "Ошибка копирования",
	24: "Ошибка при копировании шаблона",
	25: "Задание по внешней обзорной проверке выполняется",
	26: "Ошибка при выполнении задания по внешней обзорной проверке",
	27: "Задание по внешней обзорной проверке ",
	28: "Локализовано для обособленной универсальной версии",
	29: "Локализовано для архивной обособленной универсальной версии ",
	30: "Предоставление контента выполняется",
	31: "Ошибка при предоставлении контента",
	32: "Операция по предоставлению контента не выполнена",
	33: 'Canvas External Access Portal V2',
	34: 'Canvas External Access Portal V2 in-progress',
	35: 'Canvas External Access Portal V2 Error',
	100: "Разделение центра данных"
};
export const overrideArchiveDeadlineDateOptions = [{
	value: 0,
	label: "По умолчанию"
},
{
	value: 10,
	label: "10 Дней"
},
{
	value: 14,
	label: "14 Дней"
},
{
	value: 20,
	label: "20 Дней"
},
{
	value: 21,
	label: "21 День"
},
{
	value: 25,
	label: "25 Дней"
},
{
	value: 30,
	label: "30 Дней"
},
{
	value: 45,
	label: "45 Дней"
}
];
export const ExternalReviewCopyExpiryDays = [{
	value: 30,
	label: "30 дней"
},
{
	value: 60,
	label: "60 дней"
},
{
	value: 90,
	label: "90 дней"
},
{
	value: 180,
	label: "180 дней"
}
];

export const serviceLines = [{
	servicelineid: 1,
	servicelinename: "Аудиторские услуги"
},
{
	servicelineid: 3,
	servicelinename: "Стратегия реализации сделок"
},
{
	servicelineid: 2,
	servicelinename: "Консультирование"
},
{
	servicelineid: 4,
	servicelinename: "Налогообложение"
},
{
	servicelineid: 5,
	servicelinename: "ЮС"
}
];

export const subServiceLines = [{
	subservicelineid: 1,
	servicelineid: 1,
	subservicelinename: "Аудит"
},
{
	subservicelineid: 2,
	servicelineid: 1,
	subservicelinename: "FIDS"
},
{
	subservicelineid: 3,
	servicelineid: 2,
	subservicelinename: "Управление ИТ и ИТ-рисками"
},
{
	subservicelineid: 9,
	servicelineid: 1,
	subservicelinename: "Консультирование по вопросам ФО и БУ"
},
{
	subservicelineid: 10,
	servicelineid: 1,
	subservicelinename: "Соблюдение требований к ФО"
},
{
	subservicelineid: 11,
	servicelineid: 1,
	subservicelinename: "Услуги в области устойчивого развития"
},
{
	subservicelineid: 4,
	servicelineid: 3,
	subservicelinename: "Трансформация капитала"
},
{
	subservicelineid: 5,
	servicelineid: 3,
	subservicelinename: "Сопровождение сделок"
},
{
	subservicelineid: 6,
	servicelineid: 2,
	subservicelinename: "Повышение эффективности"
},
{
	subservicelineid: 7,
	servicelineid: 2,
	subservicelinename: "Риски"
},
{
	subservicelineid: 7,
	servicelineid: 23,
	subservicelinename: "Бизнес-консультирование"
},
{
	subservicelineid: 24,
	servicelineid: 6,
	subservicelinename: "Прочее"
},
{
	subservicelineid: 8,
	servicelineid: 2,
	subservicelinename: "Прочее"
},
{
	subservicelineid: 12,
	servicelineid: 4,
	subservicelinename: "Корпоративное налогообложение"
},
{
	subservicelineid: 13,
	servicelineid: 4,
	subservicelinename: "Управление персоналом"
},
{
	subservicelineid: 14,
	servicelineid: 4,
	subservicelinename: "Юридические услуги"
},
{
	subservicelineid: 15,
	servicelineid: 4,
	subservicelinename: "Косвенное налогообложение"
},
{
	subservicelineid: 16,
	servicelineid: 4,
	subservicelinename: "Услуги по подготовке ФО"
},
{
	subservicelineid: 17,
	servicelineid: 4,
	subservicelinename: "Налоговые услуги по сделкам"
},
{
	subservicelineid: 18,
	servicelineid: 4,
	subservicelinename: "Международное налогообложение"
},
{
	subservicelineid: 19,
	servicelineid: 5,
	subservicelinename: "ЮС"
},
{
	subservicelineid: 20,
	servicelineid: 2,
	subservicelinename: "Бизнес-консультирование"
},
{
	subservicelineid: 21,
	servicelineid: 2,
	subservicelinename: "Консультирование в области технологий"
},
{
	subservicelineid: 22,
	servicelineid: 3,
	subservicelinename: "Сделки и корпоративные финансы"
},
{
	subservicelineid: 23,
	servicelineid: 3,
	subservicelinename: "ЭЯ Парфенон"
}
];

export const modules = [{
	id: 1,
	name: "Управление доступом"
},
{
	id: 2,
	name: "Задания"
},
{
	id: 3,
	name: "Группы, имеющие разрешения"
},
{
	id: 4,
	name: "Шаблоны"
},
{
	id: 5,
	name: "Обмен сообщениями"
},
{
	id: '6',
		name: 'Cache'
	},
	{
		id: 7,
		name: 'Database Locator'
	},
	{
		id: 8,
		name: 'Documents'
}
];

export const roles = [{
	id: 17,
	ModuleId: 1,
	name: "Только для чтения"
},
{
	id: 18,
	ModuleId: 1,
	name: "Добавить/удалить пользователя и разрешения"
},
{
	id: 19,
	ModuleId: 2,
	name: "Только для чтения"
},
{
	id: 20,
	ModuleId: 2,
	name: "Обновить задание"
},
{
	id: 21,
	ModuleId: 2,
	name: "Пригласить члена команды"
},
{
	id: 22,
	ModuleId: 2,
	name: "Обновить данные пользователя задания"
},
{
	id: 23,
	ModuleId: 3,
	name: "Только для чтения"
},
{
	id: 24,
	ModuleId: 3,
	name: "Добавить/удалить участника"
},
{
	id: 25,
	ModuleId: 3,
	name: "Синхронизировать адреса базы данных"
},
{
	id: 26,
	ModuleId: 4,
	name: "Только для чтения"
},
{
	id: 27,
	ModuleId: 4,
	name: "Обновить шаблон"
},
{
	id: 28,
	ModuleId: 4,
	name: "Удалить шаблон"
},
{
	id: 29,
	ModuleId: 5,
	name: "Только для чтения"
},
{
	id: 30,
	ModuleId: 5,
	name: "Создать/обновить сообщение"
},
{
	id: 31,
	ModuleId: 5,
	name: "Удалить сообщение"
},
{
	id: 32,
	ModuleId: 6,
		name: 'Read and Delete Cache'
	},
	{
		id: 33,
		ModuleId: 8,
		name: 'Access Documents'
	},
	{
		id: 34,
		ModuleId: 2,
		name: "Archive Retry"
	}

];

export const countries = {
	1: "Майотта",
	2: "Британские Виргинские острова",
	3: "Испания",
	4: "Белиз",
	5: "Перу",
	6: "Словакия",
	7: "Венесуэла",
	8: "Норвегия",
	9: "Фолклендские (Мальвинские) острова",
	10: "Мозамбик",
	11: "Материковый Китай",
	12: "Судан",
	13: "Израиль",
	14: "Бельгия",
	15: "Саудовская Аравия",
	16: "Гибралтар",
	17: "Гуам",
	18: "Остров Норфолк",
	19: "Замбия",
	20: "Реюньон",
	21: "Азербайджан",
	22: "Остров Святой Елены",
	23: "Иран",
	24: "Монако",
	25: "Сен-Пьер и Микелон",
	26: "Новая Зеландия",
	27: "Острова Кука",
	28: "Сент-Люсия",
	29: "Зимбабве",
	30: "Ирак",
	31: "Тонга",
	32: "Американское Самоа",
	33: "Мальдивские острова",
	34: "Марокко",
	35: "Международные стандарты аудита (МСА)",
	36: "Албания",
	37: "Афганистан",
	38: "Гамбия",
	39: "Буркина-Фасо",
	40: "Токелау",
	41: "Ливия",
	42: "Канада",
	43: "Объединенные Арабские Эмираты",
	44: "Корейская Народно-Демократическая Республика",
	45: "Монтсеррат",
	46: "Гренландия",
	47: "Руанда",
	48: "Фиджи",
	49: "Джибути",
	50: "Ботсвана",
	51: "Кувейт",
	52: "Мадагаскар",
	53: "Остров Мэн",
	54: "Венгрия",
	55: "Намибия",
	56: "Мальта",
	57: "Джерси",
	58: "Таиланд",
	59: "Сент-Китс и Невис",
	60: "Бутан",
	61: "Панама",
	62: "Сомали",
	63: "Бахрейн",
	64: "Босния и Герцеговина",
	65: "Франция",
	66: "Республика Корея",
	67: "Исландия",
	68: "Португалия",
	69: "Тунис",
	70: "Гана",
	71: "Камерун",
	72: "Греция",
	73: "Французские Южные территории",
	74: "Остров Херд и острова Макдональд",
	75: "Андорра",
	76: "Люксембург",
	77: "Самоа",
	78: "Ангилья",
	79: "Нидерланды",
	80: "Гвинея-Бисау",
	81: "Никарагуа",
	82: "Парагвай",
	83: "Антигуа и Барбуда",
	84: "Международные стандарты финансовой отчетности (МСФО)",
	85: "Нигер",
	86: "Египет",
	87: "Государство-город Ватикан",
	88: "Латвия",
	89: "Кипр",
	90: "Внешние малые острова США",
	91: "Прочие организации России",
	92: "Сент-Винсент и Гренадины",
	93: "Гернси",
	94: "Бурунди",
	95: "Куба",
	96: "Экваториальная Гвинея",
	97: "Британская Территория в Индийском Океане",
	98: "Швеция",
	99: "Уганда",
	100: "Северная Македония",
	101: "Свазиленд",
	102: "Сальвадор",
	103: "Кыргызстан",
	104: "Ирландия",
	105: "Казахстан",
	106: "Гондурас",
	107: "Уругвай",
	108: "Грузия",
	109: "Тринидад и Тобаго",
	110: "Палестинская автономия",
	111: "Мартиника",
	112: "Аландские острова",
	113: "Французская Полинезия",
	114: "Кот-д'Ивуар",
	115: "Черногория",
	116: "Южно-Африканская Республика",
	117: "Южная Георгия и Южные Сандвичевы острова",
	118: "Йемен",
	119: "Гонконг",
	120: "Кения",
	121: "Чад",
	122: "Колумбия",
	123: "Коста-Рика",
	124: "Ангола",
	125: "Литва",
	126: "Сирия",
	127: "Малайзия",
	128: "Сьерра-Леоне",
	129: "Сербия",
	130: "Польша",
	131: "Суринам",
	132: "Гаити",
	133: "Науру",
	134: "Сан-Томе и Принсипи",
	135: "Шпицберген и Ян-Майен",
	136: "Сингапур",
	137: "Молдова",
	138: "Тайвань",
	139: "Сенегал",
	140: "Габон",
	141: "Мексика",
	142: "Сейшельские острова",
	143: "Федеративные Штаты Микронезии",
	144: "Алжир",
	145: "Италия",
	146: "Сан-Марино",
	147: "Либерия",
	148: "Бразилия",
	149: "Хорватия",
	150: "Фарерские острова",
	151: "Палау",
	152: "Финляндия",
	153: "Филиппины",
	154: "Ямайка",
	155: "Французская Гвиана",
	156: "Кабо-Верде",
	157: "Мьянма",
	158: "Лесото",
	159: "Американские Виргинские острова",
	160: "Каймановы острова",
	161: "Ниуэ",
	162: "Того",
	163: "Беларусь",
	164: "Доминика",
	165: "Индонезия",
	166: "Узбекистан",
	167: "Нигерия",
	168: "Уоллис и Футуна",
	169: "Барбадос",
	170: "Шри-Ланка",
	171: "Великобритания",
	172: "Эквадор",
	173: "Гваделупа",
	174: "Лаос",
	175: "Иордания",
	176: "Соломоновы острова",
	177: "Восточный Тимор",
	178: "Ливан",
	179: "Центральноафриканская Республика",
	180: "Индия",
	181: "Остров Рождества",
	182: "Вануату",
	183: "Бруней",
	184: "Бангладеш",
	185: "Антарктида",
	186: "Боливия",
	187: "Турция",
	188: "Багамские острова",
	189: "Коморские острова",
	190: "Западная Сахара",
	191: "Чешская Республика",
	192: "Украина",
	193: "Эстония",
	194: "Болгария",
	195: "Мавритания",
	196: "Демократическая Республика Конго",
	197: "Лихтенштейн",
	198: "Острова Питкэрн",
	199: "Дания",
	200: "Маршалловы острова",
	201: "Япония",
	202: "Австрия",
	203: "Оман",
	204: "Монголия",
	205: "Таджикистан",
	206: "Швейцария",
	207: "Гватемала",
	208: "Эритрея",
	209: "Непал",
	210: "Мали",
	211: "Словения",
	212: "Северные Марианские острова",
	213: "(Не применимо)",
	214: "Аруба",
	215: "Конго",
	216: "Катар",
	217: "Гвинея",
	218: "Соединенные Штаты Америки",
	219: "Эфиопия",
	220: "Прочее",
	221: "Гайана",
	222: "Германия",
	223: "Бермудские острова",
	224: "Острова Теркс и Кайкос",
	225: "Австралия",
	226: "Кирибати",
	227: "Пуэрто-Рико",
	228: "Пакистан",
	229: "Маврикий",
	230: "Малави",
	231: "Туркменистан",
	232: "Камбоджа",
	233: "Чили",
	234: "Новая Каледония",
	235: "Папуа-Новая Гвинея",
	236: "Остров Буве",
	237: "Тувалу",
	238: "Кюрасао",
	239: "Доминиканская Республика",
	240: "Вьетнам",
	241: "Кокосовые острова (Килинг)",
	242: "Гренада",
	243: "Танзания",
	244: "Аргентина",
	245: "Макао",
	246: "Бенин",
	247: "Румыния",
	248: "Армения",
	249: "международная",
	250: "МСФО для компаний малого и среднего бизнеса",
	251: "ОПБУ США",
	252: "Концепция АИСПА по подготовке финансовой отчетности для компаний малого и среднего бизнеса",
	253: "Южный Судан",
	254: "Косово",
	255: "Соединенные Штаты Америки - Государственный сектор",
	256: "ООО «ЭРНСТ ЭНД ЯНГ» и ООО «ЭЯ Внешаудит»",
	257: "Армения-РоссияДЦ",
	258: "Беларусь-РоссияДЦ",
	259: "Узбекистан-РоссияДЦ"
};

export const common = {
	selected: "Выбранные",
	cancel: "Отменить",
	checkbox: {
		selectAllOn: "Выбрать все",
		selectAllOff: "Отменить выбор для всех"
	},
	clear: "Очистить",
	close: "Закрыть",
	pagination: {
		show: "Показать",
		iconHover: {
			first: "Первая страница",
			previous: "Предыдущая страница",
			next: "Следующая страница",
			last: "Последняя страница"
		}
	},
	engv2Pagination: {
		show: "Показать",
		iconHover: {
			first: 'First page',
			previous: 'Previous page',
			next: 'Next page',
			last: 'Last page'
		}
	},
	search: {
		clear: "Сбросить результаты поиска",
		buttonTitle: "Поиск",
		placeholder: "Поиск",
		noResultsPlaceholder: "Результаты не найдены"
	},
	table: {
		remove: "Удалить",
		noDataPlaceholder: "Данные отсутствуют",
		noResultsPlaceholder: "Результаты не найдены"
	},
	toggleSwitch: {
		no: "Нет",
		yes: "Да"
	},
	wizard: {
		next: "Продолжить",
		back: "Назад",
		finish: "Готово"
	}
};

export const ConfidentialityTypes = [{
	id: 0,
	Name: "По умолчанию"
},
{
	id: 10,
	Name: "Низкий"
},
{
	id: 12,
	Name: "Средний"
},
{
	id: 11,
	Name: "Высокий"
},
{
	id: 13,
	Name: "Конфиденциально"
}
];

export const internalUserRoles = [{
	roleKey: 3,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Разрешения",
	roleNameUI: "Архивирование данных по заданию",
	displayName: "Архивирование данных по заданию",
	roleDisplayOrder: 2,
	tooltipText: "Возможность архивировать задание"
},
{
	roleKey: 4,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Разрешения",
	roleNameUI: "Управление заданием",
	displayName: "Управление заданием",
	roleDisplayOrder: 1,
	tooltipText: "Возможность приглашать внутренних и внешних участников и распределять разрешения внутренним участникам"
},
{
	roleKey: 5,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Разрешения",
	roleNameUI: "Обновить содержание",
	displayName: "Обновить содержание",
	roleDisplayOrder: 6,
	tooltipText: "Возможность редактировать профиль и принимать обновление содержания"
},
{
	roleKey: 7,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Разрешения",
	roleNameUI: "Создание запроса на оказание услуг",
	displayName: "Управление запросами на оказание услуг",
	roleDisplayOrder: 4,
	tooltipText: "Возможность создавать запросы на оказание услуг и отмечать, что запросы на оказание услуг выполнены"
},
{
	roleKey: 8,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Разрешения",
	roleNameUI: "Управление группой",
	displayName: "Управление группой",
	roleDisplayOrder: 5,
	tooltipText: "Принять или отклонить инструкции по группе, полученные от основной или региональной команды"
},
{
	roleKey: 9,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Разрешения",
	roleNameUI: "Копирование, создание из предыдущего, восстановление задания",
	displayName: "Копирование, создание из предыдущего, восстановление задания",
	roleDisplayOrder: 3,
	tooltipText: "Возможность копирования, создания из предыдущего задания и его восстановление из архива."
},
{
	roleKey: 0,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "Конфиденциальность",
	roleNameUI: "По умолчанию",
	displayName: "Нет (по умолчанию)",
	roleDisplayOrder: 1,
	tooltipText: "По умолчанию роль с конфиденциальными документами"
},
{
	roleKey: 10,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "Конфиденциальность",
	roleNameUI: "Низкий",
	displayName: "Низкий",
	roleDisplayOrder: 8,
	tooltipText: "Роль с низким уровнем конфиденциальности документов"
},
{
	roleKey: 11,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "Конфиденциальность",
	roleNameUI: "Высокий",
	displayName: "Высокий",
	roleDisplayOrder: 10,
	tooltipText: "Роль с высоким уровнем конфиденциальности документов"
},
{
	roleKey: 12,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "Конфиденциальность",
	roleNameUI: "Средний",
	displayName: "Средний",
	roleDisplayOrder: 9,
	tooltipText: "Роль со средним уровнем конфиденциальности документов"
},
// {
// roleKey: 13,
// sectionId:1,
// groupDisplayOrder: 200,
// groupName:"Confidentiality",
// roleNameUI: "Confidential",
// displayName: "Confidential",
// roleDisplayOrder: 11,
// tooltipText: 'Strictly Confidential Documents Role'
// },
{
	roleKey: 14,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "Ограничения",
	roleNameUI: "Только для чтения",
	displayName: "Только для чтения",
	roleDisplayOrder: 12,
	tooltipText: "Данная функция должна использоваться только для предоставления доступа сторонним проверяющим к данному заданию в системе ЭЯ Канвас. Необходимо получить соответствующие одобрения и авторизацию Группы профессиональной практики."
},
{
	roleKey: 16,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "Ограничения",
	roleNameUI: "Только проверка",
	displayName: "Только проверка",
	roleDisplayOrder: 13,
	tooltipText: "«Только проверка» дает пользователю возможность просматривать информацию в задании Канвас, подписывать и использовать функцию замечаний. Ее необходимо использовать, если другим пользователям из ЭЯ нужно проверить задание (например, Основная команда, выполняющая аудит группы) но без возможности вносить правки.",
},
{
	roleKey: 6,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Разрешения",
	roleNameUI: "Отменить правки",
	displayName: "Вернуть документы в первоначальный вид",
	roleDisplayOrder: 7,
	tooltipText: "Возможность вернуть документы в первоначальный вид в случае непреднамеренных изменений после даты выпуска заключения и удалить их из формы Канвас 440."
},
{
    roleKey: 35,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permissions",
	roleNameUI: "Manage sharing of EY Canvas FIT enablement",
	displayName: "Administer EY Canvas FIT enablement",
	roleDisplayOrder: 14,
	tooltipText: "Ability to share EY Canvas FIT enablement between EY Canvas engagements."
},
{
	roleKey: 36,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permissions",
	roleNameUI: "Import EY Helix data",
	displayName: "Import EY Helix data",
	roleDisplayOrder: 15,
	tooltipText: "Ability to import data from EY Helix."
}
];

export const restoreengagementexpirydates = [{
	value: 3,
	label: '3 months'
},
	{
		value: 6,
		label: '6 months'
	},
	{
		value: 9,
		label: '9 months'
	},
	{
		value: 12,
		label: '12 months'
	}
];

export const messageStatus = {
	0: "Current",
	1: "Scheduled",
	2: "Expired"
};

export const kendoLabels = {
	alignLeft: 'Justify left',
	alignRight: 'Justify right',
	alignCenter: 'Justify center',
	alignFull: 'Justify full',
	addComment: 'Add comment',
	addColumnBefore: 'Add column left',
	addColumnAfter: 'Add column right',
	addRowAbove: 'Add row above',
	addRowBelow: 'Add row below',
	bulletList: 'Insert unordered list',
	bold: 'Bold',
	backColor: 'Highlight',
	createLink: 'Insert Hyperlink',
	createTable: 'Create table',
	cleanFormatting: 'Clean formatting',
	deleteRow: 'Delete row',
	deleteColumn: 'Delete column',
	deleteTable: 'Delete table',
	fontSizeInherit: "Font size",
	foreColor: 'Color',
	format: 'Format',
	fontSize: 'Font size',
	hyperlink: 'Insert web link',
	italic: 'Italic',
	indent: 'Indent',
	insertTableHint: "Create a {0} by {1} table",
	"hyperlink-dialog-content-address": "Web Address",
	"hyperlink-dialog-title": "Insert hyperlink",
	"hyperlink-dialog-content-title": "Title",
	"hyperlink-dialog-content-newwindow": "Open link in new window",
	"hyperlink-dialog-cancel": "Cancel",
	"hyperlink-dialog-insert": "Insert",
	outdent: 'Outdent',
	orderedList: 'Insert ordered list',
	print: 'Print',
	pdf: 'export to pdf',
	redo: 'redo',
	strikethrough: 'Strikethrough',
	subscript: 'Subscript',
	superscript: 'Superscript',
	underline: 'Underline',
	undo: 'Undo'
};

export const kendoFormatOptions = [{
	text: 'Paragraph',
	value: "p"
},
{
	text: 'Heading 1',
	value: "h1"
},
{
	text: 'Heading 2',
	value: "h2"
},
{
	text: 'Heading 3',
	value: "h3"
},
{
	text: 'Heading 4',
	value: "h4"
},
{
	text: 'Heading 5',
	value: "h5"
},
{
	text: 'Heading 6',
	value: "h6"
}
];

export const kendoFontSize = [{
	text: '8',
	value: '8px'
},
{
	text: '9',
	value: '9px'
},
{
	text: '10',
	value: '10px'
},
{
	text: '11',
	value: '11px'
},
{
	text: '12',
	value: '12px'
},
{
	text: '14',
	value: '14px'
},
{
	text: '16',
	value: '16px'
},
{
	text: '18',
	value: '18px'
},
{
	text: '20',
	value: '20px'
},

{
	text: '22',
	value: '22px'
},
{
	text: '24',
	value: '24px'
},
{
	text: '26',
	value: '26px'
},
{
	text: '28',
	value: '28px'
},
{
	text: '36',
	value: '36px'
},
{
	text: '48',
	value: '48px'
},
{
	text: '72',
	value: '72px'
}
];
