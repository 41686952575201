import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import env from '../../../../util/env';
import { MotifButton, MotifInput, MotifFormField, MotifErrorMessage, MotifLabel } from '@ey-xd/motif-react';
import { Ellipse } from '@ey/canvascoreservices';
import EditUserPermissionsWrapper from '../styles/edituserpermissionstyle';
import SelectDropdown from '../../../Common/SelectDropdown/SelectDropdown';
import Permissions from '../../../Common/Permission/Permissions';
import { labels } from '../../../../util/uiconstants';
import Loader from '../../../Common/Loaders/loadingindicator';
import {
	getRbacRoles,
	updateInternalUser
} from '../../../../actions/admin/engagement/engagementActions';
import { UserERPTypeId, rbacRoleKeys } from '../../../../util/uiconstants';
import Toast from '../../../Common/Toast/Toast';
import { getLocalizedAPIError } from '../../common/util';
import { EngagementStatus, engagementUserStatus, IndependenceStatus } from '../../../../util/enumerations';

const formatOptionLabel = ({ value, label }) => (
	<Ellipse content={label} tooltip={label} noOfLines={1} />
);

function EngagementEditUser(props) {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [selectedUserStatus, setSelectedUserStatus] = useState();
	const [
		selectedIndependenceStatus,
		setSelectedIndependenceStatus
	] = useState();
	const [confidentialityType, setConfidentialityType] = useState();
	const [allFieldsDisabled, setAllFieldsDisabled] = useState(false);
	const [refreshPermissionKey, setRefreshPermissionKey] = useState(0);
	const [updatedUserPermissions, setUpdatedUserPermissions] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');
	const [engagementUserStatusOptions, setEngagementUserStatusOptions] = useState([]);
	const [engagementIndependenceOption, setEngagementIndependenceOption] = useState([]);
	const rbacRoleList = useSelector((state) => state.rbacRoles);
	const [ticketNumber, setTicketNumber] = useState('');
	const [isSubmitClicked, setIsSubmitClicked] = useState(false);
	const [confidentialityDropdownDisabled, setConfidentialityDropdownDisabled] = useState(false);

	//Set default selected values for respected drop down lists.
	useEffect(() => {
		if (props?.userStatusesDDOptions) {
			var selectedUserStatusItem = props?.userStatusesDDOptions.filter(
				(ee) =>
					ee.value == props?.editUserPermissionsItem?.engagementUserStatusId
			);
			if (selectedUserStatusItem)
				setSelectedUserStatus(selectedUserStatusItem[0]);

			let userStatus = props?.userStatusesDDOptions?.filter(
				(status) => status.value === engagementUserStatus.Active ||
					status.value === engagementUserStatus.DeActivated
			);
			setEngagementUserStatusOptions(userStatus);
		}

		if (props?.independenceStatusesDDOptions) {
			var selectedIndependenceStatusItem = props?.independenceStatusesDDOptions.filter(
				(ee) => ee.value == props?.editUserPermissionsItem?.independenceStatusId
			);
			if (selectedIndependenceStatusItem)
				setSelectedIndependenceStatus(selectedIndependenceStatusItem[0]);

			let userStatus = props?.independenceStatusesDDOptions?.filter(
				(status) => status.value === IndependenceStatus.Incomplete
			);
			setEngagementIndependenceOption(userStatus);
		}

		var confidentialityType = props?.confidentialityTypes.filter(
			(ee) =>
				ee.label.toLowerCase() ==
				props?.editUserPermissionsItem?.confidentiality.toLowerCase()
		);
		if (confidentialityType) {
			setConfidentialityType(confidentialityType[0]);
		}

		if (props?.editUserPermissionsItem?.userRoles?.findIndex((role) => role.roleKey == rbacRoleKeys.IndependenceOnlyRole) !== -1) {
			setConfidentialityDropdownDisabled(true);
		}
	}, [props]);

	// Use effect to call api to get all rbac roles.
	useEffect(() => {
		if (!(rbacRoleList && rbacRoleList.length > 0)) {
			getRabcRolesFromAPI();
		}
	}, []);

	const getRabcRolesFromAPI = () => {
		setIsLoading(true);
		const serviceUrlV2 = 'serviceUrlV2';
		const securityRoles = 'SecurityRoles';
		let requestsurl = env.getURL(serviceUrlV2) + securityRoles;
		dispatch(getRbacRoles(requestsurl))
			.then(() => {
				setIsLoading(false);
			})
			.catch((e) => {
				setIsLoading(false);
			});
	};

	const onUserStatusChnage = (e) => {
		setSelectedUserStatus(e);
	};

	const onIndependenceStatuseChnage = (e) => {
		setSelectedIndependenceStatus(e);
	};

	const onConfidentialityTypeChange = (c) => {
		setConfidentialityType(c);
	};

	//TODO - API call on save button click
	const onSaveButtonclick = () => {
		if (ticketNumber.length > 0) {
			let engagementUserStatusId = selectedUserStatus?.value;
			let independenceStatusId = selectedIndependenceStatus?.value;

			let confTypeId =
				confidentialityType.value !== undefined ? confidentialityType.value : 0;

			let updatedUserRoles = updatedUserPermissions
				.filter((item) => item.checked)
				.map((r) => r.id);

			if (confTypeId && confTypeId > 0) {
				rbacRoleList.map((r) => {
					if (r.roleKey == confTypeId) {
						updatedUserRoles.push(r.id);
					}
				});
			}

			let userData = {
				userId: props?.editUserPermissionsItem?.userId,
				engagementUserStatusId: engagementUserStatusId,
				independenceStatusId: independenceStatusId,
				updatedUserRoles: updatedUserRoles,
				ticketNumber: ticketNumber
			};

			console.log(userData);

			const url =
				env.getURL('serviceUrlV2') +
				'admin/subservicelines/' +
				props?.ssl +
				'/countries/' +
				props?.countryId +
				'/Engagements/' +
				props?.engagementId +
				'/Users/' +
				props?.editUserPermissionsItem?.userId;

			setIsLoading(true);
			dispatch(updateInternalUser(url, userData))
				.then(() => {
					setIsLoading(false);
					props.setSuccessMessage(labels.engagementUpdateSuccess);
					props.closeModal(true);
				})
				.catch((error) => {
					var localError = getLocalizedAPIError(error);
					setErrorMessage(localError);
					setIsLoading(false);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
		else {
			setIsSubmitClicked(true);
		}
	};

	const isDisabledEngUserStatusDD = () => {
		var userEditItem = props?.editUserPermissionsItem;
		if (!props?.editPermission) {
			return true;
		} else if (props?.engagementStatusId == EngagementStatus.Archived && userEditItem.engagementUserStatusId == engagementUserStatus.Active && userEditItem.isPostArchiveAddition == false) {
			return true;
		}
		return false;
	}

	function handlerOnTicketEntered(e) {
		setTicketNumber(e.target.value)
	}

	const showErrorMsg = () => {
		return (ticketNumber.trim().length === 0 && isSubmitClicked);
	};

	return (
		<EditUserPermissionsWrapper>
			{isLoading == true ? (
				<Loader
					show
					view="blockView"
					styleName="Userloadingstyle"
				/>
			) : (
				<section>
					<section className="addmemberwrapper customScrollbar">
						<section
							className="toastMessage"
							style={{ display: errorMessage.length > 0 ? 'block' : 'none' }}
						>
							<Toast
								variant="error"
								onClose={() => {
									setErrorMessage('');
								}}
							>
								{errorMessage}
							</Toast>
						</section>
						<section className="addmemberholder">
							<section>
								<h5>{labels.EditUserPermissionsMainTitle}</h5>
								<span className="description">
									{labels.EditUserPermissionsInstructions}
								</span>
							</section>
							<section className="userfield-row initialandrole">
								<SelectDropdown
									formLabel={labels.EngagementUserStatus}
									className="userdata userinitial"
									options={engagementUserStatusOptions}
									value={selectedUserStatus}
									onChange={onUserStatusChnage}
									formatOptionLabel={formatOptionLabel}
									isDisabled={isDisabledEngUserStatusDD()}
								></SelectDropdown>

								<SelectDropdown
									formLabel={labels.IndependenceStatus}
									className="select-group userdata engagementrole"
									options={engagementIndependenceOption}
									value={selectedIndependenceStatus}
									onChange={onIndependenceStatuseChnage}
									formatOptionLabel={formatOptionLabel}
									isDisabled={!props?.editPermission}
								></SelectDropdown>
							</section>
							<section className="userfield-row initialandrole">
								<SelectDropdown
									formLabel={labels.Confidentiality}
									className="userdata confidentialityType"
									options={props?.confidentialityTypes}
									value={confidentialityType}
									onChange={onConfidentialityTypeChange}
									formatOptionLabel={formatOptionLabel}
									isDisabled={!props?.editPermission || confidentialityDropdownDisabled}
								></SelectDropdown>
							</section>
							<section className="user-confid-permission">
								<Permissions
									key={refreshPermissionKey}
									rbacRoleList={rbacRoleList}
									disabled={allFieldsDisabled}
									userRoles={props?.editUserPermissionsItem?.userRoles ?? null}
									isThirdPartyUser={
										props?.editUserPermissionsItem?.userType ===
											UserERPTypeId.ThirdPartyUser
											? true
											: false
									}
									updatedUserPermissions={setUpdatedUserPermissions}
									isEditMode={true}
									canEditPermission={props?.editPermission}
									setConfidentialityDropdownDisabled={setConfidentialityDropdownDisabled}
								/>
							</section>
							<section className="ticketdetail">
								<h5>{labels.confirmationMessage}</h5>
								<MotifFormField>
									<MotifLabel>{labels.ticketNumber}</MotifLabel>
									<MotifInput
										value={ticketNumber}
										maxLength={100}
										clearButtonTitle={labels.clear}
										onChange={handlerOnTicketEntered}
									/>
									{showErrorMsg() && (
										<MotifErrorMessage>{labels.validationMessage}</MotifErrorMessage>
									)}
								</MotifFormField>
							</section>
						</section>
					</section>
					<section className="motif-modal-footer footermsg">
						<section className="footer-btn">
							<MotifButton
								variant="primary-alt"
								name="invite"
								value="back"
								onClick={onSaveButtonclick}
								className=""
							>
								{labels.PermissionSaveButtonText}
							</MotifButton>
							<MotifButton
								variant="secondary"
								name="cancel"
								value="back"
								onClick={() => {
									props.closeModal();
								}}
								className=""
							>
								{labels.PermissionCancelButtonText}
							</MotifButton>
							<section className="requiredtext">
								{showErrorMsg() && <MotifErrorMessage>
									<h6>{labels.messageSubmitValidationError}</h6>
								</MotifErrorMessage>}
							</section>
						</section>
					</section>
				</section>
			)}
		</EditUserPermissionsWrapper>
	);
}

export default EngagementEditUser;
