/* eslint-disable prettier */

import {
	Addon
} from 'react-bootstrap/lib/InputGroup';

/**
 * Created by calhosh on 4/14/2017.
* PT BR resource file
 */
export const labels = {
    defaultDropdown: "Menu Suspenso",
    status: "Status",
    language: "Idioma",
    channel: "Canal",
    header: "Cabeçalho",
    headerName: "Nome do cabeçalho",
    sectionCustomLabelLabel: "Legenda personalizada",
    sectionNamePlaceHolder: "Inserir o título de seção",
    showRelatedObjectLabel: "Mostrar objetos relacionados",
    errorBanner: "{0} Erro (s)",
    engagements: "Engagements",
    selectedengagements: "Engagement",
    addEngagement: "Adicionar engagement",
    archives: "Arquivos",
    userInitialsTitle: "Opções do usuário",
    clickForOptions: "Clicar para mais opções",
    comingDue: "Próximo ao vencimento",
    whatsNew: "O que há de novo",
    auditMetrics: "Métricas da auditoria",
    id: "ID ",
    archived: "Arquivado",
    myarchives: "Meus engagements arquivados",
    allarchives: "Todos os engagements arquivados",
    logout: "Sair",
    norecords: "Nenhum registro foi encontrado",
    nomatches: "Nenhuma informação correspondente encontrada, por favor tente novamente",
    notemplates: "Não há modelos disponíveis para a Área/Região selecionada",
    pleasetryagain: "Por favor, tente novamente",
    firstpage: "Primeira página",
    prevpage: "Página anterior",
    nextpage: "Próxima página",
    lastpage: "Última página",
    allengagements: "Todos os engagements",
    searchPlaceholder: "Pesquisar",
    searchEngagementsPlaceholder: "Pesquisa de projetos",
    clearSearch: "Limpar pesquisa",
    searchTextPlaceHolder: "Pesquisar",
    show: "Mostrar  ",
    manageaccess: "Gerenciar acesso",
    restore: "Restaurar",
    rollforward: "Roll forward",
    viewaccesslist: "Ver lista de acesso",
    teammembers: "Membros da equipe",
    name: "Nome",
    initials: "Iniciais",
    role: "Papel",
    usersettings: "Configurações do Usuário",
    usersettingsnewengagements: "As configurações se aplicam a engagements recém-criados",
    usersettingsallengagements: "As configurações se aplicam a todos os espaços de trabalho e engagements",
    enterfirstname: "Mostrar nome (Primeiro)",
    enterlastname: "Mostrar nome (Último)",
    firstname: "Primeiro nome",
    lastname: "Último nome",
    enter: "Entrar",
    select: "Selecione",
    email: "Endereço de e-mail",
    contact: "Número de telefone",
    accesslist: "Lista de acesso",
    engagementsettings: "Configurações do engagement",
    displayname: "Mostrar nome",
    signoffinitials: "Iniciais das assinaturas",
    primaryoffice: "Escritório principal",
    roles: "Função",
    phonenumber: "Número de telefone",
    globalapplicationsettings: "Configurações de aplicativos globais",
    displaylanguage: "Exibição de idioma",
    preferreddateformat: "Formato de data preferido",
    preferrednumberformat: "Formato de número preferido",
    save: "Salvar",
    cancel: "Cancelar",
    isRequired: "{0} É requerido",
    arcretrymessage: "Ocorreu um erro no processo de arquivamento. A data de registro para o arquivamento é atualmente {0}. O EY Canvas está tentando novamente efetuar o arquivamento automaticamente. Aguarde quinze horas a partir do momento em que este arquivo foi enviado para que o processo de recuperação automática seja concluído antes de entrar em contato com o Help Desk. Se o Help Desk puder resolver sem restaurar o engagment, a data de arquivamento continuará a ser {0}. Se o Help Desk tiver que restaurar o engagement para que a equipe tome medidas corretivas, a data do arquivo será redefinida para quando a equipe enviar o arquivo novamente. Se isso causar um arquivamento tardio, a equipe deve incluir a documentação no engagement, mencionando o número do tíquete do Help Desk, o problema e as etapas executadas para resolver de acordo com a política de arquivamento local.",
    ArchiveRetryLink: "Repetir Archive",
    workspacelist: "Lista de áreas de trabalho",
    engagementslist: "Lista de Engagement",
    accessrequested: "Acesso solicitado",
    noaccess: "Nenhum acesso",
    accessrejected: "Acesso rejeitado",
    requestaccess: "Solicitação de acesso",
    allarchiveinitialmsg: "Inserir o nome do Cliente, nome da Área de trabalho ou nome do Engagement para iniciar",
    noengagementsforusermessage: "Você não tem nenhum engagement ativo. Clique 'Criar engagment' para iniciar",
    noengagementstoimportto: "Você não tem engagements para importar.",
    favorites: "Favoritos",
    portfolioview: "Todos os projetos v2",
    reviewengagement: "Revisão de Engagements",
    noreviewengagementsmessage: "Você não possui nenhum Engagement de revisão",
    noreviewengagementsmessagecontact: "Você não tem nenhum engagament de revisão. Entre em contato com um membro da equipe de engagement se você precisar de acesso.",
    nofavoriteengagementsmsg1: "Você não tem nenhum engagement no favorito.",
    nofavoriteengagementsmsg2: "Para adicionar engagements a esta visualização, navegue para Todos os engagements",
    nofavoriteengagementsmsg3: "e clique no ícone de favoritos no cartão do engagement.",
    markasfavorite: "Marcar como favorito",
    unmarkfromfavorites: "Remover dos favoritos",
    selectworkspacenoclientworkspaceexists: "Você não possui espaços de trabalho existentes. Clique em Voltar e selecione 'Novo espaço de trabalho' na Etapa 1.",
    importpkgselectworkspacenoclientworkspaceexists: "Você não tem nenhum espaço de trabalho existente. Clique em 'Criar novo espaço de trabalho' na etapa anterior.",
    expirydatelabel: "Este engagement será excluído em",
    expirydatetodaytomorrowlabel: "Este engagement será excluído",
    /*Reporting Hub*/
    reportingHub: "Relatório",
    reportingHubTitle: "Relatório do EY Canvas",
    selectEngagement: "Selecione um engagement para visualizar relatórios",
    connectVPN: "Os relatórios só podem ser acessados quando conectados à Rede EY ou ao EY Remote Connect",
    reportsNotLoaded: "Os relatórios não puderam ser carregados. Atualize a página e tente novamente. Se o problema persistir, entre em contato com o Help Desk.",
    searchByEngagementNameOrClientId: "Selecionar Engagement",
    dataExportLabel: "Exportar Informações",
    summaryScreensLabel: "Tela de Resumo",
    projectManagementLabel: "Gerenciamento de Projeto",
    engagement: "Nome do engagement",
    reportingNotAvailable: "Os relatórios não estão disponíveis no local selecionado. Entre em contato com o seu Champion local do EY Canvas para obter mais detalhes.",
    client: "Cliente",
    workspace: "Espaço de trabalho",
    serviceline: "Linha de serviço",
    subserviceline: "Sublinha de Serviço",
    engagementstartdate: "Data final do ano",
    engagementenddate: "Data final do período coberto",
    deleteafter: "Excluir após",
    auditorsreportdate: "Data do relatório",
    createengagementquestionlabel: "Crie um engagement",
    choosenewexistingquestionheader: "Criar engagement em um espaço de trabalho novo ou existente?",
    createengagementheader: "Criar engagement  ",
    restoreengagementheader: "Restaurar engagement",
    createworkspacestepheader: "Criar novo espaço de trabalho",
    selectexistingworkspacestepheader: "Selecione um espaço de trabalho existente",
    workspacenotelibilefornewengagement: "Área de trabalho não qualificada para novos engagements",
    choosenewexistingstepheader: "Espaço de trabalho novo ou existente",
    editengagementwizheader: "Editar engagement",
    removeengagementwizheader: "Remover engagement",
    editworkspacewizheader: "Editar espaço de trabalho",
    editworkspacestepfooter: "Clique em 'Salvar e fechar' para salvar as alterações na área de trabalho ou clique em 'Salvar e continuar' para editar um engagement.",
    editwizheader: "Editar",
    newworkspace: "Novo espaço de trabalho",
    existingworkspace: "Espaço de trabalho existente",
    back: "Voltar",
    continue: "Continuar",
    saveclose: "Salvar e fechar",
    savecontinue: "Salvar e continuar",
    finish: "Finalizar",
    step01: "Etapa 01",
    step02: "Etapa 02",
    step03: "Etapa 03",
    step04: "Etapa 04",
    step05: "Etapa 05",
    engagementname: "Nome do engagement",
    confirmdecline: "Confirme o declínio. Essa ação não pode ser desfeita.",
    confirmdeclinevalidation: "Confirme que você deseja declinar.",
    responderror: "Você já respondeu a este convite, atualize a página e tente novamente.",
    engagementcode: "Código do engagement",
    engagementcodename: "Nome do código do engagement",
    selectEngagementHeader: "Selecione um espaço de trabalho existente",

    selectworkspaceclient: "Selecionar cliente",
    selectclient: "Selecione um cliente para visualizar os espaços de trabalho relacionados",
    createnewworkspace: "Criar novo espaço de trabalho",
    editworkspace: "Editar espaço de trabalho",
    clientcode: "Código do cliente",
    clientname: "Nome do cliente",
    invalidclientcode: "Código de cliente inválido",
    workspacename: "Nome do espaço de trabalho",
    location: "Localização",
    workspacecodenameplaceholder: "Digite o código de cliente para procurar o nome do cliente",
    engagementcodenameplaceholder: "Digite o código de engagement para procurar o nome do código",
    isprimary: "Primário",
    addengagementcode: "Adicionar código de engagement",
    engagementnamerequired: "O nome do engagement é requerido",
    primaryofficerequired: "O escritório principal é requerido",
    languagerequired: "O idioma é requerido",
    servicelinerequired: "Linha de serviço é requerida",
    subservicelinerequired: "Sub linha de serviço é requerida",
    engagementcodevalidationmessage: "O código do engagement não corresponde ao código do cliente",
    engagementcoderequired: "O código do engagement é requerido",
    yearenddate: "Data encerramento do exercício",
    periodenddate: "Data final do período",
    reportdate: "Data do relatório de auditoria",
    complete: "Concluir",
    end: "Final ",
    started: "Iniciado",
    notstarted: "Não foi iniciado",
    inprogress: "Em andamento",
    inreview: "Em revisão",
    taskstatus: "Status da tarefa",
    confirmlabel: "Confirmar  ",
    submitlabel: "Enviar",
    editengagementquestionlabel: "Editar um engagement",
    removeengagementquestionlabel: "Remover engagement",
    removeengaementconfirmationmessage1: "Tem certeza de que deseja remover o seguinte engagement da sua lista de engagements? Se você remover o engagement, não terá mais acesso a ele.",
    removeengagementerrormessage: "O engagement não pode ser removido da área de trabalho, pois você é o último usuário ativo. Adicione outro membro da equipe ou exclua o engagement.",
    deleteengagement: "Excluir engagement",
    deletreviewcopyoption: "Excluir",
    deleteengagementconfirmationmessage: "Tem certeza de que deseja enviar uma solicitação para excluir o seguinte engagement? ",
    deleteengagementconfirmationmessage1: "Para engagements rescindidos ou para os quais não emitimos um relatório, as equipes do engagement devem consultar a Prática Profissional para determinar se um arquivamento é necessário.",
    deleteengagementconfirmationmessageoneuser: "Tem certeza de que deseja excluir o seguinte engagement? ",
    deleteengagementconfirmationmessageoneuser1: "Os requisitos de arquivamento em",
    deleteengagementconfirmationmessageoneuser2: "DOC+ARC 3.1",
    deleteengagementconfirmationmessageoneuser3: "devem ser considerados antes da exclusão. Por exemplo, projetos finalizados ou aqueles em que não emitimos um relatório podem exigir consulta com a Prática Profissional para determinar se um arquivo é necessário.'",
    deleteengagementmarkeddelete: "O Engagement já está selecionado para exclusão",
    deleteengagementoption: "Excluir",
    deleteengagementreasonlabel: "Digite o motivo da exclusão",
    deleteengagementreasonquestion: "Por que esse engagement está sendo excluído?",
    deleteengagementreasonrequired: "O racional é necessário. Selecione uma opção ou digite uma razão acima.",
    deleteengagementcommentreasonrequired: "O racional é requerido. Selecione uma razão acima.",
    deleteengagementcommentrequiredlabel: "Por favor, digite a razão para excluir",
    deletereasontrainingengagementlabel: "Engagement de treinamento",
    deletereasonrestoredorcopiedlabel: "Engagement restaurado ou copiado apenas para referência",
    deletereasoncreatedbymistakelabel: "Criado por engano",
    deletereasonotherlabel: "Outro",
    deletereasonterminedbyclientlabel: 'Terminated engagement where no work was performed. If work was performed in this engagement, then it should be archived.',
    markeddeleteconfirm: "Confirmar exclusão",
    deleterequestbannertext1: "Pedido de exclusão de",
    deleterequestbannertext2: "desde",
    markeddeleteconfirmmessage: "Tem certeza de que deseja excluir o seguinte engagement? ",
    markeddeleteconfirmmessage1: "Se você confirmar a exclusão, esta ação não pode ser desfeita.",
    markeddeletereasonlabel: "Razão para excluir",
    engagementlistplaceholder: "Selecionar engagement",
    engagementrequired: "O engagement é requerido",
    restoreconfirmationstepheader: "Confirmação",
    restoreconfirmationheader: "Tem certeza de que deseja restaurar o engagement?",
    selectarchiveheader: "Selecionar documento arquivado",
    restoreengagementstepheader: "Restaurar engagement",
    chooserollfwdhdr: "Roll forward para um workspace novo ou existente",
    rollfwdstephdrext: "Roll forward para um workspace existente",
    rollfwdstephdrnew: "Roll forward para um novo workspace",
    rollfwdhdrqstn: "Roll forward do engagement em um workspace novo ou existente?",
    rollforwardengagementstepheader: "Roll forward do engagement",
    genericerror1: "Ocorreu um erro. Tente novamente ou entre em contato com a equipe de suporte técnico",
    restoreengagementmaxcopies: "O engagement excedeu o número máximo de cópias restauradas ({0}). Solicite acesso a uma cópia existente.",
    restoreengagementengcopies: "Existem {0} cópias restauradas deste engagement disponíveis:",
    restoreengagementengcopy: "Há {0} cópia restaurada deste engagement disponível:",
    restoreengagementengnocopies: "Não há outras cópias restauradas deste engagement disponíveis.",
    globalexceptionrestoreengagementengcopies: "Existem {0} cópias restauradas deste engagement disponíveis. Marque a caixa acima para restaurar com a regra de exceção global. ",
    globalexception: "Exceção global",
    globalexceptioncheckerror: "Marque a opção de Exceção global para continuar.",
    restoreengagementrestoredon: "restaurado em",
    restoreengagementusers: "Usuários",
    restoreengagementuser: "Usuário",
    at: "às",
    arcfileuserunavailable: "Usuário indisponível",

    restorehdrqstn: "Restaurar o engagemente em um espaço de trabalho novo ou existente?",
    chooserestorehdr: "Restaurar para o espaço de trabalho novo ou existente",
    restorestephdrext: "Restaurar para um espaço de trabalho existente",
    restorestephdrnew: "Restaurar para um novo espaço de trabalho",

    close: "Fechar",
    confirm: "Confirmar",
    settings: "Configurações",
    expand: "Expandir",
    collapse: "Reduzir",
    contextMenuEdit: "Editar",
    contextMenuEditEngagement: "Editar engagement",
    contextMenuRemoveEngagement: "Remover engagement",

    contextMenuDeleteEngagement: "Excluir engagement",
    contextMenuEditWorkspace: "Editar espaço de trabalho",
    contextMenuDelete: "Deletar",
    contextMenuOverrideArchiveDate: "Substituir data do prazo final do archive",
    na: "Não Aplicável",
    archivesubmittedby: "Enviado por",
    archivedateandtime: "Data e hora",
    notifications: "Notificações",
    nonotificationmsg: "Você não possui nenhuma notificação.",
    notifcenter: "Centro de Notificação",
    notifitemspendingact: "itens com ação pendente",
    accept: "Aceitar",
    reject: "Rejeitar",
    acceptall: "Aceitar tudo",
    rejectall: "Rejeitar tudo",
    notifEngInvite: "Convites de engagement",
    notifengaccesreq: "Pedido de acesso ao engagement",
    keyevents: "Eventos principais",
    milestones: "Milestones",
    importselecttemplateheader: "Por favor, selecione o modelo que você gostaria de usar",
    importtemplateheader: "Selecione uma área/região da qual você gostaria de importar",
    import: "Importar",
    importregionstepheader: "Selecione Área/Região",
    importtemplatestepheader: "Selecione um modelo",
    importtemplatemsg: "Selecione uma área/região para continuar",
    importselecttemplatemsg: "Selecione um modelo para continuar",
    importengagementheader: "Modelo de importação",
    importhdrqstn: "Importar para um novo espaço de trabalho ou espaço de trabalho existente",
    importstephdrext: "Importar para um espaço de trabalho existente",
    importstephdrnew: "Importar para um novo espaço de trabalho",
    chooseimporthdr: "Importar para um espaço de trabalho novo ou existente",
    selectexistingworkspacemsg: "Selecione um espaço de trabalho para continuar",
    selectaclienttocontinuemsg: "Selecione um cliente para continuar",
    selectengagementmsg: "Selecione um engagement para aceitar o pacote de dados",
    selectgaengagementmsg: "Você deve selecionar um engagement para aceitar a solicitação de link",
    arearegion: "Área/Região",
    importdatapackagequestionlabel: "O que você gostaria de fazer?",
    sentby: "Enviado por",
    on: "em",
    by: "por",
    view: "Visão",
    sourceengagement: "engagement de origem",
    package: "Pacote",
    decline: "Declinar",
    delegate: "Delegar",
    chooseaction: "Escolha uma ação",
    selectworkspace: "Selecione um espaço de trabalho",
    declinepackage: "Recusar solicitação",
    delegatepackage: "Delegar pacote",
    selectengagement: "Selecione um engagement",
    datapackagerequest: "Solicitação de pacote de dados",
    respond: "clique aqui para responder",
    deletionrequestfor: "Solicitação de exclusão recebida para",
    invalidengagementcode: "Código de engagement inválido",
    noNotifications: "Nenhuma notificação disponível",
    bellIconTitle: "Centro de Notificação",
    restoredengagement: "Engagement restaurado",
    profiledeliveryinprogress: "Entrega de perfil em andamento",
    completeprofileheader: "Completar Perfil ",
    completeindependenceheader: "Completar Independência",
    independencecompletedheader: "Independência concluída",
    retry: "Repetir",
    errorDate: "Erro",
    invitationDate: "Data do convite",
    engCreationFailedWarning: "A criação do engagement falhou. Atualize a página e tente novamente. Se o problema persistir, entre em contato com o Help Desk.",
    deletedEngagementWarning: "Se o engagement foi utilizado e excluído por engano, entre em contato com o Suporte Técnico imediatamente.",
    searchEngagementWarning: "Pesquise esses engagements para executar  ações.",
    archiveOverrideWarning: "O período de substituição do archive selecionado não é permitido pelo ARC. A data limite do archive foi revertida para o valor padrão para o seu local.",
    archiveOverrideInProgress: "Substituição de data limite de arquivamento pendente de aprovação ",
    archiveOverrideAccepted: "A substituição do data limite do arquivamento está completa",
    archiveOverrideRejected: "O período de substituição do archive selecionado não é permitido pelo ARC. A data do prazo do archive foi revertida para a data padrão de sua localização.",
    scopeName: "Nome do escopo",
    shortName: "Nome curto",
    invited: "Convidado  ",
    invitedOn: "Convidado em",
    instructions: "instruções",
    updates: "atualizações",
    viewinstructions: "Ver instruções",
    viewinstructionsupdates: "Ver atualizações",
    more: "Mais",
    addmembers: "Adicionar membros",
    statusChangeDate: "Status alterado",
    deletedBy: "Excluído por",
    deletedDate: "Excluído",
    deactivatedBy: "Desativado por",
    deactivatedDate: "Desativado",
    requestUserName: "Requerido por",
    requestDate: "Requerido",
    archiveDate: "Arquivado",
    receivedFrom: "Recebido de",
    received: "Recebido",
    clear: "Limpar",
    clearAll: "Limpar tudo",
    type: "Tipo",
    engagementhasbeendeleted: "O engagement selecionado foi excluído por outro usuário. Atualize a página e tente novamente.",
	independenceOnlyRejectInvite: {
        engagementIsNotAvailable: "O Engagement não está disponível. Atualize a página e tente novamente. Se o problema persistir, entre em contato com o Help Desk.",
        failedToRejectInvite: "Falha ao rejeitar o convite. Atualize a página e tente novamente. Se o problema persistir, entre em contato com o Help Desk.",
        succesfulRejection: "Convite rejeitado com sucesso.",
        loadingLabel: "Rejeitando convite…",
	},
    manageTeam: "Gerenciar equipe",
    childinprogress: "Configurações do Engagement em andamento",
    // SeeMore component
    showMore: "Mostrar mais",
    showLess: "Mostrar menos",
    showMoreEllipsis: "Mostre mais...",
    showLessEllipsis: "Mostre menos...",
    accepted: "Aceito",
    acceptedOn: "Aceito em:",
    rejected: "Rejeitado",
    deniedBy: "Negado por",
    engagementstatusrestored: "Restaurado",
    engagementstatusmarkedfordeletion: "Selecionado para exclusão",
    delegaterequired: "É necessário delegar",
    username: "Nome do usuário",
    noresults: "Nenhum resultado encontrado, tente novamente",
    importdatapackageconcurrencymessage: "O status da solicitação da cópia foi alterado. Atualize a página e tente novamente.",
    selectdelegateuser: "Selecione um delegado",
    eyusersearchplaceholder: "Digite o nome ou e-mail para pesquisar",
    optional: "(Opcional)",
    engagementAlerts: "Alertas do Engagement",
    engagementMetrics: "Métricas do engagement",
    overdue: "Vencidos",
    /*day labels*/
    mon: "Segunda-feira",
    tues: "Terça-feira",
    wed: "Quarta-feira",
    thurs: "Quinta-feira",
    fri: "Sexta-feira",
    sat: "Sábado",
    sun: "Domingo",
    canvas: "EY Canvas",
    overrideArchiveDateModalHeader: "Substituição do prazo final do archive",
    overrideArchiveDateModalMessage: "Tem certeza de que deseja sobrepor a data do prazo do archive?",
    overrideArchiveDateModalComboPlaceholder: "Substituição do prazo final do archive",
    createdOn: "Criado em",
    expiresOn: "Expira em",
    archiveDeadlineDate: "Data do prazo do archive",
    defaultOverriden: "Data padrão substituída",
    selectexistingworkspace: "Selecione o espaço de trabalho existente",
    importtonewworkspace: "Importar para um novo espaço de trabalho",
    groupaudit: "Solicitação de link de auditoria de grupo",
    gaacceptdeclinedelegatequestionlabel: "O que você quer fazer com essa solicitação de link?",
    gaacceptselectengagementquestionlabel: "Selecione um engagement para vincular",
    gadelegatequestionlabel: "Delegar solicitação de link de auditoria de grupo",
    gadeclinequestionlabel: "Recusar solicitação de link de auditoria de grupo",
    garegioncomponent: "Região/Componente",
    gascope: "Escopo",
    gamxTransfer: "Transferência do GAMx",
    closeNotificationCenter: "Fechar a central de notificação",
    contextMenucrp: "Criar cópia de revisão",
    workingoffline: "Trabalhando offline",
    trainingoffline: "Trabalhando offline (treinamento)",
    training: "Treinamento",
    crpdescription: "Crie uma cópia do engagement para revisores externos.",
    crpeditdescritpion: "Editar o nome do engagement ou expiração. Os engagements expirados serão excluídos automaticamente. ",
    expiry: "Prazo",
    enteremail: "Insira o endereço de e-mail",
    create: "Criar",
    metricsPlaceholderText: "Não há dados disponíveis para o período de tempo selecionado",
    deleteReviewCopy: "Excluir cópia de revisão",
    deleteReviewCopyMsg: "Tem certeza de que deseja excluir esta cópia de revisão? Uma vez excluído, o engagement não estará mais disponível e não poderá ser recuperado.",
    crpNotAvailable: "A cópia da revisão não pode ser criada para este engagement porque o Portal de acesso externo do Canvas não está disponível no momento. Contate o help desk para maiores informações. ",
    externalmember: "Membros externos",
    internalmember: "Membros Internos",
    add: "Adicionar",
    crpEditMembers: "Editar",
    invalidEmail: "Digite um endereço de e-mail válido",
    emailNotFound: "Endereço de email não encontrado",
    dupExternalUser: "Membro externo já adicionado",
    noExternalUser: "Nenhum membro externo foi adicionado.",
    expiresIn: "Expira em",
    days: "dias",
    today: "Hoje",
    tomorrow: "amanhã",
    reviewengagementname: "Revisar o nome do engagement",
    enternameoremail: "Digite o nome ou endereço de email",
    options: "Opções",
    userNotFound: "Usuário não encontrado",
    remove: "Remover",
    crpEditHeader: "Editar cópia de revisão",
    globalexceptionrestore: "Restauração de exceção global",
    expired: "Expirado",
    softwareUpdate: "Atualizações de software",
    updateNow: "Atualizar agora",
    updateMsg: "As atualizações de software estão disponíveis para o EY Canvas. Selecione Atualizar Agora para baixar e instalar as atualizações. A página será atualizada.",
    preferredlandingpageview: "Visão preferida da página inicial",
    preferredlandingpagefilter: "Filtro preferido da página inicial",
    engagementlist: "Lista de engagements",
    workspacelistusersettings: "Lista de workspaces",
    engagementsclientstoload: "Número de engagements/clientes demonstrados toda vez que você acessar o aplicativo",
    favoriteengagements: "Engagements marcados como favoritos",
    preferredEngagementDashboard: "Configurações do painel do engagement",
    preferredEngagementDashboardTeamMembers: "Membros da equipe",
    preferredEngagementDashboardExecutives: "Executivos",
    linkedHelixProjects: "Vinculado ao EY Helix",
    loadingLabel: "Carregando...",
    loadingErrorText: "Ocorreu um erro ao carregar a Linha do Tempo. Atualize a página e tente novamente.",
    selectYear: "Selecione o ano (obrigatório)",
    clientNameOrId: "Nome do cliente ou ID",
    engagementNameOrId: "Nome de engagement ou ID",
    engagementCodeOrCodeName: "Código do engagement ou nome do código",
    searchArchives: "Pesquisar",
    clearSearchArchives: "Limpar pesquisa",
    searchArchivesMessage: "Para começar, selecione o ano em que o engagement foi arquivado. Em seguida, pesquise por uma ou mais opções apresentadas abaixo:",
    titleUpdateProfile: "Informação",
    msgUpdateProfileLn1: "Você atualizou o idioma de engagement. Para receber conteúdo neste idioma, acesse Atualizações de conteúdo do Engagement e selecione Atualização de conteúdo global.",
    sessionTimerMsg1: "Devido à inatividade, sua sessão de trabalho atual está prestes a expirar.",
    sessionTimerMsg2: "Para sua segurança, as sessões do EY Canvas terminam automaticamente após 30 minutos de inatividade.",
    sessionTimerMsg3: "Sua sessão atual expirará em {0} minutos.",
    sessionTimerMsg4: "Se você deseja permanecer logado, por favor clique",
    sessionTimerHeader: "Logout automático para a sua proteção",
    theme: "Tema",
    light: "Luz",
    dark: "Escuro",
    chooseDisplayTheme: "Escolha o tema de exibição predefinido.",
    searchByCountry: "Selecione o país",
    primaryEngagementLabel: "Engagement primário",
    componentEngagementLabel: "Engagement componente",
    regionalEngagementLabel: "Engagement regional",
    linkedLabel: "Vinculado",
    eyCanvasEconomicsTooltip: "Dados econômicos do EY Canvas",
    eyOversightTooltip: "EY Canvas Oversight",
    eyPrivacyNoticeTooltip: "Aviso de privacidade",
    eyTermsOfServiceTooltip: "Termos de serviço",
    eyGeneralHelp: "Ajuda Geral",

    preferrednegativenumberformat: "Formato de número negativo preferido",

    protectedEngagementMessage: "Evite que documentos sejam impressos, copiados ou reproduzidos de outra forma",
    documentRrestriction: "Restrição de documentos: ",
    on: "Sobre",
    off: "Desligado ",
    protectedEngagementMessageOn: "- Os documentos neste engagement não podem ser impressos, copiados ou reproduzidos de outra forma",
    protectedEngagementMessageOff: "- Os documentos deste engagement podem ser impressos, copiados ou reproduzidos de outra forma",

    /* Dashboard labels*/
    homeLabel: "Página Inicial",
    manageAccessHeader: "Gerenciar o acesso",
    showInactiveUserslabel: "Mostrar usuários inativos",
    addUserButtonLabel: "Adicionar usuário",
    searchUserText: "Buscar",
    nameHeader: "Nome",
    emailHeader: "Email",
    actionHeader: "Ação",
    manageRolesLabel: "Gerenciar funções",
    deactivateUserlabel: "Desativar usuário",
    manageRolesTableModule: "Módulo",
    manageRolesTableRoles: "Função",
    manageRolesTableServiceLine: "Linha de serviço",
    manageRolesTableCountries: "Países",
    manageAccessAssignRole: "Atribuir função",
    manageAccessNoRolesAdded: "Sem papéis adicionados",
    manageAccessDeleteRoleModalHeader: "Excluir função",
    manageAccessDeleteRoleButton: "Excluir",
    noRoleResults: "Sem resultados",
    confirmAssignRoleButton: "Atribuir",
    permissionGroupName: "Nome do grupo",
    permissionGroupDescription: "Descrição do grupo",
    activeMembers: "Membros ativos",
    permissionGroupMembersTitle: "Título",
    permissionGroupMembersPhone: "Telefone",
    permissionGroupMembersGui: "GUI",
    permissionGroupMembersUserId: "Identificação de usuário",
    permissionGroupMembersAdStatus: "Status de AD",
    permissionGroupAddMember: "Adicionar membro",
    permissionGroupUserDeleteLabel: "Excluir",
    permissionGroupUserDeleteHeader: "Excluir membro",
    permissionGroupDetails: "Detalhes de grupos de permissão",
    cache: "Chave de cache",
    cacheSelection: "Selecione o local do cache",
    engagementID: "ID do engagement",
    cacheKey: "Chave de cache",
    validationMessage: "Campo obrigatório",
    getCache: "Obter cache",
    clearResult: "Limpar resultados",
    removeCache: "Remover cache",
    requiredField: "Campo obrigatório",
    ticketNumber: "Número do ticket (obrigatório)",
    confirmationMessage: "Insira um número de ticket para esta ação",
    confirmButtonLabel: "Confirmar",
    cancelButtonLabel: "Cancelar",
    modalHeaderLabel: "Remover cache",
    adminModuleinvalidEngagementId: "ID de projeto inválido",
	adminModuleInvalidCacheKey: 'Cache key contains invalid characters',
    clearResults: "Limpar resultados",
    getDocuments: "Obter documentos",
    documentsHeader: "Documentos",
    validTicketNumber: "Por favor insira um númeor de ticket válido",

    egagementsPageDescription: "Procure engagements com base em um ou múltiplos critérios para atualizar os dados do engagement, convidar novos membros da equipe, atualizar o status e as permissões dos membros da equipe existentes. ",
    engagementSearchCriteria: "País ou ID do Projeto são necessários para realizar a pesquisa",
    engagementsHeader: "Engagement",
    engagementIdSearchPlaceholder: "ID do Projeto",
    engagementNameSearchPlaceholder: "Nome do Projeto",
    workSpaceSearchPlaceholder: "Nome do espaço de trabalho ou ID",
    clientSearchPlaceholder: "Nome ou código do cliente",
    engagementCodeSearchPlaceholder: "Código do engagement ou nome de código",
    userSearchPlaceholder: "GUI do usuário, e-mail ou UserID",
    countrySearchPlaceholder: "País",
    engagementTableHeader: "Engagement",
    nameTableHeader: "Nome",
    workspaceIdTableHeader: "Área de trabalho",
    clientTableHeader: "Cliente",
    engagementCodeTableHeader: "Código do engagement",
    yearEndTableHeader: "Fim de ano",
    engagementdeleteDate: "Data de exclusão do engagement",
    archivalguid: "GUID de arquivamento",
    archivalLogId: "ID do registro de arquivamento",
    archivalFileguid: "GUID do arquivo de arquivamento",
    profile: "Perfil concluído",
    independence: "Independência",
    archiveRetry: "Repetir arquivamento",
    engagementdetails: "Detalhes do engagement",
    engagementsdetails: "Detalhes dos engagements",
    statusTableHeader: "Status",
    attributesTableHeader: "Atributos",
    attributesProfileComplete: "Perfil Completo",
    attributesIndpendenceApplicable: "Aplicabilidade da independência",
    engagementPagePlaceholder: "Procure por engajament acima, os resultados serão exibidos aqui",
    noEngagementFound: "Nenhum engagement encontrado",
    emptySearchFieldError: "Pelo menos um campo de pesquisa deve ter um valor.",
    largeCountrySelectionError: "Por favor, selecione 10 ou menos países.",
    saveEngagement: "Salvar",
    undoEditEngagement: "Redefinir",
    engagementUpdateSuccess: "As mudanças foram salvas com sucesso",
    archiveRetrySuccessMessage: "O arquivamento foi iniciado com sucesso para este engagement. Verifique o status mais tarde para validar se o arquivamento foi bem sucedido.",
    welcome: "Bem-vindo a ",
    welcomeadmin: "Ferramenta de administração",
    engagementCard: "Engagements",
    messagingCard: "Mensagens",
    templatesCard: "Modelos",
    permissionGroupCard: "Grupos de permissão",
    manageAccessCard: "Gerenciar acesso",
    databaseLocatorCard: "Sincronizar localizador de banco de dados",
    importForReviewTooltip: "O engagement será otimizado para revisão e alguns recursos e funcionalidades não estarão disponíveis.",
    importForReviewChecboxText: "Importar apenas para fins de revisão",
    clientCode: "Código do cliente",
    primaryEngagementCode: "Código do engagement principal",
    invalidEngagementTitle: "Código de cliente ou engagement inválido",
    invalidEngagementMessage: "O código do cliente e/ou o código do engagement precisam ser validados antes que você possa acessar este engagement. Clique em continuar para atualizar e validar os códigos.",
    insufficientPermission: "Permissões insuficientes para este módulo",
    noPermission: "Sem permissão",
    noAdminRights: "Você não tem direitos sobre esta ferramenta.",
    //Engagement Users Lables
    engmtUserTableUserNameHeader: "Nome do usuário",
    engmtUserTableEmailAddressHeader: "Endereço de email",
    engmtUserTableRoleHeader: "Função",
    engmtUserTableGUIeHeader: "GUI",
    engmtUserTableStatuseHeader: "Status",
    engmtUserTableIndependenceStatuseHeader: "Independência",
    engmtUserTableConfidentialityHeader: "Confidencialidade",
    engmtUserTableADStatusHeader: "Status de AD",
    engmtUserTablePermissionsHeader: "Permissões",
    engmtUserTableActionHeader: "Ação",
    engagementUsersTitle: "Usuários do engagement",
    engagementInviteMember: "Convidar membro",
    engagementInviteUser: "Convidar usuário",
    engagementRoles: "Função",
    engagementRolesRequired: "Função do engagement é necessária",
    engagementInviteMemberSuccessMessage: "Membro foi convidado para o engagement com sucesso",
    engagementInviteMemberDescription: "Procure usuários pelo primeiro nome, sobrenome ou endereço de e-mail acima. Os resultados serão exibidos aqui.",
    engagementUsersTitleEdit: "Editar",
    engagementUpdatePermissionToolTip: "Você não tem permissões suficientes para atualizar o engagement.",
    engagementRetryArchivePermissionToolTip: "Você não tem permissões suficientes para repetir o arquivamento",
    usersEditPermissionToolTip: "Você não tem permissão para realizar esta ação. Entre em contato com o administrador para solicitar permissão.",
    usersPermissionToolTip: "Você não tem permissões suficientes para atualizar o usuário do engagement.",
    invalidArchivalGuidMessage: "GUID de arquivamento inválido",
    invalidArchivalFileGuidMessage: "Arquivo GUID de arquivamento inválido",
    invalidArchivalLogId: "ID de registro de arquivamento inválido",
    datePickerLabel: "Selecione uma data",
    updateEngagement: "Atualize o Projeto",

    invalidEngagementMessage: "O código do cliente e/ou o código do engagement precisam ser validados antes que você possa acessar este engagement. Clique em continuar para atualizar e validar os códigos.",
    invalidEngagementId: "O ID de engagement é inválido",
    serverErrorMessage: "Erro do servidor volte mais tarde",
    engagementDetailsNoPermission: "Você não tem permissão suficiente para visualizar os detalhes do engagement.",
    engagementNotFound: "Detalhes do engagement não encontrados",
    pageNotFound: "Página Não encontrada",
    assignRoleModalMessage: "Selecione um módulo e uma função específica a serem habilitadas para o usuário",
    selectModule: "Selecione módulo",
    selectRole: "Selecione a função",
    serviceLine: "Linha de Serviço",
    countries: "Países",
    confirmAssignRoleButton: "Atribuir",
    addUser: "Adicionar usuário",
    addAdminUserHeader: "Digite nome, sobrenome ou endereço de e-mail do usuário e pressione enter para pesquisar",
    addMemberDescription: "Pesquise usuários pelo nome, sobrenome ou endereço de e-mail acima. Os resultados serão exibidos aqui.",
    seachInputRequired: "Entrada de pesquisa requerida",
    searchUsers: "Pesquisar usuários",
    noResultsFound: "Nenhum resultado encontrado",
    usersToBeAdded: "Usuários a serem adicionados",
    addUserSuccessMessage: "Usuário administrativo adicionado com sucesso.",
    addMemberSuccessMessage: "O membro foi adicionado ao grupo com sucesso",
    deactivateWarning: "Tem certeza de que deseja desativar o seguinte usuário?",
    deactivateButton: "Desativar",
    deactivateModalTitle: "Desativar usuário",
    generateReport: "Gerar relatório",
    generateValidationReport: "Gerar relatório de validação",

    templateManagement: "Modelos",
    templateName: "Nome do modelo",
    templateAreaRegion: "Área/região",
    templateSize: "Tamanho",
    templateCreatedBy: "Criado por",
    templateCreatedDate: "Data de criação",
    templateCountOfEngagement: "Engagements",
    templateStatus: "Status",
    templatePublished: "Publicado",
    templateUnpublished: "Não publicado",
    templateSearchText: "Enviar texto de pesquisa e pressionar Enter",
    templateInsufficientPermissions: "Permissões insuficientes para este módulo",
    templateMultipleArea: "Múltiplo",
    templateEdit: "Editar modelo",
    templateEditSave: "Salvar",
    templateEditCancel: "Cancelar",
    templateEditSuccessMessage: "O modelo foi atualizado com sucesso.",
    templateDeleteConfirmationMessage: "Tem certeza de que deseja excluir o modelo?",
    templateDelete: "Excluir modelo",
    templateEditDelete: "Excluir",

    messagingMessageTitle: "Título da mensagem",
    messagingDescriptionTitle: "Descrição da mensagem",
    messagingStartsOn: "Começa em (UTC)",
    messagingEndsOn: "Termina em (UTC)",
    messagingAffectedDataCenters: "Centro de dados afetados",
    messagingStatus: "Status",
    messagingCreatedBy: "Creado por",
    messagingCreatedDate: "Data de criação",
    messagingDataCenterSystemWide: "Sistema",
    messagingDataCenterMultiple: "Múltiplo",
    messagingDataCenterSingle: "Único",
    messageName: "Título da mensagem",
    messageStartsOn: "Começa em",
    messageEndsOn: "Termina em",
    messageDateTime: "Selecione data e hora",
    messageAllTimeInUTC: "Todos os horários estão em UTC",
    messageTargetAudience: "Público-alvo",
    messageDataCenters: "Centro de dados",
    messageSelectADate: "Selecione uma data",
    messageAllDataCenters: "Todos os centros de dados",
    messageKendoDescription: "O uso de formatação reduzirá o máximo do comprimento da descrição de 4.000 caracteres",
	messageSelectDataCenter: "Selecione um centro de dados",
	messageFieldRequired: "Campo obrigatório",
    messageStartTimeError: "A data e a hora de início não pode ser menor que a hora atual",
    messageEndTimeError1: "A data e a hora de término não deve ser anterior à data e hora atuais",
    messageEndTimeError2: "A data e hora de término deve ser maior que a data e hora de início",
    messageDataCenterError: "Selecione pelo menos um centro de dados",
    messageSubmitValidationError: "Campos obrigatórios ausentes. Role a página e verifique se todos os campos obrigatórios foram preenchidos.",
    messageChangesValidationError: "Nenhuma alteração foi feita. Edite pelo menos um campo para salvar.",
    currentutc: "Data e hora UTC atuais:",
    messageCurrentUTCTime: "{0} (no formato de 24 horas)",
    createMessage: "Criar mensagem",
    createMessagePermissions: "Permissões insuficientes para este módulo",
    createMessageSuccess: "Mensagem criada com sucesso",
    editMessage: "Editar mensagem",
    editMessageSuccess: "Mensagem editada com sucesso",
    editHoverText: "Editar",

    messagingModalDeleteTitle: "Apagar mensagem",
    messagingModalDeleteMessage: "Tem certeza de que deseja excluir a mensagem?",
    messagingModalDeleteButton: "Excluir",
    messagingModalDeleteConfirm: "Confirmar",
    messagingModalDeleteCancel: "Cancelar",
    messagingModalDeletedSuccessfully: "Mensagem excluída com sucesso",

    // databaselocator
    databaseLocatorSuccessMessage: "A sincronização do localizador de banco de dados foi enfileirada",

    // documents module
    docListHeaderDocId: "ID do documento",
    docListHeaderDocVersion: "Versão",
    docListHeaderDocName: "Nome do Documento",
    docListHeaderFileSize: "Tamanho do arquivo",
    docListHeaderContentUri: "URI de conteúdo",
    docListHeaderFileExists: "O arquivo existe",
    docListNoResults: "Nenhum documento encontrado",
    docListTotalDocuments: "Documentos totais:",
    docListDocIdSearchLabel: "ID do documento",
    docListExportBtn: "Exportar",

    // Internal user roles abbr
    picRole: "PIC",
    adminRole: "Admin",
    readOnlyRoleForThirdPartyUser: "Somente leitura",
    legacy: "Irrestrito",
    permissionLabel: "Permissão",
    permission: "Permissões",
    active: "Ativo",
    inactive: "Inativo",
    EngagementUserStatus: "Status",
    IndependenceStatus: "Independência",
    Confidentiality: "Confidencialidade",
    EditUserPermissionsMainTitle: "Você pode editar suas permissões",
    EditUserPermissionsInstructions: "Ele sempre estará editando as permissões de outras pessoas que não são suas.",
    PermissionSaveButtonText: "Salvar",
    PermissionCancelButtonText: "Cancelar",
    UnknownCountry: "Desconhecido",
    engagementNoPermissionMesage: "Você tem permissões insuficientes para o módulo de engagement.",
    cacheRemoveSuccessMessage: "Cache foi removido com sucesso",
    cacheKeyNotFound: "Nenhum resultado encontrado para a chave de cache",

    // Portfolio labels
	informationText: "Bem vindo de volta,",
		filterEngagements: {
	all: "Todos os projetos",
		favorite: "Projetos favoritos",
			review: "Revisar projetos"
},
    filterEngagementsLabel: "Filtrar projetos",
    engv2noActiveEngagements: "Você não tem nenhum projeto ativo. Clique no botão 'Adicionar projeto' para começar.",
    engv2noFavoriteEngagements: "Você não tem projetos favoritos. Para adicionar projetos a esta visualização, navegue até Todos os projetos e clique no ícone de favoritos no canto inferior direito dos cartões de projeto.",
    engv2noReviewEngagements: "Você não tem nenhum projeto de revisão. Entre em contato com um membro da equipe de trabalho se precisar de acesso.",

    // Engagement Progress Labels
    engagementProgressStrategyPhaseLabel: "Fase de escopo e estratégia",
    engagementProgressExecutionPhaseLabel: "Fase de execução",
    engagementProgressConclusionPhaseLabel: "Fase de conclusão",
    engagementProgressCompletionPercentage: "completar",
    engagementProgressCompleted: "Completar",
    engagementProgressNotAvailable: "O progresso do projeto estará disponível quando seu datacenter local for atualizado",

    // Engagement archive countdown indicator
    EngagementArchiveCountdownIndicator: {
        reportreleasedate: "A data de lançamento do relatório é <b>{0}</b>",
        archivedeadlinedate: "A data do prazo para arquivamento é <b>{0}</b>",
        today: "Hoje",
        tommorow: "amanhã",
        overdue: "atrasado",
        reportreleasedays: "<b>{0} dias</b> até a data de lançamento do Relatório",
		archivedeadlinedays: "<b>{0} dias</b> até a data do prazo para arquivamento"
    },
    postArchive: "Após o arquivamento",

    adminTool: "Ferramenta de administração",
    characters: "caracteres",
    placeholder: "Espaço reservado",
    ofLabel: "do",
    kendoTextLimitMessage: "Limite máximo de caracteres excedido",
    kendoRequiredMessage: "Requerido",
    engv2Errors: {
	noReviewEngagementsPermission: "Você não tem permissão para revisar projetos. Entre em contato com um membro da equipe de trabalho se precisar de acesso."
    },
    timeTracking: 'Time Tracker',
    timeTrackingToolMessage: "Esta ferramenta opcional foi criada especificamente para ajudá-lo a gerenciar melhor seu tempo enquanto trabalha em seus compromissos.",
    timeTrackingToolMessage1: "Depois de iniciar o registro do tempo, você pode encontrar um resumo dos dados coletados por até 14 dias. Dados com mais de 14 dias serão removidos automaticamente.",
    timeTrackingToolUpdatedMessage: "Esta ferramenta opcional ajuda a registrar como você gasta seu tempo. O tempo registrado é retido por no máximo duas semanas e não será arquivado.",
    timeTrackerErrorFallBackMessage: "O resumo de tempo não pode ser carregado. Atualize a página e tente novamente. Se o problema persistir, entre em contato com o Help Desk.",
    serviceGateway: "Porta de entrada de serviço",
    reportinghubDisableMessage: "O Spotfire será desativado em junho de 2024. Os novos relatórios do EY Canvas podem ser acessados ​​a partir do seu engagement no EY Canvas. Após 2024 Lançamento 1, ele poderá ser acessado no menu Mais. Certifique-se de atualizar quaisquer relatórios personalizados ou automações que dependam desses relatórios para a nova versão antes de junho de 2024.",
	mobileVersionLabels: {
        canvas: "Canvas",
        noEngagementFound: "Você não tem nenhum engagement ativo.</br></br>Acesse o Canvas no seu computador para começar.",
        searchEngagementPlaceholder: "Procurar....",
        search: "Procurar",
        independenceIncomplete: "Independência Incompleta",
        profileIncomplete: "Perfil incompleto",
        allEngagements: "Todos os engagements",
        noMatchesFound: "Nenhuma correspondência encontrada</br></br>Por favor tente novamente",
        inValidUserLogin: "Acesse seu computador para completar as configurações do usuário",
		pagination: {
            pageLabel: "Página",
            ofLabel: "de"
        }
    },
    independenceOnlyStatus: 'Independence only',
    // Adobe Analytics
    aaCookieConsentTitle: 'Welcome to',
    aaCookieContentPrompt: 'Do you want to allow cookies?',
    aaCookieConsentExplanation: '<p>In addition to cookies that are strictly necessary to operate this website, we use the following types of cookies to improve your experience and our services: <strong>Functional cookies</strong> to enhance your experience (e.g. remember settings), <strong>Performance cookies</strong> to measure the website\'s performance and improve your experience, <strong>Advertising/Targeting cookies</strong>, which are set by third parties with whom we execute advertising campaigns and allow us to provide you with advertisements relevant to you.</p><p>Review our <a target="_blank" href="https://www.ey.com/en_us/cookie-policy">cookie policy</a> for more information.</p>',
    aaCookieConsentExplanationWithDoNotTrack: '<p>In addition to cookies that are strictly necessary to operate this website, we use the following types of cookies to improve your experience and our services: <strong>Functional cookies</strong> to enhance your experience (e.g. remember settings), <strong>Performance cookies</strong> to measure the website\'s performance and improve your experience, <strong>Advertising/Targeting cookies</strong>, which are set by third parties with whom we execute advertising campaigns and allow us to provide you with advertisements relevant to you.</p><p>We have detected that you have enabled the Do Not Track setting in your browser; as a result, Advertising/Targeting cookies are automatically disabled.</p><p>Review our <a target="_blank" href="https://www.ey.com/en_us/cookie-policy">cookie policy</a> for more information.</p>',
    aaCookieConsentDeclineOptionalAction: 'I decline optional cookies',
    aaCookieConsentAcceptAllAction: 'I accept all cookies',
    aaCookieConsentCustomizeAction: 'Customize cookies',
    aaCookieConsentCustomizeURL: 'https://www.ey.com/en_us/cookie-settings',

    // Cookie Settings
    cookieSettings: {
        title: 'Cookie Settings',
        explanation: 'Please provide your consent for cookie usage on ey.com and the My EY platform. Select one or more of the cookie types listed below, and then save your selection(s). Refer to the listing below for details on the types of cookies and their purpose.',
        emptyCookieListNotice: 'Cookies from this category are not used in this app',
        nameTableHeader: 'Name of cookie',
        providerTableHeader: 'Cookie provider',
        purposeTableHeader: 'Purpose of cookie',
        typeTableHeader: 'Type of cookie',
        durationTableHeader: 'Duration of cookie',
        formSubmit: 'Save my selection',
        requiredCookieListTitle: 'Required Cookies',
        functionalCookieListTitle: 'Functional Cookies',
        functionalCookieAcceptance: 'I accept the functional cookies below',
        functionalCookieExplanation: 'Functionality cookies, which allow us to enhance your experience (for example by remembering any settings you may have selected).',
        performanceCookieListTitle: 'Performance Cookies',
        performanceCookieAcceptance: 'I accept the performance cookies below',
        performanceCookieExplanation: 'Performance cookies, which help us measure the website’s performance and improve your experience. In using performance cookies we do not store any personal data, and only use the information collected through these cookies in aggregated and anonymised form.',
        advertisingCookieListTitle: 'Targeting Cookies',
        advertisingCookieAcceptance: 'I accept the advertising/targeting cookies below',
        advertisingCookieExplanation: 'Advertising/targeting cookies, which we use to track user activity and sessions so that we can deliver a more personalized service, and (in the case of advertising cookies) which are set by the third parties with whom we execute advertising campaigns and allow us to provide advertisements relevant to you.',
        doNotTrackNotice: 'We have detected that you have enabled the Do Not Track setting in your browser; as a result, advertising/targeting cookies are automatically disabled.'
     },
};

export const errors = {
    // GenericErrors Codes
    '0005': "Transação pendente",
    '0006': "Falha na transação",
    '0008': "A descrição não pode conter nenhum dos seguintes caracteres: \\",
    '0010': "Falha na chamada do ARC",

    0: "Falha na solicitação",
    928: "Acesso não autorizado - Você não tem acesso à opção selecionada. Entre em contato com o Suporte Técnico para obter instruções sobre como obter acesso, se autorizado.",

    1009: "Servidor de dados não encontrado",
    1010: "Centro de dados não encontrado",
    1011: "O centro de dados deste país não foi configurado",
    1013: "ID do país ou ID do engagement não encontrado",
    1014: "Centro de dados do país não encontrado",
    1016: "Falha no mapeamento do localizador do banco de dados",
    1017: "oConsulta de dados não pode ser nula ou vazia",
    1018: "oConsulta de dados não contém nenhum campo",
    1019: "Centro de dados universal não encontrado",
    1020: "O usuário não existe no sistema",
    1021: "Os relatórios não estão disponíveis no local selecionado. Entre em contato com o seu Champion local do EY Canvas para obter mais detalhes.",

    // Scope & Strategy 1021 - 1200
    1026: "Linha não encontrada",
    1030: "Nome do cliente não encontrado",

    // Restore Engagement
    1101: "Incapaz de restaurar o engagement com o país selecionado. Atualize a página e tente novamente",

    // Execution   1201-1400
    1219: "Método não encontrado",
    1234: "O ID da função não pode ser nulo ou vazio",
    1260: "O item em que você está trabalhando foi excluído por outro usuário.",
    1300: "Solicitação do objeto inválido",
    1301: "Função de engagement inválida",
    1302: "Idioma do usuário inválido",
    1303: "Detalhes do usuário do engagement não encontrados",
    1304: "Localização inválida",
    1305: "Nome do engagement não encontrado",
    1306: "O nome do engagement excede o tamanho máximo (255 caracteres)",
    1307: "Data final do período coberto não encontrada",
    1308: "Data inválida",
    1309: "Data do relatório dos auditores não encontrada,",
    1310: "Data limite do arquivamento do engagement não encontrada",
    1311: "A data final do prazo de arquivamento do engagement não pode ser anterior à data de hoje,",
    1312: "O engagement tem códigos de engagements duplicados",
    1313: "Local não encontrado",
    1314: "Nome do espaço de trabalho não encontrado",
    1315: "O nome do espaço de trabalho excede o tamanho máximo (255 caracteres)",
    1316: "O código do cliente excede o tamanho máximo (50 caracteres)",
    1317: "O nome do cliente excede o tamanho máximo (255 caracteres)",
    1318: "Referência incorreta na categoria para indicar se o worskpace é somente para treinamento",
    1319: "Referência para indicar se o workspace é somente para treinamento não encontrada",
    1320: "Referência para indicar se o código do cliente é válido não encontrada",
    1321: "Referência incorreta para indicar se o código do cliente está validado",
    1322: "ID do cliente inválido",
    1323: "Códigos do engagement não encontrados",
    1324: "ID do engagement não encontrado",
    1325: "ID do escritório principal inválido",
    1052: "ID da linha de serviço inválido",
    1053: "ID da linha de serviço secundário inválido",
    1326: "ID do país não encontrado",
    1327: "ID de espaço de trabalho inválido",
    1328: "Status do Membro da Equipe do Engagement inválido",
    1329: "Status do engagement inválido",
    1330: "O engagement já foi excluído por outro membro da equipe",
    1331: "Data de encerramento do exercício não encontrada",
    1339: "Excedido o número máximo de engagements permitidos",
    1340: "Detalhes do local não encontrados",
    1341: "Dados do engagement para atualização não recebidos corretamente",
    1342: "Nenhum detalhe do engagement encontrado para o engagement atual",
    1343: "Não foram encontrados dados de resumo do engagement para o engagement atual",
    1344: "O engagement não existe para o ID selecionado",
    1359: "O ID do servidor de dados de um dos engagements ativos é inválido",
    1363: "A criação do Engagement falhou na Universal",
    1364: "A criação do Engagement falhou no LDC",
    1373: "Selecione um espaço de trabalho existente ou crie um novo espaço de trabalho",
    1374: "O nome do engagement já existe. Digite um novo nome de engagement para importar o modelo.",
    1379: "O conteúdo está sendo atualizado. Tente de novo mais tarde. Se o problema persistir durante o tempo prolongado contate o IT Help Desk.",
    1380: "A repetição do arquivamento não pôde ser concluída. Atualize a página e tente novamente. Se o problema persistir, entre em contato com o Help Desk.",
    1381: "Criar / RF / RS  engagement falhou, devido ao erro de correspondência no ID do País  do Workspace e Engagement.",
    1382: "Criar / RF / RS engagement falhou, devido ao erro de correspondência no escritório principal em Workspace e Engagement.",
    1384: "Restaurar ou avançar já está em andamento para este projeto. Por favor, tente novamente mais tarde.",
    1386: "O espaço de trabalho não está qualificado para novos engagements. Atualize a página e tente novamente. Se o problema persistir, entre em contato com o Help Desk.",
    1385: "O engagmeent não pode ser criado no local selecionado. Atualize a página e tente novamente. Se o problema persistir, entre em contato com o Help Desk.",
    // Invite Team Members (1401 - 1500)
    1403: "A mensagem de boas-vindas do engagement excede o tamanho máximo (200 caracteres)",
    1406: "Nenhuma coleção de engagement encontrada",
    1424: "Detalhes do engagement ou do usuário não encontrados",
    1425: "Detalhes do engagement não encontrados",

    // User details Error Codes (1426 - 1434)
    1426: "O primeiro nome é requerido",
    1427: "O sobrenome é requerido",
    1428: "Iniciais são requeridas",
    1429: "O escritório principal é requerido",
    1430: "É requerido um número de telefone preferido",
    1431: "O idioma é requerido",
    1432: "É requerido o formato de data preferido",
    1433: "É requerido um formato de número preferido",
    1434: "Os detalhes do usuário não podem ser nulos ou vazios",
    1436: "Conflito detectado. Se o problema persistir, entre em contato com o Suporte Técnico.",
    1438: "A coleção de usuários não pode estar vazia.",
    1448: "O país selecionado não foi atualizado para a versão mais recente do EY Canvas. Consulte o EY Atlas para obter o cronograma de implantação e tente novamente após a atualização. Se o problema persistir, entre em contato com o Help Desk.",

    1502: "Não foi possível concluir a operação no momento. Atualize a página e tente novamente. Se o problema persistir, entre em contato com o Suporte Técnico.",
    1504: "Não foi possível concluir a operação no momento. Atualize a página e tente novamente. Se o problema persistir, entre em contato com o Suporte Técnico.",
    1505: "Entrada inválida",

    // Common control errors (1601 - 1700)
    1601: "Porcentagem inválida",
    18000: "Erro de processamento do ARC: A operação solicitada não pode ser executada, pois o ARC está em manutenção. Por favor tente mais tarde.",
    18306: "Erro de processamento do ARC: Não foi possível encontrar o formulário ARC relacionado ao Canvas ArchivalGUID",
    18307: "Erro de processamento do ARC: o engagement foi excluído no ARC. Você não pode continuar com a opção selecionada. Entre em contato com o administrador do ARC se precisar de mais assistência.",
    18309: "Erro de processamento do ARC: a operação solicitada não pode ser executada, pois o engagement arquivado está sob restrições regulamentares. Entre em contato com o administrador do ARC para obter mais informações.",
    18320: "Erro de processamento do ARC: o formulário do ARC está no status de 'Espera' (suspenso) e não pode ser modificado.",
    18327: "Erro de processamento do ARC: o formulário do ARC está no status de 'Espera' para o status 'Excluir' e não pode ser modificado.",
    18328: "Erro de processamento do ARC: a operação solicitada não pode ser executada, pois o engagement está selecionado para exclusão no ARC. Entre em contato com o Administrador do ARC para obter mais informações",
    18329: "Erro de processamento do ARC: este engagement não está acessível aos parceiros. Entre em contato com o administrador do ARC para obter mais informações.",
    18330: "Erro de processamento do ARC: a operação solicitada não pode ser executada, pois o engagement está bloqueado devido a restrições regulatórias. Entre em contato com o Administrador do ARC para obter mais informações.",
    2027: "Solicitação incorreta",
    2046: "Não autorizado",
    2047: "Proibido",
    2048: "Registro não encontrado",
    2049: "Solicitação da entidade muito grande",
    2052: "Não aceitável",
    2053: "Ocorreu um erro ao recuperar os dados. Atualize a página e tente novamente. Se o problema persistir, entre em contato com o Suporte Técnico.",
    2056: "Ocorreu um erro genérico",
    2057: "Serviço indisponível",

    // Roll Forward Error Codes (2301-2400)
    2301: "Durante o roll forward, o documento arquivado não foi encontrado",
    2302: "Durante o roll forward, a chamada de referência de arquivamento falhou",
    2303: "Durante o rollforward, o documento arquivado retornado possui um identificador exclusivo vazio",
    2304: "Durante o rollforward, o documento arquivado retornado possui um URL de armazenamento de arquivo vazio",
    2305: "Durante o rollforward, o documento arquivado retornado possui um valor de hash vazio",
    2306: "O usuário não está autorizado a executar esta ação",
    2307: "O número permitido de cópias restauradas foi excedido",

    // Link Error Codes
    2568: "O link não está mais disponível, pois você não é o ponto de contato atual. Atualize a página.",
    3005: "A equipe principal já excluiu o componente do engagement de grupo",
    2571: "A solicitação do link foi atualizada. Atualize a página.",

    // Error for v1 engagement not supported features
    4001: "O Engagement selecionado foi criado em uma versão mais antiga do EY Canvas e não pode ser restaurado até que o servidor de dado local dos locais selecionados tenha sido atualizado para a versão mais atual do EY Canvas. Entre em contato com o Suporte local para obter mais informações.",
    4002: "O Engagement selecionado foi criado em uma versão mais antiga do EY Canvas e o acesso à solicitação não está disponível até que o servidor de dado local tenha sido atualizado para a versão mais recente. Entre em contato com o Suporte local para obter mais informações.",
    4214: "O modelo não pode ser importado para um espaço de trabalho no local selecionado. Selecione um local diferente e tente novamente",

    // Common framework error
    4205: "Detalhes da solicitação de cópia não foram  encontrados",
    401000: "A alteração da rede foi detectada e por favor atualize a página para continuar",

    // CopyHub concurrency scenarios
    4217: "O status da solicitação do pacote de dados foi alterado. Atualize a página e tente novamente.",
    4219: "Cópias entre diferentes versões do EY Canvas não são suportadas nesta versão devido a melhorias feitas no processo de cópia. Revise o Atlas EY para obter detalhes sobre o cronograma de implantação. Depois que seu data center for atualizado, uma cópia pode ser feita.",
    4220: "A versão do modelo não é compatível com este país.",
    4221: "A versão do modelo não é compatível com este país.",
    4222: "A importação não pode ser concluída até que o local de destino tenha sido atualizado para a versão mais recente do EY Canvas. Selecione outro local para continuar. Entre em contato com o Help Desk se o problema persistir.",

    // Error for Mobility API
    4501: "Nenhuma tarefa está disponível no Engagement",
    4502: "Não há tarefas pendentes disponíveis no Engagement",

    // Counrty API version error
    5001: "O local selecionado possui uma versão mais antiga do EY Canvas e um engagement não pode ser encaminhado para um novo espaço de trabalho até que o servidor de dados local dos locais selecionados tenham sido atualizados para a versão mais atual do EY Canvas. Entre em contato com o Suporte local para obter mais informações,",

    //CRP checks
    5101: "A cópia da revisão não pode ser criada para este engagement porque o Portal de acesso externo do Canvas não está disponível no momento. Contate o help desk para maiores informações. ",
    5102: "A cópia do CRP já está em andamento para este engagement. Por favor, tente novamente mais tarde.",
    5103: "A cópia da revisão não pode ser editada para este engagement porque o Portal de acesso externo do Canvas não está disponível no momento. Contate o help desk para mais informações. ",
    5104: "A restrição do documento não está disponível para este engagement. Desmarque a opção e tente novamente.",

    18100: "Linha de serviço não encontrada.",
    18101: "Sub-linha de serviço não encontrada.",

    //RBAC realted error messages.
    935: "Permissões insuficientes para realizar esta ação. Entre em contato com um administrador de engagement  e solicite permissão para realizar esta ação.",
    8001: "Não é possível completar a operação neste momento. Atualize a página e tente novamente. Se o problema persistir, entre em contato com o Help Desk.",
    336699: "O resumo de tempo não pode ser carregado. Atualize a página e tente novamente. Se o problema persistir, entre em contato com o Help Desk.",

    //Concurrency errors
    7821: "Você já respondeu a este convite, atualize a página e tente novamente."
};

export const adminErrors = {
    1332: "Erro ao acionar a repetição do arquivamento",
    1501: "O usuário está inativo. Atualize página e tentar novamente",
    156: "Permissões insuficientes para este módulo",
    70740: "As alterações não podem ser salvas. O projeto deve ter pelo menos um membro ativo que tenha permissões para administrar o projeto e tenha decidido a independência. Se o problema persistir, entre em contato com o Help Desk.",
    70790: "O domínio não é permitido. Tente novamente e se o problema persistir, entre em contato com o Help Desk.",
    70800: "Usuário inválido para convidar Usuário de terceiros.",
    70810: "Funções RBAC inválidas/inativas selecionadas para o usuário de terceiros.",
    70820: "Selecione a função RBAC válida para o usuário de terceiros.",
	70830: "Selecione a função somente leitura para usuário de terceiros."
};

export const actionTypes = {
    1: "Mensagens do sistema",
    2: "Convites de engagement",
    3: "Acesso negado",
    4: "Solicitações de exclusão do engagement",
    5: "Erro ao criar o engagement",
    6: "Solicitação de acesso ao engagement",
    7: "Engagements excluídos",
    8: "Engagements arquivados",
    9: "Engagements desativados",
    10: "Substituição da data do prazo final do archive",
    11: "Solicitações do link do grupo",
    12: "Instruções do grupo recebidas",
    13: "Cópias recebidas",
    14: "Engagement em andamento"
};
export const timephaseType = {
    '-1': "Data final do período coberto",
    '-2': "Data do relatório",
    '-3': "Data do prazo final de arquivamento",
    '1': "Escopo",
    '2': "Estratégia",
    '3': "Walkthroughs  ",
    '4': "Teste de Controle interino",
    '5': "Testes Substantivos Interinos",
    '6': "Walkthroughs (processos de final de ano)",
    '7': "Teste de Controle de Final de Ano",
    '8': "Teste Substantivo de Final de Ano",
    '9': "Conclusão",
    '10': "Não Aplicável",
    '11': "Execução",
    '12': "Atividades de qualidade de escopo e estratégia",
    '13': "Outro escopo e estratégia",
    '14': "CRA",
    '15': "Avaliação de Risco e Materialidade",
    '16': "Finalizar escopo e estratégia",
    '17': "Gerenciamento de projetos realizados em data interina"
};
export const dataExport = [
	"Formulário de Archive",
	"Formulário do Canvas",
	"Colaboração",
	"Formulários de Controle",
	"Documentos",
	"Tarefas",
	"Membros da equipe",
	"Balancete"
];
export const dataExportReportNames = {
	'Formulário de Archive': 'Archive Form',
	'Formulário do Canvas': 'Canvas Form Report',
	'Colaboração': 'Collaboration',
	'Formulários de Controle': 'Control Report',
	'Documentos': 'All Documents Report',
	'Tarefas': 'All Tasks Report',
	'Membros da equipe': 'Team Members',
	'Balancete': 'Numbers Report'
};
export const projectManagement = [
	"Detalhes de colaboração",
	"Placar digital",
	"Milestones",
	"Notas de revisão",
	"Verificações de validação"
];
export const projectManagementReportNames = {
	'Detalhes de colaboração': 'Client Collaboration Dashboard',
	'Milestones': 'Milestone Report',
	'Notas de revisão': 'Review Notes Report',
	'Verificações de validação': 'Validation Checks Report'
};
export const summaryScreenDefaults = [
	"Relatório do Plano de Auditoria",
	"Plano de auditoria com evidências relacionadas",
	"Ferramenta de revisão de controles (CRT)",
	"Resumo do CRA",
	"Findings",
	"Resumo de aplicações de TI e SO ",
	"Resumo da SCOT",
	"SEM"
];
export const summaryScreenDefaultsReportNames = {
	'Relatório do Plano de Auditoria': 'Audit Plan Report',
	'Plano de auditoria com evidências relacionadas': 'Audit Plan with Related Evidence',
	'Ferramenta de revisão de controles (CRT)': 'Controls Review Tool (CRT)',
	'Resumo do CRA': 'CRA Summary',
	'Findings': 'Findings',
	'Resumo de aplicações de TI e SO ': 'ITSO CRT',
	'Resumo da SCOT': 'SCOT Summary',
	'SEM': 'SEM'
};
export const summaryScreenInterim = [
	"Relatório do Plano de Trabalho",
	"Plano de Trabalho com Evidência Relacionada"
];
export const summaryScreenInterimReportNames = {
	'Relatório do Plano de Trabalho': 'Audit Plan Report',
	'Plano de Trabalho com Evidência Relacionada': 'Audit Plan with Related Evidence'
};
export const summaryScreenGroupAudit = [
	"Relatório do Plano de Auditoria",
	"Plano de auditoria com evidências relacionadas",
	"Ferramenta de revisão de controles (CRT)",
	"Resumo do CRA",
	"Findings",
	"Resumo das Instruções de Grupo",
	"Relatório do Grupo",
	"Resumo da Estrutura de Grupo",
	"Resumo de aplicações de TI e SO ",
	"Resumo da SCOT",
	"SEM"
];
export const summaryScreenGroupAuditReportNames = {
	'Relatório do Plano de Auditoria': 'Audit Plan Report',
	'Plano de auditoria com evidências relacionadas': 'Audit Plan with Related Evidence',
	'Ferramenta de revisão de controles (CRT)': 'Controls Review Tool (CRT)',
	'Resumo do CRA': 'CRA Summary',
	'Findings': 'Findings',
	'Resumo das Instruções de Grupo': 'Group Instruction Summary',
	'Relatório do Grupo': 'Group Report',
	'Resumo da Estrutura de Grupo': 'Group Structure Summary',
	'Resumo de aplicações de TI e SO ': 'ITSO CRT',
	'Resumo da SCOT': 'SCOT Summary',
	'SEM': 'SEM'
};
export const summaryScreenGroupAuditInterim = [
	"Resumo das Instruções de Grupo",
	"Resumo da Estrutura de Grupo",
	"Relatório do Plano de Trabalho",
	"Plano de Trabalho com Evidência Relacionada"
];
export const summaryScreenGroupAuditInterimReportNames = {
	'Resumo das Instruções de Grupo': 'Group Instruction Summary',
	'Resumo da Estrutura de Grupo': 'Group Structure Summary',
	'Relatório do Plano de Trabalho': 'Audit Plan Report',
	'Plano de Trabalho com Evidência Relacionada': 'Audit Plan with Related Evidence'
};
export const rolelistitems = [{
        id: 8,
        label: "Funcionários"
    },
    {
        id: 7,
        label: "Senior"
    },
    {
        id: 6,
        label: "Gerente"
    },
    {
        id: 5,
        label: "Gerente Sênior"
    },
    {
        id: 3,
        label: "Diretor Executivo"
    },
    {
        id: 4,
        label: "Diretor"
    },
    {
        id: 11,
        label: "Outro Sócio"
    },
    {
        id: 2,
        label: "Sócio do Engagement"
    },
    {
        id: 1,
        label: "Sócio Responsável do Engagement"
    },
    {
        id: 10,
        label: "Revisor de Qualidade do Engagement"
    },
    {
        id: 17,
        label: "Revisor de Qualidade de Auditoria"
    },
    {
        id: 16,
        label: "Conselho Geral"
    },
    {
        id: 12,
        label: "GDS - Staff"
    },
    {
        id: 30,
        label: "GDS - Sênior"
    },
    {
        id: 31,
        label: "GDS - Gerente"
    }
];
export const roleType = {
    '11': "Outro Sócio",
    '24': "Outro (por exemplo, revisão de due diligence)",
    '15': "Serviços de Suporte Executivo",
    '18': "Equipe de Componentes de ML",
    '17': "Revisor da Qualidade de Auditoria",
    '1': "Sócio Responsável",
    '2': "Sócio do Engagement",
    '9': "Intern",
    '13': "Advisory (FAIT, TAS, Recursos Humanos ou Outro)",
    '14': "Imposto",
    '27': "Indústria",
    '10': "Revisor de Qualidade do Engagement",
    '25': "Escritório",
    '8': "Funcionários",
    '20': "Equipe do cliente",
    '19': "Supervisor de Clientes",
    '5': "Gerente Sênior",
    '16': "Conselho Geral",
    '26': "Área",
    '23': "Regulador",
    '22': "Equipe de Auditoria Interna",
    '3': "Diretor executivo",
    '21': "Supervisor de Auditoria Interna",
    '6': "Gerente",
    '4': "Diretor",
    '7': "Sênior",
    '12': "Talento Global de Hub",
    '28': "Nacional",
    '29': "Global",
    '12': "GDS - Staff",
    '30': "GDS - Sênior",
    '31': "GDS - Gerente"
};

export const copyObjectGroupType = {
    1: "Engagament",
    2: "Contas",
    3: "SCOTs",
    4: "Aplicativos de TI e Organização de Serviços",
    5: "Solicitações ",
    6: "Evidência",
    7: "Números",
    8: "Solicitações e Tarefas Externas",
    9: "Arquivos temporários",
    10: "Evidência do Período Anterior",
    11: "Arquivos Excluídos Recentemente",
    12: "Históricos de Arquivos",
    13: "Modelo de Engagement",
    14: "Restaurar cópia do GCO",
    15: "Cópia de Revisão Externa",
    16: "Revisão externa do Engagement em andamento",
    17: "Formulários do Canvas",
    20: "Evidência",
    21: "Engagement",
    22: "Contas",
    23: "Scots",
    24: "Roll forward do Engagement",
    27: "Evidências Externas",
    28: "Fluxos de trabalho guiados"
};

export const EngagementStatusText = [
	"Indefinido",
	"Ativo",
	"Suspenso",
	"Arquivado",
	"Excluído",
	"Arquivamento em Andamento",
	"Reativado",
	"Restaurado",
	"Selecionado para exclusão",
	"Erro ao arquivar",
	"Erro do Roll forward",
	"Erro ao reativar",
	"Erro ao restaurar",
	"RollForward em andamento",
	"Reativação em andamento",
	"Restaurar em andamento",
	"Migração GAMX em andamento",
	"Erro da Migração GAMX",
	"Criação da Transação pendente",
	"Falha na criação da transação ",
	"Transação atualizada pendente",
	"Falha ao atualizar a transação",
	"Cópia em Andamento",
	"Erro na cópia",
	"Erro no modelo da cópia",
	"Revisão Externa do Engagement em Andamento",
	"Erro na Revisão Externa do Engagement",
	"Revisão externa do engagement"
];
export const overdueIssues = {
    partnerInvitationPending: "Convite para o Sócio Pendente",
    eqrInvitationPending: "Convite para o EQR Pendente",
    invalidEngagementCode: "Código de engagement inválido",
    overdueArchiveDeadline: "Prazo da data de arquivamento atrasado",
    upcomingArchiveDeadline: "Próximo prazo para arquivamento",
    contentDeliveryInProgress: "Entrega de conteúdo em andamento",
    overdueMilestones: "Milestones da equipe vencidos",
    overduePartnerTasks: "Tarefas do sócio em atraso",
    overdueEqrTasks: "Tarefas do EQR em atraso"
};
export const engagementMetricNames = [{
        propName: 'preparer',
        value: "Preparar"
    },
    {
        propName: 'forMyReview',
        value: "Revisar"
    },
    {
        propName: 'upcomingReview',
        value: "Revisão futura"
    },
    {
        propName: 'reviewNotesAssigned',
        value: "Comentários para mim"
    },
    {
        propName: 'reviewNotesAuthored',
        value: "Meus comentários"
    },
    {
        propName: 'clientRequests',
        value: "solicitações de"
    },
    {
        propName: 'timephases',
        value: "Fases de tempo"
    },
    {
        propName: 'groupTasks',
        value: "Tarefas de grupo"
    },
    {
        propName: 'groupDeliverables',
        value: "Resultados do grupo"
    },
    {
        propName: 'automationDocuments',
        value: "Automação"
    }
];
export const metricFilterComingDue = [{
        value: 1,
        label: "À vencer amanhã"
    },
    {
        value: 3,
        label: "À vencer nos próximos 3 dias"
    },
    {
        value: 5,
        label: "À vencer nos próximos 5 dias",
        isDefault: true
    },
    {
        value: 10,
        label: "À vencer nos próximos 10 dias"
    },
    {
        value: 30,
        label: "À vencer nos próximos 30 dias",
        isMax: true
    }
];
export const metricFilterWhatsNew = [{
        value: 1,
        label: "O que há de novo desde ontem"
    },
    {
        value: 3,
        label: "O que há de novo nos últimos 3 dias"
    },
    {
        value: 5,
        label: "O que há de novo nos últimos 5 dias",
        isDefault: true
    },
    {
        value: 10,
        label: "O que há de novo nos últimos 10 dias",
        isMax: true
    }
];

export const type1Alerts = [
    "hasPartnerInvited",
    "hasEqrInvited",
    "hasValidEngagementCode",
    "overdueArchiveDeadline"
];

export const engagementStatuses = {
    1: "Ativo",
    2: "Suspenso",
    3: "Arquivado",
    4: "Excluído",
    5: "Arquivamento em andamento",
    6: "Reativado",
    7: "Restaurado",
    8: "Selecionado para exclusão",
    9: "Erro no arquivamento",
    10: "Erro no Roll Forward",
    11: "Erro ao reativar",
    12: "Erro ao restaurar",
    13: "Roll forward em andamento",
    14: "Reativação em andamento",
    15: "Restauração em andamento",
    16: "Migração GAMX em andamento",
    17: "Erro de migração GAMX",
    18: "Criar transação pendente",
    19: "Falha na criação da transação",
    20: "Atualizar transação pendente",
    21: "Falha na transação de atualização",
    22: "Cópia em andamento",
    23: "Erro de cópia",
    24: "Erro do modelo de cópia ",
    25: "Revisão externa do engagement andamento",
    26: "Erro na revisão externa do engagement ",
    27: "Revisão externa do engagement",
    28: "Localizado no servidor Universal ",
    29: "Localizada no servidor Universal  Arquivada",
    30: "Entrega de conteúdo em andamento",
    31: "Erro de entrega de conteúdo",
    32: "Falha na entrega do conteúdo",
    33: "Portal de acesso externo Canvas V2",
    34: "Portal de acesso externo Canvas V2 em andamento",
    35: "Erro do Portal de acesso externo Canvas V2",
    100: "Divisão de data center"
};

export const overrideArchiveDeadlineDateOptions = [{
        value: 0,
        label: "Padrão"
    },
    {
        value: 10,
        label: "10 dias"
    },
    {
        value: 14,
        label: "14 dias"
    },
    {
        value: 20,
        label: "20 dias"
    },
    {
        value: 21,
        label: "21 dias"
    },
    {
        value: 25,
        label: "25 dias"
    },
    {
        value: 30,
        label: "30 dias"
    },
    {
        value: 45,
        label: "45 dias"
    }
];
export const ExternalReviewCopyExpiryDays = [{
        value: 30,
        label: "30 dias"
    },
    {
        value: 60,
        label: "60 dias"
    },
    {
        value: 90,
        label: "90 dias"
    },
    {
        value: 180,
        label: "180 dias"
    }
];

export const serviceLines = [{
        servicelineid: 1,
        servicelinename: "Assurance"
    },
    {
        servicelineid: 3,
        servicelinename: "Estratégia e Transações"
    },
    {
        servicelineid: 2,
        servicelinename: "Consultoria"
    },
    {
        servicelineid: 4,
        servicelinename: "Tax"
    },
    {
        servicelineid: 5,
        servicelinename: "GCO"
    }
];

export const subServiceLines = [{
        subservicelineid: 1,
        servicelineid: 1,
        subservicelinename: "Auditaria"
    },
    {
        subservicelineid: 2,
        servicelineid: 1,
        subservicelinename: "FIDS"
    },
    {
        subservicelineid: 3,
        servicelineid: 2,
        subservicelinename: "ITRA"
    },
    {
        subservicelineid: 9,
        servicelineid: 1,
        subservicelinename: "FAAS"
    },
    {
        subservicelineid: 10,
        servicelineid: 1,
        subservicelinename: "ACR"
    },
    {
        subservicelineid: 11,
        servicelineid: 1,
        subservicelinename: "CCaSS"
    },
    {
        subservicelineid: 4,
        servicelineid: 3,
        subservicelinename: "Capital Transformation"
    },
    {
        subservicelineid: 5,
        servicelineid: 3,
        subservicelinename: "Transaction Support"
    },
    {
        subservicelineid: 6,
        servicelineid: 2,
        subservicelinename: "Performance Improvement"
    },
    {
        subservicelineid: 7,
        servicelineid: 2,
        subservicelinename: "Risk"
    },
    {
        subservicelineid: 7,
        servicelineid: 23,
        subservicelinename: "Business Consulting"
    },
    {
        subservicelineid: 24,
        servicelineid: 6,
        subservicelinename: "Outro"
    },
    {
        subservicelineid: 8,
        servicelineid: 2,
        subservicelinename: "Outro"
    },
    {
        subservicelineid: 12,
        servicelineid: 4,
        subservicelinename: "BTS"
    },
    {
        subservicelineid: 13,
        servicelineid: 4,
        subservicelinename: "Human Capital"
    },
    {
        subservicelineid: 14,
        servicelineid: 4,
        subservicelinename: "Law"
    },
    {
        subservicelineid: 15,
        servicelineid: 4,
        subservicelinename: "Indirect Tax"
    },
    {
        subservicelineid: 16,
        servicelineid: 4,
        subservicelinename: "GCR"
    },
    {
        subservicelineid: 17,
        servicelineid: 4,
        subservicelinename: "Transaction Tax"
    },
    {
        subservicelineid: 18,
        servicelineid: 4,
        subservicelinename: "ITS"
    },
    {
        subservicelineid: 19,
        servicelineid: 5,
        subservicelinename: "GCO"
    },
    {
        subservicelineid: 20,
        servicelineid: 2,
        subservicelinename: "Business Consulting"
    },
    {
        subservicelineid: 21,
        servicelineid: 2,
        subservicelinename: "Technology Consulting"
    },
    {
        subservicelineid: 22,
        servicelineid: 3,
        subservicelinename: "Transactions and Corporate Finance"
    },
    {
        subservicelineid: 23,
        servicelineid: 3,
        subservicelinename: "EY Parthenon"
    },
    {
        subservicelineid: 25,
        servicelineid: 1,
        subservicelinename: "Risco Tecnológico"
    }
];

export const modules = [{
        id: 1,
        name: "Gerenciar o acesso"
    },
    {
        id: 2,
        name: "Engagements"
    },
    {
        id: 3,
        name: "Grupos de permissão"
    },
    {
        id: 4,
        name: "Modelos"
    },
    {
        id: 5,
        name: "Mensagens"
    },
    {
        id: '6',
        name: "Cache"
    },
    {
        id: 7,
        name: "Localizador de banco de dados"
    },
    {
        id: 8,
        name: "Documentos"
    }
];

export const roles = [{
        id: 17,
        ModuleId: 1,
        name: "Somente leitura"
    },
    {
        id: 18,
        ModuleId: 1,
        name: "Adicionar/remover usuário e permissões"
    },
    {
        id: 19,
        ModuleId: 2,
        name: "Somente leitura"
    },
    {
        id: 20,
        ModuleId: 2,
        name: "Atualizar o engagement"
    },
    {
        id: 21,
        ModuleId: 2,
        name: "Convidar membro da equipe"
    },
    {
        id: 22,
        ModuleId: 2,
        name: "Atualizar usuário do engagement"
    },
    {
        id: 23,
        ModuleId: 3,
        name: "Somente leitura"
    },
    {
        id: 24,
        ModuleId: 3,
        name: "Adicionar/remover membro"
    },
    {
        id: 25,
        ModuleId: 3,
        name: "Sincronizar DatabaseLocator"
    },
    {
        id: 26,
        ModuleId: 4,
        name: "Somente leitura"
    },
    {
        id: 27,
        ModuleId: 4,
        name: "Modelo de atualização"
    },
    {
        id: 28,
        ModuleId: 4,
        name: "Excluir modelo"
    },
    {
        id: 29,
        ModuleId: 5,
        name: "Somente leitura"
    },
    {
        id: 30,
        ModuleId: 5,
        name: "Criar/atualizar mensagem"
    },
    {
        id: 31,
        ModuleId: 5,
        name: "Excluir mensagem"
    },
    {
        id: 32,
        ModuleId: 6,
        name: "Ler e Excluir cache"
    },
    {
        id: 33,
        ModuleId: 8,
        name: "Documentos de acesso"
    },
    {
        id: 34,
        ModuleId: 2,
        name: "Repetir arquivamento"
    }
];

export const countries = {
    1: "Maiote",
    2: "Ilhas Virgens Britânicas",
    3: "Espanha",
    4: "Belize",
    5: "Peru",
    6: "Eslováquia",
    7: "Venezuela",
    8: "Noruega",
    9: "Ilhas Falkland (Malvinas)",
    10: "Moçambique",
    11: "China continental",
    12: "Sudão",
    13: "Israel",
    14: "Bélgica",
    15: "Arábia Saudita",
    16: "Gibraltar",
    17: "Guam",
    18: "Ilhas Norfolk",
    19: "Zâmbia",
    20: "Reunião",
    21: "Azerbaijão",
    22: "Santa Helena",
    23: "Irã",
    24: "Mônaco",
    25: "Saint Pierre e Miquelon",
    26: "Nova Zelândia",
    27: "Ilhas Cook",
    28: "Santa Lúcia",
    29: "Zimbábue",
    30: "Iraque",
    31: "Tonga",
    32: "Samoa Americana",
    33: "Maldivas",
    34: "Marrocos",
    35: "Normas Internacionais sobre Auditoria (ISA)",
    36: "Albânia",
    37: "Afeganistão",
    38: "Gâmbia",
    39: "Burquina Faso",
    40: "Tokelau",
    41: "Líbia",
    42: "Canadá",
    43: "Emirados Árabes Unidos",
    44: "República dos Povos Democráticos da Coreia",
    45: "Montserrat",
    46: "Gronelândia",
    47: "Ruanda",
    48: "Fiji",
    49: "Djibuti",
    50: "Botsuana",
    51: "Kuaite",
    52: "Madagáscar",
    53: "Ilha do Homem",
    54: "Hungria",
    55: "Namíbia",
    56: "Malta",
    57: "Jersey",
    58: "Tailândia",
    59: "São Cristóvão e Nevis",
    60: "Butão",
    61: "Panamá",
    62: "Somália",
    63: "Bahrein",
    64: "Bósnia e Herzegovina",
    65: "França",
    66: "República da Coreia de",
    67: "Islândia",
    68: "Portugal",
    69: "Tunísia",
    70: "Gana",
    71: "Camarões",
    72: "Grécia",
    73: "Territórios do Sul francês",
    74: "Ilhas Heard e McDonald",
    75: "Andorra",
    76: "Luxemburgo",
    77: "Samoa",
    78: "Anguilla",
    79: "Países Baixos",
    80: "Guiné-Bissau",
    81: "Nicarágua",
    82: "Paraguai",
    83: "Antígua e Barbuda",
    84: "Padrão Internacional de Relatórios Financeiros (IFRS)",
    85: "Níger",
    86: "Egito",
    87: "Estado da Cidade do Vaticano",
    88: "Letônia",
    89: "Chipre",
    90: "Ilhas Periféis Menores dos EUA",
    91: "Rússia Outras Entidades Jurídicas",
    92: "São Vicente e Granadinas",
    93: "Guernsey",
    94: "Burundi",
    95: "Cuba",
    96: "Guiné Equatorial",
    97: "Território britânico do Oceano Índico",
    98: "Suécia",
    99: "Uganda",
    100: "Macedônia do Norte",
    101: "Suazilândia",
    102: "El Salvador",
    103: "Quirguistão",
    104: "Irlanda",
    105: "Cazaquistão",
    106: "Honduras",
    107: "Uruguai",
    108: "Geórgia",
    109: "Trinidad e Tobago",
    110: "Autoridade Palestina",
    111: "Martinica",
    112: "Ilhas Aland",
    113: "Polinésia Francesa",
    114: "Costa do Marfim",
    115: "Montenegro",
    116: "África do Sul",
    117: "Ilhas Geórgia do Sul e Sandwich do Sul",
    118: "Iêmen",
    119: "Hong Kong",
    120: "Quênia",
    121: "Chade",
    122: "Colômbia",
    123: "Costa Rica",
    124: "Angola",
    125: "Lituânia",
    126: "Síria",
    127: "Malásia",
    128: "Serra Leoa",
    129: "Sérvia e Montenegro",
    130: "Polônia",
    131: "Suriname",
    132: "Haiti",
    133: "Nauru",
    134: "São Tomé e Príncipe",
    135: "Svalbard e Jan Mayen",
    136: "Cingapura",
    137: "Moldávia",
    138: "Taiwan",
    139: "Senegal",
    140: "Gabão",
    141: "México",
    142: "Seicheles",
    143: "Estados federados da micronésia",
    144: "Argélia",
    145: "Itália",
    146: "San Marino",
    147: "Libéria",
    148: "Brasil",
    149: "Croácia",
    150: "Ilhas Faroés",
    151: "Palácio",
    152: "Finlândia",
    153: "Filipinas",
    154: "Jamaica",
    155: "Guiana Francesa",
    156: "Cabo Verde",
    157: "Myanmar",
    158: "Lesoto",
    159: "Ilhas Virgens Americanas",
    160: "Ilhas Cayman",
    161: "Niue",
    162: "Togo",
    163: "Belarus",
    164: "Domínica",
    165: "Indonésio",
    166: "Uzbequistão",
    167: "Nigéria",
    168: "Wallis e Futuna",
    169: "Barbados",
    170: "Sri Lanka",
    171: "Reino Unido",
    172: "Equador",
    173: "Guadalupe",
    174: "Laos",
    175: "Jordânia",
    176: "Ilhas Salomão",
    177: "Timor-Leste",
    178: "Líbano",
    179: "República Centro-Africana",
    180: "Índia",
    181: "Ilha Christmas",
    182: "Vanuatu",
    183: "Brunei",
    184: "Bangladesh",
    185: "Antártida",
    186: "Bolívia",
    187: "Turquia",
    188: "Bahamas",
    189: "Comores",
    190: "Saara Ocidental",
    191: "República Tcheca",
    192: "Ucrânia",
    193: "Estônia",
    194: "Bulgária",
    195: "Mauritânia",
    196: "Congo A República Democrática do",
    197: "Liechtenstein",
    198: "Pitcairn",
    199: "Dinamarca",
    200: "Ilhas Marshall",
    201: "Japão",
    202: "Áustria",
    203: "Omã",
    204: "Mongólia",
    205: "Tajiquistão",
    206: "Suíça",
    207: "Guatemala",
    208: "Eritreia",
    209: "Nepal",
    210: "Mali",
    211: "Eslovênia",
    212: "Ilhas Marianas do Norte",
    213: "(Não aplicável)",
    214: "Aruba",
    215: "Congo",
    216: "Catar",
    217: "Guiné",
    218: "Estados Unidos",
    219: "Etiópia",
    220: "Outro",
    221: "Guiana",
    222: "Alemanha",
    223: "Bermudas",
    224: "Ilhas Turks e Caicos",
    225: "Austrália",
    226: "Kiribati",
    227: "Porto Rico",
    228: "Paquistão",
    229: "Maurícia",
    230: "Malawi",
    231: "Turquemenistão",
    232: "Camboja",
    233: "Chile",
    234: "Nova Caledónia",
    235: "Papua-Nova Guiné",
    236: "Ilha Bouvet",
    237: "Tuvalu",
    238: "Curaçau",
    239: "República Dominicana",
    240: "Vietnã",
    241: "Ilhas Cocos (Keeling)",
    242: "Granada",
    243: "Tanzânia",
    244: "Argentina",
    245: "Macau",
    246: "Benim",
    247: "Romênia",
    248: "Armênia",
    249: "global",
    250: "IFRS para SMEs",
    251: "GAAP DOS EUA",
    252: "a AICPA  Estrutura de relatórios financeiros para entidades de pequeno e médio porte",
    253: "Sudão do Sul",
    254: "Kosovo",
    255: "Estados Unidos - Governo",
    256: "Rússia OOO EY & OOO EY Vneshaudit",
    257: "Armênia-RússiaDC",
    258: "Bielorrússia-RússiaDC",
    259: "Uzbequistão-RússiaDC"
};

export const common = {
    selected: "Selecionado",
    cancel: "Cancelar",
    checkbox: {
        selectAllOn: "Selecione todos",
        selectAllOff: "Desmarque todos"
    },
    clear: "Limpar",
    close: "Fechar",
    pagination: {
        show: "Mostrar",
        iconHover: {
            first: "Primeira Página",
            previous: "Página anterior",
            next: "Próxima Página",
            last: "Última Página"
        }
    },
    engv2Pagination: {
        show: "Mostrar",
        iconHover: {
            first: "Primeira página",
            previous: "Página anterior",
            next: "Próxima página",
            last: "Última página"
        }
    },
    search: {
        clear: "Apagar busca",
        buttonTitle: "Procurar",
        placeholder: "Procurar",
        noResultsPlaceholder: "Nenhum resultado encontrado"
    },
    table: {
        remove: "Remover",
        noDataPlaceholder: "Não há dados disponíveis",
        noResultsPlaceholder: "Nenhum resultado encontrado"
    },
    toggleSwitch: {
        no: "Não",
        yes: "Sim"
    },
    wizard: {
        next: "Continuar",
        back: "Voltar",
        finish: "Acabar"
    }
};

export const ConfidentialityTypes = [{
        id: 0,
        Name: "Inadimplência"
    },
    {
        id: 10,
        Name: "Baixo"
    },
    {
        id: 12,
        Name: "Moderado"
    },
    {
        id: 11,
        Name: "Alto"
    }
];

export const internalUserRoles = [{
        roleKey: 3,
        sectionId: 2,
        groupDisplayOrder: 150,
        groupName: "Permissões",
        roleNameUI: "Arquivar engagement",
        displayName: "Arquivar engagement",
        roleDisplayOrder: 2,
        tooltipText: "Capacidade de arquivar o engagement"
    },
    {
        roleKey: 4,
        sectionId: 2,
        groupDisplayOrder: 150,
        groupName: "Permissões",
        roleNameUI: "Administrar o engagement",
        displayName: "Administrar o engagement",
        roleDisplayOrder: 1,
        tooltipText: "Capacidade de convidar membros internos e externos e atribuir permissões a membros internos"
    },
    {
        roleKey: 5,
        sectionId: 2,
        groupDisplayOrder: 150,
        groupName: "Permissões",
        roleNameUI: "Atualizar conteúdo",
        displayName: "Atualizar conteúdo",
        roleDisplayOrder: 6,
        tooltipText: "Capacidade de editar o perfil e aceitar uma atualização de conteúdo"
    },
    {
        roleKey: 7,
        sectionId: 2,
        groupDisplayOrder: 150,
        groupName: "Permissões",
        roleNameUI: "Criar solicitação de serviço",
        displayName: "Gerenciar solicitações de serviços",
        roleDisplayOrder: 4,
        tooltipText: "Capacidade de criar solicitações de serviço e marcar solicitações de serviço concluídas"
    },
    {
        roleKey: 8,
        sectionId: 2,
        groupDisplayOrder: 150,
        groupName: "Permissões",
        roleNameUI: "Gerenciar o grupo",
        displayName: "Gerenciar o grupo",
        roleDisplayOrder: 5,
        tooltipText: "Aceite ou rejeite as instruções de grupo recebidas da equipe primária ou regional."
    },
    {
        roleKey: 9,
        sectionId: 2,
        groupDisplayOrder: 150,
        groupName: "Permissões",
        roleNameUI: "Copiar, roll-forward, restaurar compromissos",
        displayName: "Copiar, roll-forward, restaurar compromissos",
        roleDisplayOrder: 3,
		tooltipText: "Capacidade de copiar o engagement e de avançar e restaurar seu archive"
    },
    {
        roleKey: 0,
        sectionId: 1,
        groupDisplayOrder: 200,
        groupName: "Confidencialidade",
        roleNameUI: "Inadimplência",
        displayName: "Nenhum (Padrão)",
        roleDisplayOrder: 1,
        tooltipText: "Função de documentos confidenciais padrão"
    },
    {
        roleKey: 10,
        sectionId: 1,
        groupDisplayOrder: 200,
        groupName: "Confidencialidade",
        roleNameUI: "Baixo",
        displayName: "Baixo",
        roleDisplayOrder: 8,
        tooltipText: "Função de documentos confidenciais baixos"
    },
    {
        roleKey: 11,
        sectionId: 1,
        groupDisplayOrder: 200,
        groupName: "Confidencialidade",
        roleNameUI: "Alto",
        displayName: "Alto",
        roleDisplayOrder: 10,
        tooltipText: "Papel de documentos confidenciais elevados"
    },
    {
        roleKey: 12,
        sectionId: 1,
        groupDisplayOrder: 200,
        groupName: "Confidencialidade",
        roleNameUI: "Moderado",
        displayName: "Moderado",
        roleDisplayOrder: 9,
        tooltipText: "Papel de documentos confidenciais moderados"
    },
    // {
    // roleKey: 13,
    // sectionId:1,
    // groupDisplayOrder: 200,
    // groupName:"Confidentiality",
    // roleNameUI: "Confidential",
    // displayName: "Confidential",
    // roleDisplayOrder: 11,
    // tooltipText: 'Strictly Confidential Documents Role'
    // },
    {
        roleKey: 14,
        sectionId: 3,
        groupDisplayOrder: 100,
		groupName: "Restrições",
        roleNameUI: "Somente leitura",
        displayName: "Somente leitura",
        roleDisplayOrder: 12,
        tooltipText: "Essa funcionalidade deve ser usada apenas para conceder revisores terceirizados a este compromisso do EY Canvas. As aprovações e autorizações apropriadas devem ser adquiridas da Prática Profissional."
    },
    {
        roleKey: 16,
        sectionId: 3,
        groupDisplayOrder: 100,
		groupName: "Restrições",
        roleNameUI: "Somente revisão",
        displayName: "Somente revisão",
        roleDisplayOrder: 13,
        tooltipText: "A revisão apenas oferece a esse usuário a capacidade de visualizar informações no envolvimento do Canvas, assinar e usar a funcionalidade de nota de revisão. Isso deve ser usado quando outros usuários da EY precisarem revisar este engagement (por exemplo, Equipe Primária de Auditoria do Grupo), mas não puderem fazer edições.",
    },
    {
        roleKey: 37,
        sectionId: 3,
        groupDisplayOrder: 100,
		groupName: "Restrições",
		roleNameUI: "Independence only",
		displayName: "Independence only",
		roleDisplayOrder: 14,
		tooltipText: 'Independence only gives this user the ability to complete their independence form for the engagement, but will not allow user to fully access the EY Canvas engagement. This should be used when a user has performed work for the engagement, but does not need to have access to EY Canvas.',
    },
    {
        roleKey: 6,
        sectionId: 2,
        groupDisplayOrder: 150,
        groupName: "Permissões",
        roleNameUI: "Desfazer edições",
        displayName: "Reverter documentos",
        roleDisplayOrder: 7,
        tooltipText: "Capacidade de reverter documentos se as alterações foram feitas acidentalmente após a data de lançamento do relatório e removê-los do 440 Canvas Form."
    },
{
	roleKey: 35,
	sectionId: 2,
	groupDisplayOrder: 150,
    groupName: "Permissões",
    roleNameUI: "Gerenciar o compartilhamento da habilitação do EY Canvas FIT",
        displayName: "Administrar fluxos de trabalho guiados",
	roleDisplayOrder: 14,
        tooltipText: "Capacidade de compartilhar fluxos de trabalho guiados entre engagements do EY Canvas"
},
{
	roleKey: 36,
	sectionId: 2,
	groupDisplayOrder: 150,
    groupName: "Permissões",
    roleNameUI: "Importar dados do EY Helix ",
    displayName: "Importar dados do EY Helix ",
	roleDisplayOrder: 15,
        tooltipText: "Capacidade de importar dados do EY Helix"
}
];

export const restoreengagementexpirydates = [{
        value: 3,
        label: "3 meses"
    },
    {
        value: 6,
        label: "6 meses"
    },
    {
        value: 9,
        label: "9 meses"
    },
    {
        value: 12,
        label: "12 meses"
    }
];

export const messageStatus = {
    0: "Atual",
    1: "Agendado",
    2: "Expirado"
};

export const kendoLabels = {
    alignLeft: "Justifique à esquerda",
    alignRight: "Justifique à direita",
    alignCenter: "Justifique ao centro",
    alignFull: "Justifique tudo",
    addComment: "Adicionar comentário",
    addColumnBefore: "Adicionar coluna à esquerda",
    addColumnAfter: "Adicionar coluna à direita",
    addRowAbove: "Adicionar linha acima",
    addRowBelow: "Adicionar linha abaixo",
    bulletList: "Inserir lista não ordenada",
    bold: "Negrito",
    backColor: "Realçar",
    createLink: "Insera Hyperlink",
    createTable: "Criar tabela",
    cleanFormatting: "Limpar formatação",
    deleteRow: "Excluir linha",
    deleteColumn: "Excluir coluna",
    deleteTable: "Excluir tabela",
    fontSizeInherit: "Tamanho da fonte",
    foreColor: "Cor",
    format: "Formato",
    fontSize: "Estilo da fonte",
    hyperlink: "Inserir link da web",
    italic: "Itálico",
    indent: "Recuar",
    insertTableHint: "Crie uma tabela {0} por {1}",
    "hyperlink-dialog-content-address": "Endereço da Web",
    "hyperlink-dialog-title": "Insira hyperlink",
    "hyperlink-dialog-content-title": "Título",
    "hyperlink-dialog-content-newwindow": "Abrir link em nova janela",
    "hyperlink-dialog-cancel": "Cancelar",
    "hyperlink-dialog-insert": "Inserir",
    outdent: "Sair",
    orderedList: "Inserir lista ordenada",
    print: "Imprimir",
    pdf: "exportar para pdf",
    redo: "refazer",
    strikethrough: "Tachado",
    subscript: "Subscrito",
    superscript: "Sobrescrito",
    underline: "Sublinhado",
    undo: "Desfazer"
};

export const kendoFormatOptions = [{
        text: "Parágrafo",
        value: "p"
    },
    {
        text: "Cabeçalho 1",
        value: "h1"
    },
    {
        text: "Cabeçalho 2",
        value: "h2"
    },
    {
        text: "Cabeçalho 3",
        value: "h3"
    },
    {
        text: "Cabeçalho 4",
        value: "h4"
    },
    {
        text: "Cabeçalho 5",
        value: "h5"
    },
    {
        text: "Cabeçalho 6",
        value: "h6"
    }
];

export const kendoFontSize = [{
        text: '8',
        value: "8px"
    },
    {
        text: '9',
        value: "9px"
    },
    {
        text: '10',
        value: "10px"
    },
    {
        text: '11',
        value: "11px"
    },
    {
        text: '12',
        value: "12px"
    },
    {
        text: '14',
        value: "14px"
    },
    {
        text: '16',
        value: "16px"
    },
    {
        text: '18',
        value: "18px"
    },
    {
        text: '20',
        value: "20px"
    },
    {
        text: '22',
        value: "22px"
    },
    {
        text: '24',
        value: "24px"
    },
    {
        text: '26',
        value: "26px"
    },
    {
        text: '28',
        value: "28px"
    },
    {
        text: '36',
        value: "36px"
    },
    {
        text: '48',
        value: "48px"
    },
    {
        text: '72',
        value: "72px"
    }
];

export const cacheOptionValue = [{
        value: 1,
        label: "LDC"
    },
    {
        value: 2,
        label: "Universal"
    }
]
