import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
	MotifButton,
	MotifFormField,
	MotifLabel,
	MotifSearch,
	MotifSelect,
	MotifOption,
	MotifErrorMessage,
	MotifInlineMessage
} from '@ey-xd/motif-react';
import styled from 'styled-components';
import Pagination from '@ey/pager';
import {
	currentResource,
	labels,
	pagingOptions,
	engagementsSearchRetainFlag
} from '../../../../util/uiconstants';
import EngagementTable from './EngagementTable';
import env from '../../../../util/env';
import {
	getEngagements,
	resetEngagementData,
	setEngagementSearchParameters
} from '../../../../actions/admin/engagement/engagementActions';
import {getCountries} from '../../../../actions/admin/masterdataactions';
import {getMeCall} from '../../../../actions/admin/mepermissionsactions';
import {SVGICONS} from '@ey/icons';
import AccessDeniedMessage from '../../../Common/AccessDeniedMessage/AccessDeniedMessage';
import Loader from '@ey/loader';

function EngagementsView() {
	const dispatch = useDispatch();

	const engagementSearchParameters = useSelector(
		(store) => store.engagementSearchParameters
	);
	const engagementData = useSelector((store) => store.engagements);
	const countries = useSelector((store) => store.countries);
	const userPermissions = useSelector((store) => store.userPermissions);

	const [engagementItems, setEngagementItems] = useState([]);
	const [country, setCountry] = useState();
	const [page, setPage] = useState(1);
	const [engagementIdSearch, setEngagementIdSearch] = useState('');
	const [engagementNameSearch, setEngagementNameSearch] = useState('');
	const [clientSearch, setClientSearch] = useState('');
	const [engagementCodeSearch, setEngagementCodeSearch] = useState('');
	const [userSearch, setUserSearch] = useState('');
	const [optionPerPage, setOptionPerPage] = useState(pagingOptions.options[0]);
	const [totalPages, setTotalPages] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [isPageLoading, setIsPageLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [keyCounter, setKeyCounter] = useState(0);
	const [isMeCallSent, setIsMeCallSent] = useState(false);
	const [isCountryRequired, setIsCountryRequired] = useState(false);
	const [showInvalidEngIdValidationMessage, setShowInvalidEngIdValidationMessage] = useState(false);
	let searchUrl = '';

	useEffect(() => {
		return () => {
			localStorage.setItem(
				engagementsSearchRetainFlag.IsRetainEngagementsSearchResult,
				false
			);
		};
	}, []);

	useEffect(() => {
		var searchRetainView = localStorage.getItem(
			engagementsSearchRetainFlag.IsRetainEngagementsSearchResult
		);
		if (engagementSearchParameters && searchRetainView == 'true') {
			setEngagementIdSearch(engagementSearchParameters?.engagementId);
			setEngagementNameSearch(engagementSearchParameters?.engagementName);
			setClientSearch(engagementSearchParameters?.clientNameCode);
			setEngagementCodeSearch(engagementSearchParameters?.codeOrCodeName);
			setUserSearch(engagementSearchParameters?.userGUIEmailUserId);
			setCountry(engagementSearchParameters?.countryId);
		}
	}, [engagementSearchParameters]);

	useEffect(() => {
		setIsPageLoading(true);
		dispatch(getMeCall())
			.then(() => {
				setIsMeCallSent(true);
				setIsPageLoading(false);
			})
			.catch(() => {
				setIsPageLoading(false);
			})
			.finally(() => {
				setIsPageLoading(false);
			});
	}, []);

	useEffect(() => {
		if (isMeCallSent && isUserEngagementAccess()) {
			if (Object.keys(countries).length === 0) {
				getEngagementCountries();
			}
		}
	}, [isMeCallSent]);

	useEffect(() => {
		var searchRetainView = localStorage.getItem(
			engagementsSearchRetainFlag.IsRetainEngagementsSearchResult
		);
		if (engagementData && searchRetainView == 'true') {
			setEngagementItems(engagementData);
			setTotalPages(engagementData?.totalPageCount);
		}
	}, [engagementData]);

	const getCountriesForDropDown = () => {
		let countryList = [];
		if (Object.keys(countries).length > 0) {
			_.forEach(countries, (item) => {
				if (item) {
					countryList.push({
						value: item.countryId.toString(),
						label: item.countryName
					});
				}
			});
		}
		return countryList;
	};

	const constructSearchUrl = () => {
		if (engagementIdSearch) {
			searchUrl =
				'engagementIdFilter=' + encodeURIComponent(engagementIdSearch);
		}

		if (engagementNameSearch) {
			searchUrl =
				searchUrl + '&engagementNameFilter=' + encodeURIComponent(engagementNameSearch);
		}

		if (clientSearch) {
			searchUrl =
				searchUrl + '&clientFilter=' + encodeURIComponent(clientSearch.replaceAll('"',''));
		}

		if (engagementCodeSearch) {
			searchUrl =
				searchUrl +
				'&engagementCodeFilter=' +
				encodeURIComponent(engagementCodeSearch.replaceAll('"',''));
		}

		if (userSearch) {
			searchUrl = searchUrl + '&userFilter=' + encodeURIComponent(userSearch);
		}

		if (country) {
			searchUrl = searchUrl + '&countryIdFilter=' + country;
		}

		return searchUrl;
	};

	const ValidateSearch = () => {
		const searches = [
			engagementNameSearch,
			engagementCodeSearch,
			clientSearch,
			userSearch
		];

		const mandatorySearchFields = [country, engagementIdSearch];

		const isValidSearch = searches.filter((s) => {
			return s?.trim().length > 0;
		});

		const isValidMandatorySearch = mandatorySearchFields.filter((s) => {
			return s?.trim().length > 0;
		});
		
		if (isValidSearch.length == 0 && isValidMandatorySearch.length == 0) {
			setErrorMessage(true);
			return false;
		}
		
		if (engagementIdSearch && !Number(engagementIdSearch?.trim())) {
			setShowInvalidEngIdValidationMessage(true);
			return false;
		} 
		
		if (isValidMandatorySearch.length == 0) {
			setIsCountryRequired(true);
			return false;
		}

		return true;
	};

	const saveSearchParameters = (isClearSearch = false) => {
		var searchParameters = {
			engagementId: isClearSearch == true ? '' : engagementIdSearch,
			engagementName: isClearSearch == true ? '' : engagementNameSearch,
			clientNameCode: isClearSearch == true ? '' : clientSearch,
			codeOrCodeName: isClearSearch == true ? '' : engagementCodeSearch,
			userGUIEmailUserId: isClearSearch == true ? '' : userSearch,
			countryId: isClearSearch == true ? '' : country
		};
		dispatch(setEngagementSearchParameters(searchParameters));
	};

	const loadEngagements = (page, optionPerPage) => {
		let validSearch = ValidateSearch();
		if (validSearch) {
			setIsLoading(true);
			setIsCountryRequired(false);
			setShowInvalidEngIdValidationMessage(false);
			setErrorMessage(false);
			constructSearchUrl();
			localStorage.setItem(
				engagementsSearchRetainFlag.IsRetainEngagementsSearchResult,
				true
			);
			saveSearchParameters();

			const reqUrl =
				env.getURL('serviceUrlV2') +
				'admin/Engagements?' +
				searchUrl +
				'&page=' +
				page +
				'&pageSize=' +
				optionPerPage;

			dispatch(getEngagements(reqUrl))
				.then(() => {
					setIsLoading(false);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	const getEngagementCountries = () => {
		let countriesURL = env.getURL('serviceUrlV2') + 'countries?pageSize=-1';
		dispatch(getCountries(countriesURL)).then(() => {});
	};

	const onEngagementIdSearch = (e) => {
		setErrorMessage(false);
		setIsCountryRequired(false);
		setShowInvalidEngIdValidationMessage(false);
		setEngagementIdSearch(e.target.value);
	}

	const onEngagementNameSearch = (e) => {
		setErrorMessage(false);
		setEngagementNameSearch(e.target.value);
	};
	const onClientSearch = (e) => {
		setErrorMessage(false);
		setClientSearch(e.target.value);
	};
	const onEngagementCodeSearch = (e) => {
		setErrorMessage(false);
		setEngagementCodeSearch(e.target.value);
	};
	const onUserSearch = (e) => {
		setErrorMessage(false);
		setUserSearch(e.target.value);
	};

	const dropDownOnChange = (option) => {
		setErrorMessage(false);
		setIsCountryRequired(false);
		setCountry(option);
	};

	const onSearchButtonClick = () => {
		if (isMeCallSent && isUserEngagementAccess()) {
			loadEngagements(page, optionPerPage);
		}
	};

	const onClear = () => {
		setIsCountryRequired(false);
		setShowInvalidEngIdValidationMessage(false);
		setEngagementItems([]);
		saveSearchParameters(true);
		setCountry();
		setEngagementIdSearch('');
		setEngagementNameSearch('');
		setClientSearch('');
		setEngagementCodeSearch('');
		setUserSearch('');
		dispatch(resetEngagementData());
		setErrorMessage(false);
		setKeyCounter(keyCounter + 1);
		setPage(1);
		localStorage.setItem(
			engagementsSearchRetainFlag.IsRetainEngagementsSearchResult,
			false
		);
	};

	const paginationHandler = (value) => {
		if (page !== value && page > 0) {
			setPage(value);
			loadEngagements(value, optionPerPage);
		}
	};
	const handlerOptionPage = (value) => {
		if (value !== optionPerPage) {
			setOptionPerPage(value);
			setPage(1);
			loadEngagements(1, value);
		}
	};

	const isUserEngagementAccess = () => {
		var permission = userPermissions?.permissions;
		return (
			permission?.canInviteEngagementMember == true ||
			permission?.canReadEngagement == true ||
			permission?.canUpdateEngagement == true ||
			permission?.canUpdateEngagementUser == true ||
			permission?.hasEngagementDefaultRole == true
		);
	};

	return (
		<>
			{isPageLoading ? (
				<Loader />
			) : (
				<Wrapper>
					{!isUserEngagementAccess() ? (
						<AccessDeniedMessage
							permissionMesage={labels.engagementNoPermissionMesage}
						/>
					) : (
						<>
							<section className="desc-wrapper mainResponsiveWrapper">
								<p>{labels.egagementsPageDescription}</p>
							</section>
							<section className="notification mainResponsiveWrapper">
								{errorMessage && (
									<section className="error-section">
										<span className="modal-error-message" aria-label="error">
											<SVGICONS
												styleName="small note-error-icon"
												name="error"
											/>
											<span className="error-text" id="error">
												{labels.emptySearchFieldError}
											</span>
										</span>
									</section>
								)}
							</section>
							<section className="searches-wrapper mainResponsiveWrapper">
								<section className="wrapper">
									<MotifSearch
										maxLength={10}
										value={engagementIdSearch}
										onChange={onEngagementIdSearch}
										placeholder={labels.engagementIdSearchPlaceholder}
										onEnter={onSearchButtonClick}
									></MotifSearch>
									{engagementIdSearch.trim().length != 0 && showInvalidEngIdValidationMessage ? (
										<MotifErrorMessage>
											{labels.adminModuleinvalidEngagementId}
										</MotifErrorMessage>
									) : <></>}
								</section>
								<MotifSearch
									maxLength={100}
									value={engagementNameSearch}
									onChange={onEngagementNameSearch}
									placeholder={labels.engagementNameSearchPlaceholder}
									onEnter={onSearchButtonClick}
								></MotifSearch>
								<MotifSearch
									maxLength={100}
									value={clientSearch}
									onChange={onClientSearch}
									placeholder={labels.clientSearchPlaceholder}
									onEnter={onSearchButtonClick}
								></MotifSearch>
								<MotifSearch
									maxLength={100}
									value={engagementCodeSearch}
									onChange={onEngagementCodeSearch}
									placeholder={labels.engagementCodeSearchPlaceholder}
									onEnter={onSearchButtonClick}
								></MotifSearch>
								<MotifSearch
									maxLength={100}
									value={userSearch}
									onChange={onUserSearch}
									placeholder={labels.userSearchPlaceholder}
									onEnter={onSearchButtonClick}
								></MotifSearch>
								<section className="wrapper">
									<MotifFormField className="countrylist">
										<MotifLabel id="select-label-search">
											{labels.countrySearchPlaceholder}
										</MotifLabel>
										<MotifSelect
											id="countries"
											onChange={(e) => dropDownOnChange(e)}
											filter
											arialabelledby="select-label-search"
											key={keyCounter}
											value={country}
										>
											{getCountriesForDropDown()?.map((item) => (
												<MotifOption
													key={item.value}
													value={item.value}
													label={item.label}
												>
													{item.label}
												</MotifOption>
											))}
										</MotifSelect>
									</MotifFormField>
									{isCountryRequired && (
										<MotifErrorMessage>
											{labels.validationMessage}
										</MotifErrorMessage>
									)}
								</section>
							</section>
							<section className="searchlink mainResponsiveWrapper">
									<MotifButton
										className="motif-button-primary-alt e-search-btn"
										onClick={onSearchButtonClick}
									>
										{currentResource.common.search.buttonTitle}
									</MotifButton>
									<MotifButton
										className="motif-button-primary-alt e-clearsearch-btn"
										onClick={onClear}
									>
										{currentResource.common.search.clear}
									</MotifButton>
									<MotifInlineMessage>
										<span>{labels.engagementSearchCriteria}</span>
									</MotifInlineMessage>
								</section>
							<EngagementTable data={engagementItems} isLoading={isLoading} />

							<section className="pagerholder">
								<Pagination
									styleName="engagement-paging mainResponsiveWrapper"
									options_per_page={pagingOptions.options}
									default_dropdown_value={optionPerPage}
									showText={currentResource.common.pagination.show}
									currentPage={page}
									TotalPages={totalPages}
									dropup
									onInputChange={(e) => {
										let inputValue = Number.parseInt(e.target.value);
										paginationHandler(inputValue);
									}}
									onDropdownChange={(e) => {
										handlerOptionPage(e.value);
									}}
									onFirstPageClick={() => {
										paginationHandler(1);
									}}
									onPrevPageClick={() => {
										paginationHandler(page - 1);
									}}
									onNextPageClick={() => {
										paginationHandler(page + 1);
									}}
									onLastPageClick={() => {
										paginationHandler(totalPages);
									}}
									dropdown_position="left"
									iconHover={currentResource.common.pagination.iconHover}
								/>
							</section>
						</>
					)}
				</Wrapper>
			)}
		</>
	);
}

const Wrapper = styled.section`
	margin-top: 5rem;
	overflow: hidden;

	.notification {
		margin-bottom: 1rem;
		svg {
			fill: ${(props) => props.theme.color[`red600`]};
			margin-right: 1rem;
			vertical-align: middle;
		}
		.error-text {
			font-size: 1.4rem;
			color: ${(props) => props.theme.color[`red600`]};
			vertical-align: middle;
		}
	}

	//start of countrylist dropdown
	.countrylist {
		width: 26rem;
		margin-bottom: 0;
		button {
			.motif-select-trigger {
				height: 4rem;
			}
			.motif-select-view-value {
				width: 100%;
			}
		}

		&.motif-form-field-select-input .motif-select-field-list {
			overflow: hidden !important;
			.motif-select-list-options {
				max-height: 16rem !important;
			}
		}

		.labeltext {
			font-size: 1.4rem;
			margin: 0;
			padding: 0 0 2rem 0;
		}
		&.motif-form-field {
			font-size: 1.4rem !important;

			.motif-select-options{
				.motif-option {
					& > span{				
						width: 100%;
						display: inline-block;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}

			}
			.motif-error-message {
				font-size: 1.4rem;
				margin-top: 0.5rem;
				svg {
					width: 1.8rem;
					height: 1.8rem;
				}
			}

			.motif-select {
				.motif-checkbox-label-wrap {
					margin: 0;
				}

				.motif-select-search-input {
					font-size: 1.4rem;
				}

				button {				
					height: 4rem;
					padding-top: 1.6rem;

					.motif-icon-button{
						top: 0.5rem;
						padding: 0;
						svg{
							margin-top: 0;
						}
					}
					
					svg.motif-checkbox-checked-icon {
						margin-top: 0;
					}

					svg {
						margin-top: 0.5rem;
					}
				}
			}

			.motif-label {
				font-size: 1.4rem !important;
				font-weight: 100;
			}
			.motif-select {
				line-height: normal;
			}
		}
	}

	//end of countrylist dropdown

	//pagination

	.pagerholder {
		margin: 0 auto;
		width: 100%;
		position: absolute;
		bottom: 0rem;
	}

	.engagement-paging {
		justify-content: flex-end;
		line-height: normal;
		min-width: auto !important;
		font-size: 1.4rem;
		background: ${(props) => props.theme.color[`white`]};
		border-top: 1px solid ${(props) => props.theme.color[`grey200`]};
	}

	.e-MotifBreadcrumb {
		margin-top: 1rem;
		margin-bottom: 3rem;

		.e-breadcrumb a {
			font-size: 1.5rem;
		}
	}

	.e-icon-wrapper {
		.pageheading {
			display: flex;
			padding: 2rem 0;
			svg {
				min-width: 2.5rem !important;
				min-height: 2.5rem !important;
				margin-right: 1rem;
			}

			h3 {
				margin: 0;
				line-height: 2.5rem;
			}
		}
	}

	.desc-wrapper {
		padding: 3rem 0 1.5rem 0;
	}

	.desc-wrapper p {
		font-size: 1.4rem;
		font-weight: normal;
		letter-spacing: 0;
		margin-bottom: 0;
	}

	.searchlink{	
		display: flex;
		margin-bottom: 2rem;

		.motif-inline-message {
			.motif-inline-message-content{
				vertical-align: middle;
				line-height: 4rem;
				font-size: 1.4rem;
				svg{
					width: 1.6rem;
					height: 1.6rem;
					fill: ${(props) => props.theme.color[`grey500`]};
					margin: 1.2rem 0.5rem 0 0;
				}					
			}
		}

		.e-search-btn{
			width: 12rem;
		}

		.e-clearsearch-btn {
			width: 15rem;
		}

		.e-search-btn,
		.e-clearsearch-btn  {
			height: 4rem;
			font-size: 1.4rem;
			margin-right: 2rem;
		}
	}

	.searches-wrapper {
		display: grid;
		grid-template-columns: repeat(5, 1fr) repeat(1, auto);
		grid-gap: 15px;
		margin-bottom: 2.5rem;

		.wrapper{
			position: relative;
			.motif-error-message {
				position: absolute;
				font-size: 1.2rem;		
			}
		}

		> * {
			align-self: end;
		}

		.motif-typeahead-close-button {
			margin-top: 0.4rem;
		}

		.motif-input {
			height: 4rem;
			font-size: 1.4rem;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			padding-right: 3rem;
			&::placeholder {
				font-size: 1.4rem !important;
			}
		}

		.searchlink {
			display: flex;
		}

		.e-country-dd {
			border-bottom: solid 0.1rem;

			* {
				border: none !important;
			}

			.react-select__control {
				min-height: auto;

				.react-select__input {
					padding-top: 0 !important;
				}
			}

			.react-select__single-value {
				padding-top: 0.2rem;
			}
		}
	}
`;

export default EngagementsView;
